import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Pdfviewer(props) {
  const [numPages, setNumPages] = useState(null);
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
      <Document
        file={`data:application/pdf;base64,${props.url}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          props.width ? (
              <Page width={props.width} key={`page_${index + 1}`} pageNumber={index + 1} />
          ): (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          )
        ))}
      </Document>
  );
}
