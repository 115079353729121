import React, { useState, useEffect } from "react";
import Axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import { Card, CardBody } from "reactstrap";
import AllergyDeleteModal from "./AllergyDeleteModal";

const AllergyTable = (props) => {
  const [AllergyTypesData, setAllergyTypesData] = useState([]);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchAllergyTypes = () => {
    Axios.get(API_ENDPOINT+"/common/allergy-list").then(
      (response) => {
        if (response.status === 200) {
          setAllergyTypesData(response.data.data);
        }
      }
    );
  };

  const getAllergyName = (cell) => { // eslint-disable-line
    return AllergyTypesData.filter((b) => b.id == cell).map((a) => a.name)[0]; // eslint-disable-line
  };

 

  const Dateformatter = (cell) => {
    return moment(cell, 'YYYY-MM-DD').format(MomentConfig.MOMENT_FORMAT_DATE);
  };

  const AllergyRemover = (cell, row) => {
    return (
      <AllergyDeleteModal
        id={row.id}
        TableRenderAllergies={props.TableRenderAllergies}
      />
    );
  };

  const defaultSorted = [
    {
      dataField: "onsetDate",
      order: "desc",
    }
  ];

  const tableColumns = [
    {
      dataField: "allergyName",
      text: "Allergy",
      sort:true
    },
    {
      dataField: "onsetDate",
      text: "Onset Date",
      formatter: Dateformatter,
      sort:true
    },
    {
      dataField: "severity",
      text: "Severity",
    },
    {
      dataField: "reaction",
      text: "Reaction",
    },
    {
      dataField: "occurance",
      text: "Occurance",
    },
    {
      dataField: "status",
      text: "Actions",
      formatter: AllergyRemover,
    },
  ];


  useEffect(() => {
    fetchAllergyTypes();
    props.TableRenderAllergies();
  }, []); // eslint-disable-line

  return (
    <div className="AllergyTable">
      <Card>
        <CardBody className="health-card">
          {props.AllergiesNoDataFound ? (
            <p>No Data Found</p>
          ) : (
            <BootstrapTable
              keyField="id"
              data={props.data}
              columns={tableColumns}
              bordered={false}
              bootstrap4
              defaultSorted={defaultSorted}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default AllergyTable;
