import React from "react";
import classnames from "classnames";
import ReactHtmlParser from "react-html-parser";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import MedicationTable from "./MedicationTable"

class DetailTabs extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.props.setCurrentTab(tab);
      if (tab === '2') {
        let sickNoteHTMLParser = ReactHtmlParser(this.props.sicknote);
        if (sickNoteHTMLParser && sickNoteHTMLParser.length) {
          this.props.setShowDownloadButton(true);
        } else {
          this.props.setShowDownloadButton(false);
        }
      } else if (tab === '4') {
        let referralNoteHTMLParser = ReactHtmlParser(this.props.referalnote);
        if (referralNoteHTMLParser && referralNoteHTMLParser.length) {
          this.props.setShowDownloadButton(true);
        } else {
          this.props.setShowDownloadButton(false);
        }
      }

    }
  }


  render() {
    // DEPENDENCY VARIABLES 
    const { className, details } = this.props;

    const SickNote = ReactHtmlParser(this.props.sicknote);
    // const Prescription = ReactHtmlParser(this.props.prescription);
    const ReferralNote = ReactHtmlParser(this.props.referalnote);
    const ConsultationSummary = ReactHtmlParser(this.props.consultationsummary);

    const NoDataFound = () => {
      return <h5 className="nodatafound">No data found</h5>;
    };

    let SickNoteContainer = "";


    if (SickNote.length && this.props.sicknote !== "<p><br></p>" && this.props.sicknote !== "") {

      SickNoteContainer = SickNote;
    } else {
      SickNoteContainer = <NoDataFound />;
    }

    if (details.type.id === "3") {
      return (
        <div className={"tab " + className}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Patient Notes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Sick Notes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                Referral Notes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Prescription
              </NavLink>
            </NavItem>

          </Nav>
          <TabContent activeTab={this.state.activeTab} className="disable-scrollbars">
            <TabPane tabId="1">
              {ConsultationSummary.length ? ConsultationSummary : <NoDataFound />}
            </TabPane>
            <TabPane tabId="2">
              {SickNoteContainer}
            </TabPane>
            <TabPane tabId="3">
              {this.props.MedicationsList.length ? <MedicationTable MedicationsList={this.props.MedicationsList} PatientId={this.props.PatientId} /> : <NoDataFound />}

            </TabPane>

            <TabPane tabId="4">
              {ReferralNote.length ? ReferralNote : <NoDataFound />}
            </TabPane>
          </TabContent>
        </div>
      );
    } else {
      return (
        <div className={"tab " + className}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Patient Notes
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={this.state.activeTab} className="disable-scrollbars">
            <TabPane tabId="1">
              {ConsultationSummary.length ? ConsultationSummary : <NoDataFound />}
            </TabPane>
            <TabPane tabId="2">
              {SickNoteContainer}
            </TabPane>
            <TabPane tabId="3">
              {this.props.MedicationsList.length ? <MedicationTable MedicationsList={this.props.MedicationsList} PatientId={this.props.PatientId} /> : <NoDataFound />}

            </TabPane>

            <TabPane tabId="4">
              {ReferralNote.length ? ReferralNote : <NoDataFound />}
            </TabPane>
          </TabContent>
        </div>
      );
    }

  }
}

export default DetailTabs;
