/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
  Input, Label, Card, Spinner,
  CardText, CardBody, CardTitle,
} from 'reactstrap';
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { GlobalState } from '../../../context/ContextApi';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import { cookieService } from '../../../services/cookieService';

const CancelAppointmentModal = (props) => {
  // DEPENDENCY VARIABLES 
  const { cancelAppointmentModal, setCancelAppointmentModal, parent, setCancelRuleStatus, appointmentId } = props;
  // CONTEXT VARIABLES
  const { paymentNote, setPaymentNote, setRefundAmount, setRefundCurrency } = useContext(GlobalState);
  // LOCAL VARIABLES
  let history = useHistory();
  let characterLimit = 500;
  const [cancellationChargeList, setCancellationChargeList] = useState("");
  const [reasonForCancellation, setReasonForCancellation] = useState("");
  const [apiCallStatus, setApiCallStatus] = useState("idle");
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  function showAppointmentCancellationScreen() {
    history.push("/appointment-cancelled");
  }

  const handleReasonForCancellation = (value) => {
    if (value.length <= characterLimit) {
      setReasonForCancellation(value)
    } else {
      toastr.error(`Maximum characters allowed is ${characterLimit}`, "Please use a shorter reason")
    }
  }

  const fetchCancellationChargeList = () => {
    setApiCallStatus("loading")
    const param = appointmentId ? { 'appointmentId': appointmentId } : "";

    axios.post("/patient/appointment/cancellation-charge-list", param)
      .then((response) => {
        // console.log(response);
        if (response.data.data.length === 0) {
          setApiCallStatus("error");
          setApiErrorMessage(response.data.title)
        } else {
          setCancellationChargeList(response.data.data)
          setApiCallStatus("loaded")
        }
      })
      .catch((error) => {
        // console.log(error.response);
        setApiCallStatus("error");
        setApiErrorMessage(error.response.data.title)
      });
  };

  const saveRefundAmountInContextAPI = () => {
    cancellationChargeList.length > 0 && cancellationChargeList.map(
      (item) => { // eslint-disable-line 
        if (item.isTrue === "1") {
          setRefundCurrency(item.currency)
          setRefundAmount(item.refundAmount)
        }
      }
    )
  }

  const cancelAppointment = () => {
    saveRefundAmountInContextAPI();
    setApiCallStatus("loading")

    let params;
    // let dependentID = cookieService.get('dependentID');
    // if(dependentID){
    //   params = {
    //     'appointmentId': appointmentId,
    //     'reason': reasonForCancellation,
    //     'dependentID': dependentID
    //   };
    // } else {
      params = {
        'appointmentId': appointmentId,
        'reason': reasonForCancellation
      };
    // }

    axios.post("/patient/appointment/cancel", params)
      .then((response) => {
        // console.log(response);
        toggle();
        showAppointmentCancellationScreen();
      })
      .catch((error) => {
        // console.log(error.response);
        toastr.error('Cancellation Failed', error.response.data.title);
        setApiErrorMessage(error.response && error.response.data && error.response.data.title ? error.response.data.title : "")
        setApiCallStatus("error");
      });
  };

  const amountFormatter = (cell, row) => {
    return `${getSymbolFromCurrency(row.currency)}${cell} (${row.cancelChargePercent}%)`
  }

  const chargeFormatter = (cell) => {
    return `${cell}%`
  }

  const timeFormatter = (utcTimeStamp) => {
    return moment.utc(utcTimeStamp).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME);
  }

  const stringOperationForBetween = (cell, row) => {
    let newString = cell.replace("%", timeFormatter(row.maxTime));
    newString = newString.replace("%", timeFormatter(row.minTime));
    return newString;
  }

  const defaultStringOperation = (cell, row) => {
    return cell.replace("%", timeFormatter(row.minTime));
  }

  const cancellationTimeFormatter = (cell, row) => {
    if (row.operator === "between") {
      return stringOperationForBetween(cell, row);
    } else {
      return defaultStringOperation(cell, row);
    }
  }

  const rowStyle = (row) => {
    const style = {};

    if (row.isTrue === "1") {
      style.backgroundColor = '#F7D159';
    } else {
      style.backgroundColor = '#FFFFFF';
    }

    return style;
  };

  const columns = appointmentId
    ? [
      {
        dataField: 'cancellationSlotText',
        text: 'Cancellation Period',
        formatter: cancellationTimeFormatter,
      }, {
        dataField: 'cancelCharge',
        text: 'Cancellation Charge',
        formatter: amountFormatter,
        headerStyle: { "width": '170px' },
      }]
    : [
      {
        dataField: 'cancellationSlotText',
        text: 'Cancellation Period'
      }, {
        dataField: 'cancelChargePercent',
        text: 'Cancellation Charge',
        formatter: chargeFormatter,
        headerStyle: { "width": '170px' },
        style: { "textAlign": 'center' },
      }];


  const toggle = () => {
    setCancelAppointmentModal(!cancelAppointmentModal);
    setReasonForCancellation("");
    setApiErrorMessage("");
    setCancellationChargeList("");
    setApiCallStatus("idle");
    setPaymentNote("")
  }

  // ON LOAD API CALL TO GET CHARGE LIST 
  useEffect(() => {
    if (parent === "consultationCard" && apiCallStatus === "idle" && appointmentId) fetchCancellationChargeList();
  }, [cancelAppointmentModal, parent, apiCallStatus, appointmentId]) // eslint-disable-line

  useEffect(() => {
    if (parent === "bookingScreen" && apiCallStatus === "idle") fetchCancellationChargeList();
  }, [parent, apiCallStatus]) // eslint-disable-line

  // BELOW EFFECT HIDES THE CANCEL CONSULTATION BUTTON IF NO RULES IS RETURNED FROM API
  useEffect(() => {
    if (parent === "consultationCard" && apiCallStatus !== "idle") setCancelRuleStatus(apiCallStatus)
  }, [parent, apiCallStatus]) // eslint-disable-line

  return (
    <div>
      <Modal isOpen={cancelAppointmentModal} toggle={toggle}>
        <ModalHeader toggle={toggle} cssModule={{ 'modal-title': 'w-100 text-center' }}><h3>{appointmentId ? 'Appointment Cancellation and Refund' : 'Appointment Cancellation Policy'}</h3></ModalHeader>
        <ModalBody>
          {apiCallStatus === "loading"
            ? <div className={paymentNote === "coupon" ? "refund-coupon-modal-spinner" : "refund-modal-spinner"}>
              <Spinner color="primary" />
            </div>
            : ""}

          {appointmentId && apiCallStatus !== "loading" ? <Label tag="h5">Are you sure you want to cancel?</Label> : ""}

          {apiCallStatus === 'loaded'
            ? <div className="refund-modal-body disable-scrollbars">
              {appointmentId && paymentNote !== "coupon"
                ? <>
                  <Label tag="h6">If you cancel the appointment, cancellation charge will be calculated as below.</Label>
                  <br />
                </>
                : ""}

              {parent === "bookingScreen"
                ? <>
                  <Label tag="h6">If you cancel the appointment, cancellation charge will be calculated as below.</Label>
                  <br />
                </>
                : ""}

              {cancelAppointmentModal && cancellationChargeList && paymentNote !== "coupon"
                ? <>
                  <BootstrapTable
                    keyField='id'
                    data={cancellationChargeList}
                    columns={columns}
                    rowStyle={rowStyle}
                  />
                  {appointmentId
                    ? <div className="legend-container">
                      <div className="rule-legend"> </div>
                      <span className="rule-legend-text"> Active Cancellation Rule </span>
                    </div>
                    : ""}
                </>
                : ""} {" "}
            </div>
            : <div className="refund-modal-body disable-scrollbars">
              {paymentNote !== "coupon" && apiCallStatus === 'error'
                ? <Card>
                  <CardBody>
                    <CardTitle tag="h5">That's an error</CardTitle>
                    <CardText>{apiErrorMessage}</CardText>
                  </CardBody>
                </Card>
                : ""
              }
            </div>
          }

          {paymentNote === "coupon" && apiCallStatus !== "loading" && parent === "consultationCard"
            ? <>
              <Label tag="h6">
                This appointment has been booked using a 100% coupon and there is no payment associated with it.
                If you cancel the appointment, the coupon will be no longer valid.
                  </Label>
              <br />
            </>
            : ""}

          {appointmentId && apiCallStatus !== "loading"
            ? <>
              <Label tag="h5">Reason for Cancellation<span className="required"> * </span></Label>
              <Input
                type="textarea"
                className="reason-for-cancellation"
                value={reasonForCancellation}
                onChange={(e) => { handleReasonForCancellation(e.target.value) }}
              />
              <Label tag="h5" className="float-right">Max {characterLimit} characters allowed </Label>
            </>
            : ""}

        </ModalBody>
        {appointmentId
          ? <ModalFooter className="centralize">
            {paymentNote !== "coupon" && apiCallStatus === 'error'
              ? ""
              : <Button
                color="primary"
                disabled={reasonForCancellation ? false : true}
                title={reasonForCancellation ? "Proceed and cancellation appointment" : "Reason for cancellation is empty"}
                onClick={() => { cancelAppointment() }}
              >Proceed</Button>
            } {' '}
            <Button color="secondary" onClick={toggle}>Close</Button>

          </ModalFooter>
          : ""}
      </Modal>
    </div>
  );
}

export default CancelAppointmentModal;