import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { cookieService } from '../../services/cookieService';
import logo from "../../assets/equipo-logo.svg";

const IosappInterstitial = (props) => {
  let history = useHistory();
  const token = decodeURIComponent(props.match.params.token);
  const appointmentId = decodeURIComponent(props.match.params.appointmentid);
  useEffect(() => {
      if (token && appointmentId) {
        var date = new Date();
        date.setTime(+date + 7 * 86400000);
        cookieService.set("token", token, {
            path: "/",
            expires: date,
        });
        setTimeout(() => { history.push(`/video-consultation/${appointmentId}`)}, 2000 )
    }
  }, [token, appointmentId]); // eslint-disable-line
  
  return (
      <div className="email-verification-wrapper">
      <div className="container verification-container">
        <div className="card">

          <header className="verification-header">
            <h1 className="text-center verification-title">Video Consultation</h1>
            <div className="text-center verify-img-wrapper">
              <img
                src={logo}
                alt="Doc APP logo"
                className="img-fluid"
              />
            </div>
          </header>
          <main style={{ padding: '1rem'}}>
              <>
                <p>
                  Please wait while we are navigating you to the video consulation page
                </p>
              </>
          </main>
        </div>
      </div>
    </div>
  );
};
export default IosappInterstitial;
