import { toastr } from "react-redux-toastr";

const HeightFeet = (HeightFeet, setHeightFeet, limit) => {
  if (HeightFeet > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Height Feet should not be greater than ${limit}`
    );
  } else {
    setHeightFeet(HeightFeet);
  }
};


const HeightInch = (HeightInch, setHeightInch, limit) => {
  if (HeightInch > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Height Inch should not be greater than ${limit}`
    );
  } else {
    setHeightInch(HeightInch);
  }
};
  

const HeightCms = (HeightCms, setHeightCms, limit) => {
  if (HeightCms > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Height Cms should not be greater than ${limit}`
    );
  } else {
    setHeightCms(HeightCms);
  }
};

const WeightPounds = (WeightPounds, setWeightPounds, limit) => {
  if (WeightPounds > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Weight pounds should not be greater than ${limit}`
    );
  } else {
    setWeightPounds(WeightPounds);
  }
};

const WeightKg = (WeightKg, setWeightKg, limit) => {
  if (WeightKg > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Weight kg should not be greater than ${limit}`
    );
  } else {
    setWeightKg(WeightKg);
  }
};

const TemperatureF = (TemperatureF, setTemperatureF, limit) => {
  if (TemperatureF > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Temperature F should not be greater than ${limit}`
    );
  } else {
    setTemperatureF(TemperatureF);
  }
};

const TemperatureC = (TemperatureC, setTemperatureC, limit) => {
  if (TemperatureC > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Temperature C should not be greater than ${limit}`
    );
  } else {
    setTemperatureC(TemperatureC);
  }
};

const Pulse = (Pulse, setPulse, limit) => {
  if (Pulse > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Pulse should not be greater than ${limit}`
    );
  } else {
    setPulse(Pulse);
  }
};

const PeakFlow = (PeakFlow, setPeakFlow, limit) => {
  if (PeakFlow > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Peak flow should not be greater than ${limit}`
    );
  } else {
    setPeakFlow(PeakFlow);
  }
};
const GripStrength = (GripStrength, setGripStrength, limit) => {
  if (GripStrength > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Grip strength should not be greater than ${limit}`
    );
  } else {
    setGripStrength(GripStrength);
  }
};

const BpSystolic = (BpSystolic, setBpSystolic, limit) => {
  if (BpSystolic > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Bp systolic should not be greater than ${limit}`
    );
  } else {
    setBpSystolic(BpSystolic);
  }
};

const BpDiastolic = (BpDiastolic, setBpDiastolic, limit) => {
  if (BpDiastolic > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Bp diastolic should not be greater than ${limit}`
    );
  } else {
    setBpDiastolic(BpDiastolic);
  }
};

const HeartRate = (HeartRate, setHeartRate, limit) => {
  if (HeartRate > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Heart rate should not be greater than ${limit}`
    );
  } else {
    setHeartRate(HeartRate);
  }
};
const Respiration = (Respiration, setRespiration, limit) => {
  if (Respiration > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Respiration should not be greater than ${limit}`
    );
  } else {
    setRespiration(Respiration);
  }
};
const O2Saturation = (O2Saturation, setO2Saturation, limit) => {
  if (O2Saturation > limit) {
    toastr.warning(
      "Limit Exceeded",
      `O2 Saturation should not be greater than ${limit}`
    );
  } else {
    setO2Saturation(O2Saturation);
  }
};
const Glucose = (Glucose, setGlucose, limit) => {
  if (Glucose > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Glucose should not be greater than ${limit}`
    );
  } else {
    setGlucose(Glucose);
  }
};
const Vision = (Vision, setVision, limit) => {
  if (Vision > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Vision should not be greater than ${limit}`
    );
  } else {
    setVision(Vision);
  }
};
const Comments = (Comments, setComments, limit) => {
  if (Comments.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Comments should not be greater than ${limit}`
    );
  } else {
    setComments(Comments);
  }
};
const Occurrence = (Occurrence, setOccurrence, limit) => {
  if (Occurrence.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Occurrence should not be greater than ${limit}`
    );
  } else {
    setOccurrence(Occurrence);
  }
};
const Reaction = (Reaction, setReaction, limit) => {
  if (Reaction.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Reaction should not be greater than ${limit}`
    );
  } else {
    setReaction(Reaction);
  }
};
const Dosage = (Dosage, setDosage, limit) => {
  if (Dosage.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Dosage should not be greater than ${limit}`
    );
  } else {
    setDosage(Dosage);
  }
};
const Frequency = (Frequency, setFrequency, limit) => {
  if (Frequency.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Frequency should not be greater than ${limit}`
    );
  } else {
    setFrequency(Frequency);
  }
};
const Route = (Route, setRoute, limit) => {
  if (Route.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Route should not be greater than ${limit}`
    );
  } else {
    setRoute(Route);
  }
};
const PharmacyName = (PharmacyName, setPharmacyName, limit) => {
  if (PharmacyName.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Pharmacy name should not be greater than ${limit}`
    );
  } else {
    setPharmacyName(PharmacyName);
  }
};
const ContactNumber = (ContactNumber, setContactNumber, limit) => {
  if (ContactNumber.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Contact number should not be greater than ${limit} Characters`
    );
  } else {
    setContactNumber(ContactNumber);
  }
};

const State = (State, setState, limit) => {
  if (State.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `State should not be greater than ${limit} Characters`
    );
  } else {
    setState(State);
  }
};

const City = (City, setCity, limit) => {
  if (City.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `City should not be greater than ${limit} Characters`
    );
  } else {
    setCity(City);
  }
};

const ZipCode = (ZipCode, setZipCode, limit) => {
  if (ZipCode.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Post code should not be greater than ${limit} Characters`
    );
  } else {
    setZipCode(ZipCode);
  }
};
const PhysicianName = (PhysicianName, setPhysicianName, limit) => {
  if (PhysicianName.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Physician name should not be greater than ${limit}`
    );
  } else {
    setPhysicianName(PhysicianName);
  }
};
const FirstName = (FirstName, setFirstName, limit) => {
  if (FirstName.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `First name should not be greater than ${limit} Characters`
    );
  } else {
    setFirstName(FirstName);
  }
};

const MiddleName = (MiddleName, setMiddleName, limit) => {
  if (MiddleName.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Middle name should not be greater than ${limit} Characters`
    );
  } else {
    setMiddleName(MiddleName);
  }
};

const LastName = (LastName, setLastName, limit) => {
  if (LastName.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Last name should not be greater than ${limit} Characters`
    );
  } else {
    setLastName(LastName);
  }
};

const ProfileState = (State, setState,setAutoState, limit) => {
  if (State.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `State should not be greater than ${limit} Characters`
    );
  } else {
    setState(State);
    setAutoState(State);
  }
};

const ProfileCity = (City, setCity,setAutoCity, limit) => {
  if (City.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `City should not be greater than ${limit} Characters`
    );
  } else {
    setCity(City);
    setAutoCity(City);
  }
};

const ProfileZipCode = (ZipCode, setZipCode,setAutoZipCode, limit, ZipText='Post Code') => {
  if (ZipCode.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      ZipText+` should not be greater than ${limit} Characters`
    );
  } else {
    setZipCode(ZipCode);
    setAutoZipCode(ZipCode)
  }
};
const IdentificationTitle = (IdentificationTitle, setIdentificationTitle, limit) => {
  if (IdentificationTitle.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Title should not be greater than ${limit} Characters`
    );
  } else {
    setIdentificationTitle(IdentificationTitle);
  }
};
const ChiefComplaint = (ChiefComplaint, setChiefComplaint, limit) => {
  if (ChiefComplaint.length > limit) {
    toastr.warning(
      "Limit Exceeded",
      `Chief complaint for consultation should not be greater than ${limit} Characters`
    );
  } else {
    setChiefComplaint(ChiefComplaint);
  }
};
export {
  HeightFeet,
  HeightInch,
  HeightCms,
  WeightPounds,
  WeightKg,
  TemperatureF,
  TemperatureC,
  Pulse,
  PeakFlow,
  GripStrength,
  BpSystolic,
  BpDiastolic,
  HeartRate,
  Respiration,
  O2Saturation,
  Glucose,
  Vision,
  Comments,
  Occurrence,
  Reaction,
  Dosage,
  Frequency,
  Route,
  PharmacyName,
  ContactNumber,
  State,
  City,
  ZipCode,
  PhysicianName,
  FirstName,
  MiddleName,
  LastName,
  ProfileState,
ProfileCity,
ProfileZipCode,
IdentificationTitle,
ChiefComplaint
};

