/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'; // eslint-disable-line
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import "../CareTeam.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const AdvancedPharmacySearchModal = (props) => {
  // DEPENDENCY VARIABLES
  const { advancedSearchModal, setAdvancedSearchModal, pharmacyHandler, patientDetails } = props
  // LOCAL VARIABLES
  let token = localStorage.getItem("token");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [spinner, setSpinner] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [search, setSearch] = useState(false)
  const [pharmacyList, setPharmacyList] = useState("")
  const [pharmacyInfo, setPharmacyInfo] = useState("")
  const [name, setName] = useState("")
  const [country, setCountry] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zip, setZip] = useState("")
  const [Countries, setCountries] = useState("");
  const [patientInfo, setPatientInfo] = useState("");

  const toggle = () => {
    setAdvancedSearchModal(!advancedSearchModal);
    setPharmacyList("")
    setApiLoaded(false)
    setPharmacyInfo("")
    setPatientInfo("")
    setName("")
    setCountry("")
    setAddress("")
    setCity("")
    setState("")
    setZip("")
  }

  const clearValues = () => {
    setPharmacyList("")
    setApiLoaded(false)
    setPharmacyInfo("")
    setPatientInfo("")
    setName("")
    setCountry("")
    setAddress("")
    setCity("")
    setState("")
    setZip("")
  }

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const radioValidation = (cell, row) => {
    let data = row
    data.value = row.id
    data.label = row.name

    return (
      <div className="advanced-search-radio-button">
        <Input type="radio" name="choose" onClick={() => { setPharmacyInfo(data) }} />
      </div>
    )
  }

  const columns = [{
    dataField: 'id',
    text: 'Select',
    formatter: radioValidation
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'country_name',
    text: 'Country',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'address',
    text: 'Address',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'city',
    text: 'City/Town',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'state',
    text: 'State/ Province/ County',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'zipcode',
    text: 'Postal Code',
    sort: true,
    formatter: basicValidation
  }];

  const searchPharmacy = () => {
    setSpinner(true);
    setApiLoaded(false);

    const params = {
      name: name ? name : "",
      country: country ? Number(country.value) : "",
      address: address ? address : "",
      city: city ? city : "",
      state: state ? state : "",
      zip: zip ? zip : ""
    }

    axios.post(`${API_ENDPOINT}/common/get-pharmacy-list`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setPharmacyList(response.data.data);
          setApiLoaded(true);
          setSpinner(false);
        } else {
          setPharmacyList("");
          setApiLoaded(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        setPharmacyList("");
        setApiLoaded(true);
        setSpinner(false);
      });
  };

  const getCountries = () => {
    axios
      .get(`${API_ENDPOINT}/common/country-list`)
      .then(function (response) {
        setCountries(response.data.data);
      })
      .catch(function (error) { });
  };

  const CountryList = Countries && Countries.map((key) => {
    return { value: key.id, label: key.country_name };
  });

  const CountryHandler = (event) => {
    setCountry(event);
  };

  const enableOrDisableSearch = () => {
    if (name || country || address || city || state || zip) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }

  const autoSelectPatientCountry = () => {
    let patientCountry = patientInfo.is_patient.country
    Countries.map(
      // eslint-disable-next-line
      (country) => {
        if (country.country_name === patientCountry) {
          setCountry({
            value: country.id,
            label: country.country_name
          });
        }
      }
    )
  }

  const assignValueToPatientInfo = () => {
    setPatientInfo(patientDetails)
  }

  // eslint-disable-next-line
  useEffect(() => { getCountries(); }, [])
  // eslint-disable-next-line
  useEffect(() => { if (patientDetails && !patientInfo) assignValueToPatientInfo(); }, [advancedSearchModal])
  // eslint-disable-next-line
  useEffect(() => { if (Countries && patientInfo) autoSelectPatientCountry(); }, [Countries, patientInfo])
  // eslint-disable-next-line
  useEffect(() => { enableOrDisableSearch(); }, [name, country, address, city, state, zip])

  return (
    <div>
      <Modal isOpen={advancedSearchModal} toggle={toggle} size="lg" >
        {/* <ModalHeader toggle={toggle}>Find A Pharmacy</ModalHeader> */}
        <h3 className="ModalHeader mt-3">Find Pharmacy</h3>
            <div
              className="modal-close"
              onClick={() => toggle()}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>

        <ModalBody>
          <Card className="adv-search-background-card">
          <Row>
            <Col>
              <Input
                value={name}
                placeholder="Name"
                onChange={(e) => { setName(e.target.value) }}
              />
            </Col>
            <Col>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={CountryList}
                onChange={(event) => CountryHandler(event)}
                value={country}
                placeholder="Country"
                isSearchable
                isClearable
              />
            </Col>
            <Col>
              <Input
                value={address}
                placeholder="Address"
                onChange={(e) => { setAddress(e.target.value) }}
              />
            </Col>
          </Row>
          <Row className="margin-top">
            <Col>
              <Input
                value={city}
                placeholder="City/Town"
                onChange={(e) => { setCity(e.target.value) }}
              />
            </Col>
            <Col>
              <Input
                value={state}
                placeholder="State/Province/County"
                onChange={(e) => { setState(e.target.value) }}
              />
            </Col>
            <Col>
              <Input
                value={zip}
                placeholder="Postal Code"
                onChange={(e) => { setZip(e.target.value) }}
              />
            </Col>
          </Row>
          </Card>
          <Row>
            <Col className="advanced-search-button">
              <Button
                color="primary"
                className="button-width margin-right"
                onClick={() => { searchPharmacy() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Search" : ""}
              >Search</Button> {" "}
              <Button
                color="secondary"
                className="button-width"
                onClick={() => { clearValues() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >Clear</Button>
            </Col>
          </Row>
          <div className="advanced-search-button">
            {spinner
              ? <Spinner color="primary" />
              : ""}
          </div>
          {pharmacyList && apiLoaded
            ? <>
              <Row className="advanced-search-table-height">
                <Col>
                  {pharmacyList && apiLoaded
                    ? <div className="advanced-search-table">
                      <BootstrapTable keyField='id' data={pharmacyList} columns={columns} />
                    </div>
                    : ""}
                  {!pharmacyList && apiLoaded && !spinner ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
                </Col>
              </Row>
            </>
            : ""
          }
          {!pharmacyList && apiLoaded && !spinner ? 
            <>
            <hr />
            <Row className="advanced-search-table-height">
              <Col>
                {!pharmacyList && apiLoaded && !spinner ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
              </Col>
            </Row>
          </>
          : ""
          }
        </ModalBody>
        <ModalFooter className="advanced-search-modal-footer">
          {pharmacyInfo
            ? <>
              <Button
                color="primary"
                className="button-width"
                onClick={() => { pharmacyHandler(pharmacyInfo); toggle() }}
                disabled={!spinner && pharmacyInfo ? false : true}
                title={!spinner && pharmacyInfo ? "" : "Pharmacy Not Selected"}
              >Select</Button>{' '}
            </>
            : ""}
          <Button color="secondary" className="button-width" onClick={toggle}>Close</Button>

        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdvancedPharmacySearchModal;