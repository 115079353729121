import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Row, Col } from 'reactstrap';
import OT from '@opentok/client'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
// import { toastr } from "react-redux-toastr";
import './Publisher.css'

import {
    faMicrophone,
    faMicrophoneSlash,
    faPhoneSlash,
    faVideo,
    faVideoSlash,
    faRetweet
} from "@fortawesome/free-solid-svg-icons";

import { GlobalState } from '../../../../context/ContextApi';
import disableBrowserBackButton from 'disable-browser-back-navigation';
// import ConsultCompleteModal from "./ConsultCompleteModal";
// import AppointmentCompleted from "./AppointmentCompleted";

//OPENTOK VARIABLE 
var publisher;
var session;

const Publisher = (props) => {
    // DESTRUCTURING VARIABLES
    const { apiKey, token, sessionId } = props.props;
    const { setAlterSidebar, setTimer, appointmentId, setConsultComplete, consultationStarted, setConsultationStarted, SuperUserSession } = useContext(GlobalState);

    // LOCAL VARIABLES 
    let displayText = "Please wait. The care provider will join soon";
    const [sessionStatus, setSessionStatus] = useState(false);
    const [audio, setAudio] = useState(true);
    const [video, setVideo] = useState(true);
    const [blankState, setBlankState] = useState(true);
    const [showMediaPermission, setShowMediaPermission] = useState(false);
    const [consultCompleteModal, setConsultCompleteModal] = useState(false) // eslint-disable-line

    const history = useHistory();
    // NOTIFICATION PANEL FOR SUCCESS
    // const showToastrSuccess = (message, subMessage = "") => {
    //     const options = {
    //         timeOut: parseInt(5000),
    //         showCloseButton: true,
    //         progressBar: false,
    //     };

    //     const toastrInstance = toastr.success;
    //     toastrInstance(
    //         message, subMessage,
    //         options
    //     );
    // }

    // OPENTOK FUNCTIONS
    session = OT.initSession(apiKey, sessionId);

    // HANDLING ALL OF OUR ERRORS HERE BY ALERTING THEM
    function handleError(error) {
        if (error) {
            if(error.name === "OT_USER_MEDIA_ACCESS_DENIED") {
                setShowMediaPermission(true);
            }
            //alert(error.message);
        }
    }

    // (optional) add server code here


    function initializeSession() {
        // Subscribe to a newly created stream
        if (!consultationStarted) {
            session.on('streamCreated', function (event) {
                session.subscribe(event.stream, 'subscriber', {
                    insertMode: 'append',
                    width: '100%',
                    height: '100%'
                }, handleError);
                console.log('streamCreated');
                console.log('supersessioncreate', SuperUserSession);
                setBlankState(false);
                setConsultationStarted(true);
            });
        } else {
            setBlankState(false);
        }

        session.on('streamDestroyed', function (event) {
            console.log('streamDestroyed');
            setBlankState(true);
        });

        session.on('connectionDestroyed', function (event) {
            console.log('connectionDestroyed');
            console.log('supersession', SuperUserSession);
            setSessionStatus(false);
            setConsultationStarted(false);
            if(SuperUserSession == 1){
                history.push({
                    pathname: '/dependent-appointment-completed',
                });
            }else{
                history.push({
                    pathname: '/appointment-completed',
                });
            }
           
            setTimeout(() => {
                disconnect();
            }, 2000);
        });

        // Create a publisher
        publisher = OT.initPublisher('publisher', {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            showControls: true,
            publishAudio: true,
            publishVideo: true,
        }, handleError);

        publisher.on({
            accessAllowed: function (event) {
                setShowMediaPermission(false);
              // The user has granted access to the camera and mic.
            },
            accessDenied: function accessDeniedHandler(event) {
              // The user has denied access to the camera and mic.
            },
            accessDialogOpened: function (event) {
                // The Allow/Deny dialog box is opened.
            },
            accessDialogClosed: function (event) {
                // The Allow/Deny dialog box is closed.
            }
        });
        setAlterSidebar(true);
        disableBrowserBackButton();
    }

    // FUNCTION TO START A SESSION 
    const connect = () => {
        // Connect to the session
        session.connect(token, function (error) {
            // If the connection is successful, initialize a publisher and publish to the session
            if (error) {
                handleError(error);
            } else {
                session.publish(publisher, handleError);
                setSessionStatus(true);
            }
        });
    }

    // OPENTOK FUNCTIONS  
    const startAudio = () => { publisher.publishAudio(true); setAudio(!audio); }

    const stopAudio = () => { publisher.publishAudio(false); setAudio(!audio); }

    const startVideo = () => { publisher.publishVideo(true); setVideo(!video); }

    const stopVideo = () => { publisher.publishVideo(false); setVideo(!video); }

    const switchCamera = () => { publisher.cycleVideo(); }

    const disconnect = () => {
        setSessionStatus(false);
        setConsultComplete(true);
        setAlterSidebar(false)
        if (session) session.disconnect();
        if (session) publisher.destroy();
        if (session) session.destroy();
        if (session) session = "";
        callPatientEndSessionAPI();
    }

    const callPatientEndSessionAPI = () => {
        axios.post('/econsult/patient-left-session', {
            appointmentId: appointmentId
        })
            .then(function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        if (sessionId) initializeSession();
        if (sessionId) connect();
    }, [sessionId]); //eslint-disable-line

    useEffect(() => { sessionStatus ? setTimer(true) : setTimer(false) }, [sessionStatus]);  //eslint-disable-line

    return (
        <div id="videos" className="video-container">
            <Row >
                <Col>
                    {/* Doctor is the publisher here */}
                    <div id="publisher"></div>
                </Col>
            </Row>
            <Row className="subscriber-and-buttons-div">
                <div id="subscriber">
                    {blankState && (<p className="please-wait-message">{displayText}</p>)}
                </div>

                <div className="button-container video-btn">
                    {!audio && (<Button disabled={sessionStatus ? false : true} className={audio ? "active-button" : null} title="unmute" onClick={() => { startAudio() }}><FontAwesomeIcon icon={faMicrophoneSlash} fixedWidth className="align-middle" /></Button>)}
                    {audio && (<Button disabled={sessionStatus ? false : true} className={audio ? "active-button" : null} title="mute" onClick={() => { stopAudio() }}><FontAwesomeIcon icon={faMicrophone} fixedWidth className="align-middle" /></Button>)}

                    {!video && (<Button disabled={sessionStatus ? false : true} className={video ? "active-button" : null} title="camera on" onClick={() => { startVideo() }}><FontAwesomeIcon icon={faVideoSlash} fixedWidth className="align-middle" /></Button>)}
                    {video && (<Button disabled={sessionStatus ? false : true} className={video ? "active-button" : null} title="camera off" onClick={() => { stopVideo() }}><FontAwesomeIcon icon={faVideo} fixedWidth className="align-middle" /></Button>)}

                    <Button disabled={sessionStatus ? false : true} title="switch camera" onClick={() => { switchCamera() }}><FontAwesomeIcon icon={faRetweet} fixedWidth className="align-middle" /></Button>
                    <a href="/my-appointments">
                        <Button disabled={sessionStatus ? false : true} title="end call" color="danger" onClick={() => { disconnect() }}><FontAwesomeIcon icon={faPhoneSlash} fixedWidth className="align-middle" /></Button>
                    </a>
                </div>
            </Row>
                {showMediaPermission ? (
                    <div className="permission-banner">Allow <b>Equipo Health</b> to access this devices <b>Camera & Microphone.</b></div>

                    ) : null
                }
                {/* <ConsultCompleteModal 
                consultCompleteModal ={consultCompleteModal} 
                setConsultCompleteModal = { setConsultCompleteModal}
                appointmentId={appointmentId}
                /> */}
                {/* <AppointmentCompleted
                consultCompleteModal ={consultCompleteModal} 
                setConsultCompleteModal = { setConsultCompleteModal}
                appointmentId={appointmentId}
                /> */}
        </div>
    )
}

export default Publisher
