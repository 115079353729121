import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';

import ImmunizationDeleteModal from "./ImmunizationDeleteModal";

const ImmunizationTable = (props) => {

  const ImmunizationRemover = (cell, row) => {
    if (row.source && row.source === "PS") {
      return (
        <span className="Immunization-exclamation" data-tip="Prescribed Immunization"
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
          <ReactTooltip className="doc-upload-tooltip" />
        </span>
      )
    } else {
      return (
        <ImmunizationDeleteModal
          id={row.id}
          TableRenderImmunizations={props.TableRenderImmunizations}
        />
      );
    }
  };
  const Dateformatter = (cell) => {
    return moment(cell, 'YYYY-MM-DD').format(MomentConfig.MOMENT_FORMAT_DATE);
  };
  

  const defaultSorted = [
    {
      dataField: "cvxCode",
      order: "asc",
    },
  ];

  const tableColumns = [
    {
      dataField: "cvxCode",
      text: "CVX Code",
      sort: true,
      headerStyle: { width: '130px' }, 
      style: { width: '130px' }
    },
    {
      dataField: "drugname",
      text: "Immunization",
      headerStyle: { width: '150px' }, 
      style: { width: '150px' }
    },
    {
      dataField: "manufacturer",
      text: "Manufacturer",
      headerStyle: { width: '150px' }, 
      style: { width: '150px' }
    },
    {
      dataField: "lotNumber",
      text: "Lot Number",
      headerStyle: { width: '150px' }, 
      style: { width: '150px' }
    },
    {
      dataField: "amountAdministeredUnit",
      text: "Unit",
      headerStyle: { width: '100px' }, 
      style: { width: '100px' }
    },
    {
      dataField: "amountAdministered",
      text: "Amount",
      headerStyle: { width: '100px' }, 
      style: { width: '100px' }
    },
    {
      dataField: "administeredDate",
      text: "Administered Date",
      formatter: Dateformatter,
      headerStyle: { width: '170px' }, 
      style: { width: '170px' }
    },
    {
      dataField: "status",
      text: "Actions",
      formatter: ImmunizationRemover,
      headerStyle: { width: '150px' }, 
      style: { width: '150px' }
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => { props.TableRenderImmunizations(); }, 3000);
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line

  return (
    <div className="ImmunizationTable">
      <Card>
        <CardBody className="health-card">
          {props.ImmunizationsNoDataFound ? (
            <p>No Data Found</p>
          ) : (
              <BootstrapTable
                keyField="id"
                data={props.data}
                columns={tableColumns}
                bordered={false}
                bootstrap4
                defaultSorted={defaultSorted}
              />
            )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ImmunizationTable;
