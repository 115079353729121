import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import './PrivacyPolicyModal.css'

const PrivacyPolicyModal = (props) => {
  // DEPENDENCY VARIABLES  
  const { privacyPolicyModal, setPrivacyPolicy } = props

  const toggle = () => {
    setPrivacyPolicy(!privacyPolicyModal);
  };

  return (
    <div>
      <Modal isOpen={privacyPolicyModal} size="xl" toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">Privacy Policy</ModalHeader>
        <ModalBody className="disable-scrollbars term-container-height">
          <div className="terms-and-conditions">
            <strong>WHAT IS EQUIPO HEALTH</strong>
            <br />
            Equipo Health is committed to safeguarding the privacy and security of its users. We intend to provide you with personalized internet experience that delivers the information, resources and services that are most relevant and helpful to you. In order to achieve this goal, Equipo Health collects information you provide us if you access, voluntarily enter information into, or sign up for or request certain services from us on our Website.

The only personal information Equipo Health obtains about individual users to its website is that supplied voluntarily by users. When we may need personal information to provide users with customized content or to inform them about new features or services, users are explicitly asked for that information.

Equipo will share your information only as described in this Privacy Notice, or as permitted by law. Equipo will not sell, lease, rent, or trade your personal information to any third party for that party’s marketing or promotional purposes, unless you give your consent.


            <br />
            <br />
            <strong>SUBMISSION OF INFORMATION</strong>
            <br />
            You may choose to provide us information when you fill out a form on the website or otherwise contact us. We may also obtain information about you that is publicly available. This information may identify you personally. For example, we may collect your name, company name, address, email address, phone number, position, title, and similar information.

By using our Website, you consent to the use of cookies.

If you provide us with your information on your interest in our products or services, we will use such information to reach out to you about our products or services.
We may use your information for our own marketing, promotions, invitations, newsletters, respond to your questions, provide customer support, share news and product updates.
We will not retain your information longer than necessary to fulfill the purposes for which it was collected or as required by applicable laws or regulations. We take reasonable and appropriate administrative, physical, and technical security safeguards to protect the information that you share with us from loss, theft, misuse and unauthorized access, alteration, destruction, and disclosure. In addition, we enter into agreements which require that care and precautions be taken to prevent loss, misuse, or disclosure of your information. Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of information about you.
            <br />
            <br />
            <strong>NEWSLETTER SUBSCRIPTIONS</strong>
            <br />
            From time to time, we provide information to our customers and potential customers in the form of electronic newsletters and similar updates. When you subscribe to these updates, you will be added to our mailing list and will receive announcements and information about us. These will be emailed to the address(es) that you provide when you subscribe. You may opt out of receiving these communications by following the “unsubscribe” instructions that are contained within the communications that we send to you.
            <br />
            <br />
            <strong>MINORS</strong>
            <br />
            The website covered by this privacy policy is not intended for or targeted at children. Equipo Health will not knowingly collect and maintain personally identifiable information from children of age thirteen years or younger. If you are a parent or guardian and think that we have collected personal information from a child under the age of 13 through this website, please contact us. 
            <strong>COMMUNICATION PREFERENCES</strong>
            <br />
            <br />
            Equipo Health may send commercial emails to you advertising our products and services. You can also subscribe to various product and service-specific communications on our websites. If you receive direct marketing email from us and wish to discontinue these mailings, you may unsubscribe at https://equipo.io/unsubscribe and/or in any unsubscribe opt-out method provided in the communications.

Where allowed by applicable law, the unsubscribe options provided to you may not  apply to communications primarily for the purpose of administering order completion, contracts, support, product safety warnings, software updates where you are provided with appropriate choice in accordance with applicable law, or other administrative and transactional notices, the primary purpose of which is not promotional in nature.  This means that even if you opt-out of receiving direct marketing communications, we may still need to retain some of your personal information necessary to communicate with you on these topics where required for these purposes. We do not share your personal information obtained through this site for the purpose of third-party direct marketing.
            <br />
            <br />
            <strong>CHANGES TO THIS WEBSITE PRIVACY POLICY</strong>
            Changes made to this policy will appear on this page of our website. We will also provide appropriate notice and choices to you, on this website and in other appropriate locations, based on the scope and extent of changes. You may always visit this policy to learn of any updates.  

            <br />
            <br />

            <strong>EFFECTIVE DATE
</strong>
            The effective date of this policy is December 1, 2019.

For any questions contact info@equipohealth.com
          
          
          
          </div>
        </ModalBody>
        <ModalFooter className="close-term-container">
          <Button className="close-term button-width" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PrivacyPolicyModal;
