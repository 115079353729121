import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";//eslint-disable-line
import { Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import "./MyAppointments.css";
import styles from "../Consultation.module.css";
import { useTranslation } from "react-i18next";
import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import ConsultationCard from "./ConsultationCard";
import Axios from "axios";
import TabsWithTextLabel from "./Tabs";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { cookieService } from '../../../services/cookieService';
let activeTab = "0"

const MyAppointments = () => {
  let history = useHistory();
  const location = useLocation();
  const [UpcomingConsultationList, setUpcomingConsultationList] = useState([]);
  const [
    UpcomingConsultationLoading,
    setUpcomingConsultationLoading,
  ] = useState(true);
  const [
    UpcomingConsultationNodataFound,
    setUpcomingConsultationNodataFound,
  ] = useState(false);
  const [CompletedConsultationList, setCompletedConsultationList] = useState(
    []
  );
  const [AllConsultationList, setAllConsultationList] = useState(
    []
  );
  const [
    CompletedConsultationLoading,
    setCompletedConsultationLoading,
  ] = useState(true);

  const [
    AllConsultationLoading,
    setAllConsultationLoading,
  ] = useState(true);

  const [
    CompletedConsultationNodataFound,
    setCompletedConsultationNodataFound,
  ] = useState(false);

  const [
    AllConsultationNodataFound,
    setAllConsultationNodataFound,
  ] = useState(false);

  const [errorStatus, setErrorStatus] = useState(false); // eslint-disable-line
  const [responseFields, setResponseFields] = useState({ // eslint-disable-line
    message: "Error occured",
    status: false,
  });

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');

  const fetchUpcomingAppointmentList = () => {
    
    let data = {}
    // if(dependentID){
    //   // data = {'dependentID': dependentID}
    // }
    Axios.post(
      API_ENDPOINT + "/patient/appointment/upcoming", data
    )
      .then((res) => {
        if (res.status === 200) {
          setUpcomingConsultationList(res.data.data);
          // console.log(res.data.data);
          setUpcomingConsultationLoading(false);
          if (res.data.data.length === 0 || res.data.status === 404) {
            setUpcomingConsultationNodataFound(true);
          } else {
            setUpcomingConsultationNodataFound(false);
          }
        }
      })
      .catch((error) => {
        setUpcomingConsultationLoading(false);
        setUpcomingConsultationNodataFound(true);
      });
  };

  const fetchCompleteAppointmentList = () => {

    let data = {}
    // if(dependentID){
    //   // data = {'dependentID': dependentID}
    // }
    
    Axios.post(
      API_ENDPOINT + "/patient/appointment/completed", data
    )
      .then((res) => {
        if (res.status === 200) {
          setCompletedConsultationList(res.data.data);
          // console.log(res.data.data);
          setCompletedConsultationLoading(false);
          if (res.data.data.length === 0 || res.data.status === 404) {
            setCompletedConsultationNodataFound(true);
          } else {
            setCompletedConsultationNodataFound(false);
          }
        }
      })
      .catch((error) => {
        setCompletedConsultationLoading(false);
        setCompletedConsultationNodataFound(true);
      });
  };


  const fetchAllAppointmentList = () => {
    
    let data = {}
    // if(dependentID){
    //   // data = {'dependentID': dependentID}
    // }
    Axios.post(
      API_ENDPOINT + "/patient/appointment/all", data
    )
      .then((res) => {
        if (res.status === 200) {
          setAllConsultationList(res.data.data);
          setAllConsultationLoading(false);
          if (res.data.data.length === 0 || res.data.status === 404) {
            setAllConsultationNodataFound(true);
          } else {
            setAllConsultationNodataFound(false);
          }
        }
      })
      .catch((error) => {
        setAllConsultationLoading(false);
        setAllConsultationNodataFound(true);
      });
  };

  const upcoming_appointments = UpcomingConsultationList.map((consultation) => {
    return (
      <ConsultationCard
        paymentNote={consultation.paymentNote}
        key={consultation.id}
        id={consultation.id}
        physicianId={consultation.physicianId ? consultation.physicianId : null}
        physician_title={consultation.physician_title}
        physician_name={consultation.physician_name}
        consultation_time={consultation.schStartTime}
        status={consultation.app_status}
        chief_complaint={consultation.chiefComplaint}
        schStartTime={consultation.schStartTime}
        schEndTime={consultation.schEndTime}
        profile_image={consultation.profileImageURL}
        specialityId = {consultation.specialityId}
        specialityText = {consultation.specialityText}
      />
    );
  });

  const completed_appointments = CompletedConsultationList.map(
    (consultation) => {
      return (
        <ConsultationCard
          paymentNote={consultation.paymentNote}
          key={consultation.id}
          id={consultation.id}
          physicianId={consultation.physicianId ? consultation.physicianId : null}
          physician_title={consultation.physician_title}
          physician_name={consultation.physician_name}
          consultation_time={consultation.schStartTime}
          status={consultation.app_status}
          chief_complaint={consultation.chiefComplaint}
          profile_image={consultation.profileImageURL}
          buttonstate="hide"
          specialityId = {consultation.specialityId}
          specialityText = {consultation.specialityText}
          consultationDetails = {consultation}
        />
      );
    }
  );


  const all_appointments = AllConsultationList.map(
    (consultation) => {
      const now = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const end_time = moment.utc(consultation.schEndTime).local().add(60, 'minutes').format("YYYY-MM-DD HH:mm:ss");
      let buttonstate = "";
      if (moment(end_time).isSameOrBefore(now) || consultation.app_status === "Completed") {
        buttonstate = "hide";
      }

      let appointmentId = ""
      let string = window.name;
      string = string.split(",").pop();
      if (consultation.id === string) appointmentId = consultation.id;

      return (
        <ConsultationCard
          isRedirected={appointmentId}
          paymentNote={consultation.paymentNote}
          key={consultation.id ? consultation.id : null}
          id={consultation.id ? consultation.id : null}
          physicianId={consultation.physicianId ? consultation.physicianId : null}
          physician_title={consultation.physician_title ? consultation.physician_title : null}
          physician_name={consultation.physician_name ? consultation.physician_name : null}
          consultation_time={consultation.schStartTime ? consultation.schStartTime : null}
          status={consultation.app_status ? consultation.app_status : null}
          chief_complaint={consultation.chiefComplaint ? consultation.chiefComplaint : null}
          schStartTime={consultation.schStartTime ? consultation.schStartTime : null}
          schEndTime={consultation.schEndTime ? consultation.schEndTime : null}
          profile_image={consultation.profileImageURL ? consultation.profileImageURL : null}
          buttonstate={buttonstate}
          specialityId = {consultation.specialityId}
          specialityText = {consultation.specialityText}
          consultationDetails = {consultation}
        />
      );
    }
  );

  useEffect(() => {
    let data = location.state && location.state.detail;

    if (data === "checkoutSuccess") {
      responseFields.message = t("checkoutSuccessMessage");
      toastr.success(
        responseFields.message
      )
      setTimeout(() => {
        history.push({
          state: { detail: '' }
        })
      }, 500);

      responseFields.status = true;
      setErrorStatus(true);
      window.scrollTo(0, 0);
    }
  }, []);// eslint-disable-line

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  // BELOW EFFECT READS THE ACTIVE TAB NAME FROM THE URL
  useEffect(() => {
    let tab = atob(query.get("tab"));
    activeTab = tab
  }, [location]) // eslint-disable-line

  // INITIAL API CALL TO FETCH DATA 
  useEffect(() => {
    if (activeTab === "1" && UpcomingConsultationList.length === 0) fetchUpcomingAppointmentList();
    if (activeTab === "2" && CompletedConsultationList.length === 0) fetchCompleteAppointmentList();
    if (activeTab === "3" && AllConsultationList.length === 0) fetchAllAppointmentList();
  }, [location]); // eslint-disable-line


  const { t } = useTranslation();
  return (
    <>
      <div className="main h-100 w-100">
        <PageLayout>
          <Header>
            <HeaderTitle>My Appointments</HeaderTitle>
            {/* <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">{t("Dashboard")}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{t("My Appointments")}</BreadcrumbItem>
            </Breadcrumb> */}
          </Header>
              <Card className={styles.providerCard}>
                <CardBody>
                  <a href="/book-appointment">
                    <button className="btn btn-primary pull-right mr-2">
                      {t('Book Appointment')}
                    </button>
                  </a>
                  <TabsWithTextLabel
                    name="Default"
                    upcoming_appointments={upcoming_appointments}
                    completed_appointments={completed_appointments}
                    CompletedConsultationLoading={CompletedConsultationLoading}
                    UpcomingConsultationLoading={UpcomingConsultationLoading}
                    UpcomingConsultationNodataFound={UpcomingConsultationNodataFound}
                    CompletedConsultationNodataFound={CompletedConsultationNodataFound}
                    AllConsultationLoading={AllConsultationLoading}
                    AllConsultationNodataFound={AllConsultationNodataFound}
                    all_appointments={all_appointments}
                  />
                </CardBody>
              </Card>
        </PageLayout>
      </div>
    </>
  );
};

export default MyAppointments;
