import React, { useState, useContext } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Modal, ModalBody, Col, Row } from "reactstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../../../src/context/ContextApi';
import { cookieService } from '../../../services/cookieService';

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const AllergyDeleteModal = (props) => {
  const { completeProfileChanged, setCompleteProfileChanged } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');
  const deleteAllergy = (id) => {
    const deleteAllergyId = {
      id: id,
      // dependentID: dependentID ? dependentID : 0
    };

    Axios.post(
      API_ENDPOINT+"/patient/remove-patient-allergy-from-list",
      deleteAllergyId
    ).then((res) => {
      props.TableRenderAllergies();
      toastr.success("Allergy Removed", "Allergy Removed Successfully");
      setCompleteProfileChanged(!completeProfileChanged);
    });
  };

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button className=" delete-button" outline onClick={() => toggle(index)}>
            <FontAwesomeIcon
              icon={faTrashAlt}
              fixedWidth
              className="align-middle mr-2"
            />
          </Button>
          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
            <ModalBody className="text-center m-3">
              <Row>
                <Col md="12">
                  <p className="text-center">Are you sure want to delete this allergy?</p>
                  <Button
                    color="primary"
                    onClick={() => deleteAllergy(props.id)}
                  >
                    Yes
                  </Button>{"  "}
                  <Button color="outline-primary mr-2" onClick={() => toggle(index)}>
                    No
                  </Button>
                  
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default AllergyDeleteModal;
