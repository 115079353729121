import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import Axios from "axios";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Validation from "./../../../common/FieldValidation";
import { cookieService } from '../../../services/cookieService';

const VitalsInput = (props) => {
  const [RecordDate, setRecordDate] = useState("");
  const [HeightFeet, setHeightFeet] = useState("");
  const [HeightInch, setHeightInch] = useState("");
  const [HeightCms, setHeightCms] = useState("");
  const [WeightPounds, setWeightPounds] = useState("");
  const [WeightKg, setWeightKg] = useState("");
  const [PeakFlow, setPeakFlow] = useState("");
  const [Bmi, setBmi] = useState(""); // eslint-disable-line
  const [Percentile, setPercentile] = useState("");
  const [Bsa, setBsa] = useState(""); // eslint-disable-line
  const [TemperatureF, setTemperatureF] = useState("");
  const [TemperatureC, setTemperatureC] = useState("");
  const [Pulse, setPulse] = useState("");
  const [GripStrength, setGripStrength] = useState("");
  const [Position, setPosition] = useState(0);
  const [BpSystolic, setBpSystolic] = useState("");
  const [BpDiastolic, setBpDiastolic] = useState("");
  const [HeartRate, setHeartRate] = useState("");
  const [Respiration, setRespiration] = useState("");
  const [O2Saturation, setO2Saturation] = useState("");
  const [Glucose, setGlucose] = useState("");
  const [Vision, setVision] = useState("");
  const [PainScale, setPainScale] = useState("");
  const [Comments, setComments] = useState("");
  const [Source, setSource] = useState("Patient");


  const [bmiValue, setBmiValue] = useState("");
  const [bsaValue, setBsaValue] = useState("");

  const RecordDateHandler = (value) => {
    setRecordDate(new Date(value));
    const selectedDate = { recordDate: moment(value).format("Y-MM-DD") };
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    Axios.post(
      API_ENDPOINT + "/patient/get-patient-date-wise-vitals",
      selectedDate
    )
      .then((res) => {
        if (res.data.status === 200 && res.data.title === "Data found") {
          const fetchedByDate = res.data.data;
          setBmi(fetchedByDate.BMI);
          setPercentile(fetchedByDate.BMI_Pctile);
          setBsa(fetchedByDate.BSA);
          setHeightCms(fetchedByDate.Centimeter);
          setHeightFeet(fetchedByDate.Feet);
          setHeightInch(fetchedByDate.Inches);
          setWeightKg(fetchedByDate.Kg);
          setWeightPounds(fetchedByDate.Pound);
          setBpDiastolic(fetchedByDate.bpDiastolic);
          setBpSystolic(fetchedByDate.bpSystolic);
          setComments(fetchedByDate.comment);
          setGlucose(fetchedByDate.glucose);
          setGripStrength(fetchedByDate.gripStrength);
          setHeartRate(fetchedByDate.heartRate);
          setO2Saturation(fetchedByDate.o2Saturation);
          setPainScale(fetchedByDate.painScale);
          setPeakFlow(fetchedByDate.peakFlow);
          setPosition(fetchedByDate.position);
          setPulse(fetchedByDate.pulseRate);
          setRespiration(fetchedByDate.respiration);
          setSource(fetchedByDate.source);
          setTemperatureF(((fetchedByDate.temperature* 9) / 5 + 32).toFixed(1));
          setTemperatureC(fetchedByDate.temperature);
          setVision(fetchedByDate.vision);
        } else {
          AddVitals();
        }
      })
      .catch((error) => {
        AddVitals();
      });
  };

  const HeightFeetHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;
    Validation.HeightFeet(value, setHeightFeet, 10)
    // setHeightFeet(value);
    const a = value / 0.032808;
    const b = HeightInch / 0.3937;
    const c = a + b;
    setHeightCms(c.toFixed(0));
  };

  const HeightInchHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setHeightInch(value);
    Validation.HeightInch(value, setHeightInch, 120)
    const a = value / 0.3937;
    const b = HeightFeet / 0.032808;
    const c = a + b;
    setHeightCms(c.toFixed(0));
  };

  const HeightCmsHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setHeightCms(value);
    Validation.HeightCms(value, setHeightCms, 300)
    const realFeet = (value * 0.3937) / 12;
    const feet = Math.floor(realFeet);
    const inches = ((realFeet - feet) * 12).toFixed(1);
    setHeightFeet(feet);
    setHeightInch(inches);
  };

  const WeightPoundsHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setWeightPounds(value);
    Validation.WeightPounds(value, setWeightPounds, 2200)
    const a = value / 2.2046;
    setWeightKg(a.toFixed(0));
  };

  const WeightKgHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setWeightKg(value);
    Validation.WeightKg(value, setWeightKg, 1000)
    const a = value * 2.2046;
    setWeightPounds(a.toFixed(1));
  };

  const TemperatureFHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setTemperatureF(value);
    Validation.TemperatureF(value, setTemperatureF, 200)
    const Celcius = ((value - 32) * 5) / 9;
    setTemperatureC(Celcius.toFixed(1));
  };

  const TemperatureCHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setTemperatureC(value);
    Validation.TemperatureC(value, setTemperatureC, 93)
    const Fahrenheit = (value * 9) / 5 + 32;
    setTemperatureF(Fahrenheit.toFixed(1));
  };

  const PulseHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setPulse(value);
    Validation.Pulse(value, setPulse, 300)
  };

  const PeakFlowHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setPeakFlow(value);
    Validation.PeakFlow(value, setPeakFlow, 1000)
  };

  const GripStrengthHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setGripStrength(value);
    Validation.GripStrength(value, setGripStrength, 1000)
  };

  const PositionHandler = (value) => {
    setPosition(value);
  };

  const PainScaleHandler = (value) => {
    setPainScale(value);
  };

  const BpSystolicHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setBpSystolic(value);
    Validation.BpSystolic(value, setBpSystolic, 1000)
  };

  const BpDiastolicHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setBpDiastolic(value);
    Validation.BpDiastolic(value, setBpDiastolic, 1000)
  };

  const HeartRateHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setHeartRate(value);
    Validation.HeartRate(value, setHeartRate, 300)
  };

  const RespirationHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setRespiration(value);
    Validation.Respiration(value, setRespiration, 100)
  };

  const O2SaturationHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setO2Saturation(value);
    Validation.O2Saturation(value, setO2Saturation, 100)
  };

  const GlucoseHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;

    // setGlucose(value);
    Validation.Glucose(value, setGlucose, 200)
  };

  const VisionHandler = (v) => {
    let value =
      v.indexOf(".") >= 0
        ? v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 2)
        : v;
    // setVision(value);
    Validation.Vision(value, setVision, 200)
  };



  const CommentsHandler = (value) => {
    Validation.Comments(value, setComments, 500)
    // setComments(value);
  };

  const PositionOptions = [
    { value: 1, label: "Arm" },
    { value: 2, label: "Chest" },
    { value: 3, label: "Head" },
  ];

  const PainScaleoptions = [
    { value: "slight", label: "Slight" },
    { value: "moderate", label: "Moderate" },
    { value: "severe", label: "Severe" },
  ];


  /* const BMI =((WeightKg / ((HeightCms / 100) * (HeightCms / 100))).toFixed(1)); */

  const BMI = () => {
    if (WeightKg && HeightCms) {
      (WeightKg / ((HeightCms / 100) * (HeightCms / 100))).toFixed(1)
    }
    else {
      return "";
    }
  }
  const BSA = () => {
    if (WeightKg && HeightCms) {
      (Math.pow(WeightKg, 0.5378) * Math.pow(HeightCms, 0.3964) * 0.024265).toFixed(1)
    }
    else {
      return "";
    }
  }

  // let dependentID = cookieService.get('dependentID');

  const VitalsformData = {
    bpSystolic: BpSystolic,
    bpDiastolic: BpDiastolic,
    pulseRate: Pulse,
    respiration: Respiration,
    peakFlow: PeakFlow,
    heartRate: HeartRate,
    gripStrength: GripStrength,
    position: Position,
    painScale: PainScale,
    temperature: TemperatureC,
    o2Saturation: O2Saturation,
    vision: Vision,
    bmi: WeightKg === "" && HeightCms === "" ? 0 : BMI,
    bsa: WeightKg === "" && HeightCms === "" ? 0 : BSA,
    BMI_Pctile: Percentile,
    Kg: WeightKg,
    Pound: WeightPounds,
    Feet: HeightFeet,
    Inches: HeightInch,
    Centimeter: HeightCms,
    comment: Comments,
    source: Source,
    glucose: Glucose,
    recordDate: moment(RecordDate).format("Y-MM-DD"),
    // dependentID: dependentID ? dependentID : ''
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  

  const VitalFormSubmit = () => {
    if (BpSystolic === "" && BpDiastolic === "" && Pulse === "" & Respiration === "" && PeakFlow === "" && HeartRate === "" && GripStrength === "" && TemperatureC === "" && O2Saturation === "" && Vision === "" && WeightKg === "" && HeightCms === "" && Comments === "" && Glucose === "" && PainScale === "" && Position === 0) {
      toastr.warning("Empty Vitals", "Vitals should contain atleast a value");
    } else {

      Axios.post(API_ENDPOINT + "/patient/set-patient-vitals", VitalsformData)
        .then((res) => {
          if (res.status === 200) {
            toastr.success("Vitals Updated", "Vitals Updated Successfully");
            props.toggleModal();
            props.TableRenderVitals();
          }
        })
        .catch((error) => {
          // console.log(error);
          toastr.error("error occurred", "Vitals failed to update");
        });

    }



  };

  const AddVitals = () => {
    setHeightFeet("");
    setHeightInch("");
    setHeightCms("");
    setWeightPounds("");
    setWeightKg("");
    setPeakFlow("");
    setBmi("");
    setPercentile("");
    setBsa("");
    setTemperatureC("");
    setTemperatureF("");
    setPulse("");
    setGripStrength("");
    setPosition(0);
    setBpSystolic("");
    setBpDiastolic("");
    setHeartRate("");
    setRespiration("");
    setO2Saturation("");
    setGlucose("");
    setVision("");
    setPainScale("");
    setComments("");
    setSource("Patient");
  };

  useEffect(() => {

    if (WeightKg && HeightCms) {
      setBmiValue((WeightKg / ((HeightCms / 100) * (HeightCms / 100))).toFixed(1))
    }
    else {
      setBmiValue("");
    }
  }, [WeightKg, HeightCms]);

  useEffect(() => {

    if (WeightKg && HeightCms) {
      setBsaValue((Math.pow(WeightKg, 0.5378) * Math.pow(HeightCms, 0.3964) * 0.024265).toFixed(1))
    }
    else {
      setBsaValue("");
    }


  }, [WeightKg, HeightCms]);

  useEffect(() => {
    if (props.selectedDataEdit) {
      setTimeout(() => {
        RecordDateHandler(props.selectedDataEdit.recordDate)
      }, 1000);
    }
  }, [props.selectedDataEdit]); // eslint-disable-line

  let submitButton = (
    <>
      {" "}
      <Button color="primary mt-3" disabled onClick={() => VitalFormSubmit()}>
        Save
      </Button>{" "}
      {/* <span className="requiredcustom">Please select a record date</span> */}
    </>
  );
  if (RecordDate !== "") {
    submitButton = (
      <Button color="primary mt-3" onClick={() => VitalFormSubmit()}>
        Save
      </Button>
    );
  }

  return (
    <div className="vitals_info_form">
      <Row>
        <Col md="12">
          <Row>
            <Col>
              <p>
                Record Date <span className="mandatory-marker">*</span>
              </p>
            </Col>
            <Col>
              <DatePicker
                dateFormat="dd MMM yyyy"
                placeholderText="Select date"
                maxDate={moment().toDate()}
                onChange={(date) => RecordDateHandler(date)}
                selected={RecordDate}
                disabled={RecordDate ? true : false}
                title={RecordDate ? 'Disabled' : ''}
                className="form-control"
              />
            </Col>
          </Row>
        </Col>
        {RecordDate ? 
        <>
          <Col md="6">
            <Label>Height (ft / in / cm)</Label>
            <Row>
              <Col md="4" sm="4" xs="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="height_ft"
                    value={HeightFeet > 0 ? HeightFeet : ""}
                    onChange={(event) => HeightFeetHandler(event.target.value)}
                  />
                </FormGroup>
                <p className="down_label">ft</p>
              </Col>
              <Col md="4" sm="4" xs="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="height_inch"
                    value={HeightInch > 0 ? HeightInch : ""}
                    onChange={(event) => HeightInchHandler(event.target.value)}
                  />
                </FormGroup>
                <p className="down_label">in</p>
              </Col>
              <Col md="4" sm="4" xs="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="height_cms"
                    value={HeightCms > 0 ? HeightCms : ""}
                    onChange={(event) => HeightCmsHandler(event.target.value)}
                  />
                </FormGroup>
                <p className="down_label">cm</p>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Label>Weight (lbs / kg)</Label>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    name="weight_pounds"
                    value={WeightPounds > 0 ? WeightPounds : ""}
                    onChange={(event) =>
                      WeightPoundsHandler(event.target.value)
                    }
                  />
                </FormGroup>
                <p className="down_label">lbs</p>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    name="weight_kg"
                    value={WeightKg}
                    onChange={(event) => WeightKgHandler(event.target.value)}
                  />
                </FormGroup>
                <p className="down_label">kg</p>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col md="6">
                <Label>BMI:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="bmi"
                    /* value={(
                      WeightKg /
                      ((HeightCms / 100) * (HeightCms / 100))
                    ).toFixed(1)} */
                    value={bmiValue}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <Label>BSA:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="bsa"
                    /* value={WeightKg <= 0 && HeightCms <= 0 ? "" : (
                      Math.pow(WeightKg, 0.5378) *
                      Math.pow(HeightCms, 0.3964) *
                      0.024265
                    ).toFixed(1)} */
                    value={bsaValue}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Label>Temperature</Label>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    name="temp_F"
                    value={TemperatureF}
                    onChange={(event) =>
                      TemperatureFHandler(event.target.value)
                    }
                  />
                </FormGroup>
                <p className="down_label">F</p>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    type="text"
                    name="temp_c"
                    value={TemperatureC}
                    onChange={(event) =>
                      TemperatureCHandler(event.target.value)
                    }
                  />
                </FormGroup>
                <p className="down_label">C</p>
              </Col>
            </Row>
          </Col>
          <Col md="3">
            <Label>Pulse</Label>
            <FormGroup>
              <Input
                type="text"
                name="pulse"
                value={Pulse}
                onChange={(event) => PulseHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Peak Flow</Label>
            <FormGroup>
              <Input
                type="text"
                name="peak_flow"
                value={PeakFlow}
                onChange={(event) => PeakFlowHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Grip Strength</Label>
            <FormGroup>
              <Input
                type="text"
                name="grip_strength"
                value={GripStrength}
                onChange={(event) => GripStrengthHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Position</Label>
            <FormGroup>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={PositionOptions}
                value={PositionOptions.find((obj) => obj.value === +Position)}
                onChange={(event) => PositionHandler(event.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>BP-Systolic</Label>
            <FormGroup>
              <Input
                type="text"
                name="bp_systolic"
                value={BpSystolic}
                onChange={(event) => BpSystolicHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>BP-Diastolic</Label>
            <FormGroup>
              <Input
                type="text"
                name="bp_diastolic"
                value={BpDiastolic}
                onChange={(event) => BpDiastolicHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Heart Rate</Label>
            <FormGroup>
              <Input
                type="text"
                name="heart_rate"
                value={HeartRate}
                onChange={(event) => HeartRateHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Respiration</Label>
            <FormGroup>
              <Input
                type="text"
                name="respiration"
                value={Respiration}
                onChange={(event) => RespirationHandler(event.target.value)}
              />
            </FormGroup>
            <p className="down_label">rt/min</p>
          </Col>
          <Col md="3">
            <Label>O2 Saturation</Label>
            <FormGroup>
              <Input
                type="text"
                name="02_saturation"
                value={O2Saturation}
                onChange={(event) => O2SaturationHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Glucose</Label>
            <FormGroup>
              <Input
                type="text"
                name="glucose"
                value={Glucose}
                onChange={(event) => GlucoseHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Vision</Label>
            <FormGroup>
              <Input
                type="text"
                name="vision"
                value={Vision}
                onChange={(event) => VisionHandler(event.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <Label>Pain Scale</Label>
            <FormGroup>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={PainScaleoptions}
                isSearchable
                value={PainScaleoptions.find((obj) => obj.value === PainScale)}
                onChange={(event) => PainScaleHandler(event.value)}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <Label>Comments</Label>
            <Input
              type="textarea"
              row="4"
              value={Comments}
              onChange={(event) => CommentsHandler(event.target.value)}
            />
          </Col>
        </> : null }
      </Row>
      {RecordDate ? 
      <Row className="row" style={{ 'justify-content': 'center' }}>
        {submitButton}
        <Button color="outline-primary mt-3 ml-2" onClick={props.toggleModal}>
          Cancel
        </Button>
      </Row> : null }
    </div>
  );
};

export default VitalsInput;
