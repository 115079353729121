import React, {useEffect } from "react";
import {
  CardCvcElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import "./CvcForm.css";

export default function CvcForm({setPaymentDetails, setError, setDisabled}) {
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    setPaymentDetails({stripe: stripe, elements: elements, CardCvcElement: CardCvcElement});
  }, [stripe, elements, CardCvcElement]); // eslint-disable-line

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  return (
    <>
      <CardCvcElement id="cvv-element" options={cardStyle} onChange={handleChange} />
    </>
  );
}