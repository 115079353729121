import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  FormText,
  CustomInput,
  Button,
  UncontrolledAlert
} from "reactstrap";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import { useTranslation } from "react-i18next";
import "./InstantCall.css";

const options = [{ value: "Myself", label: "Myself" }];


const InstantCall = (props) => {
  const { t } = useTranslation();
  const location = useLocation(); // eslint-disable-line
  const [errorStatus, setErrorStatus] = useState(false); // eslint-disable-line
  const [responseFields, setResponseFields] = useState( // eslint-disable-line
    { message: 'Error occured', status: false }
  );

  return (
    <>
    <div className="main h-100 w-100">
    {errorStatus && (
      <UncontrolledAlert color={responseFields.status ? 'primary' : 'danger'}>
        <div className="alert-message">
          {responseFields.message}
        </div>
      </UncontrolledAlert>)
    }
    <PageLayout>
      <Header>
        <HeaderTitle>{t('videoConsultationWithDoctors')}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/calls">{t('call')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('create')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {t('whoDoYouWantToTalkAbout')}
                    </Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={options}
                      isSearchable
                    />
                    <FormFeedback>{t('youWillNotBeAbleToSaveThis')}</FormFeedback>
                    <FormText>
                      {t('saveThisForNextVisit')}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">{t('relationship')}</Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={options}
                      isSearchable
                      placeholder={t('selectOrEnterNewPH')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleDate2">{t('dateOfBirth')}</Label>
                    <Input
                      type="date"
                      name="date"
                      id="exampleDate2"
                      placeholder={t('datePH')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleCheckbox">{t('gender')}</Label>
                    <div>
                      <CustomInput
                        type="radio"
                        id="radio1"
                        name="customRadio"
                        label={t('male')}
                        className="mb-2"
                        defaultChecked
                        inline
                      />
                      <CustomInput
                        type="radio"
                        id="radio2"
                        name="customRadio"
                        label={t('female')}
                        className="mb-2"
                        inline
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText1">{t('allergies')}</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText1"
                      placeholder={t('allergyPH')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText2">{t('medications')}</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText2"
                      placeholder={t('medicationPH')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText3">
                      {t('whatDoYouWantToTalkAbout')}
                    </Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText3"
                      placeholder={t('shareReasonsForCall')}
                    />
                    <FormFeedback>{t('youWillNotBeAbleToSaveThis')}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleDate">{t('date')}</Label>
                    <Input
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder={t('datePH')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleCustomSelectDisabled">{t('timeSlot')}</Label>
                    <CustomInput
                      type="select"
                      id="exampleCustomSelectDisabled"
                      name="customSelect"
                      disabled
                    >
                      <option value="">-- Select --</option>
                      <option>Value 1</option>
                      <option>Value 2</option>
                      <option>Value 3</option>
                      <option>Value 4</option>
                      <option>Value 5</option>
                    </CustomInput>
                  </FormGroup>
                  <div className="text-center">
                    <Button color="primary">
                      <FontAwesomeIcon icon={faCalendar} />{" "}
                      <span className="margin-left-10">{t('scheduleCall')}</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageLayout>
    </div>
    </>
  );
}


export default InstantCall;
