import React, { useContext, useEffect, useState } from "react";
import { cookieService } from '../../../services/cookieService';
import { GlobalState } from '../../../context/ContextApi';
import defaultAvatar from './../../../assets/avatar.png';
import settingsicon from "../../../assets/myaccount.png";
import logouticon from "../../../assets/logout.png";
import pharmacyicon from "../../../assets/pharmacy.png";
import healthicon from "../../../assets/health.png";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import './navbar.css';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import ProfileConfirmModal from "./ProfileConfirmModal";

import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCog,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";



const NavbarToggle = (props) => {
  const { hideMenu, setHideMenu } = useContext(GlobalState);
  const toggleMenu = () => {
    setHideMenu(!hideMenu);
  };
  return (
    <div style={{color:'#1e1e1f'}} >
      <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => toggleMenu()}
    >
<i className="hamburger align-self-center" />
    </span>
    </div>
  );
};

const ProfilePic = () => {
  const { Details } = useContext(GlobalState);
  const { LastName, FirstName } = useContext(GlobalState);
  const { isIncompleteInfoPresent, setIsIncompleteInfoPresent } = useContext(GlobalState); // eslint-disable-line
  const { completedProfileData, setCompletedProfileData } = useContext(GlobalState); // eslint-disable-line
  const { t } = useTranslation();
  const signOut = () => {
    cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('refresh_token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('dependentID', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('newProfilePic', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    localStorage.removeItem("userDetail");
    window.location = "/"
  }

  let ProfilePic = Details.profileImageURL ? Details.profileImageURL : defaultAvatar;
  if(cookieService.get('newProfilePic'))
  {
    ProfilePic = cookieService.get('newProfilePic');
  }
  return (
    // <PopupMenu>
    <>
    {/* <div className="nav-bar-profile-pic">
      <img
        alt="profile pic"
        src={Details.profileImageURL ? Details.profileImageURL : defaultAvatar}
        className="rounded-circle img-responsive mt-2"
        width="35"
        height="35"
      />
      
    </div> */}
    <Collapse navbar>
      <Nav className={"ml-auto"} navbar>
      {isIncompleteInfoPresent &&
      <AlertForNoData completedProfileData={completedProfileData}/>
      }
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
        {FirstName ? <DropdownToggle nav caret>
          <div className="profile-img">
            <img
              alt="profile pic"
              src={ProfilePic}
              className="rounded-circle img-responsive mt-2"
              width="42"
              height="42"
            />
            
            {/* <span className="profile-name ml-2">{FirstName + " " + LastName}</span> */}
           
                    <h4 className="profile-name ml-2">{FirstName + " " + LastName}</h4>
                    </div>
            {/* <Chip size="6"
              avatar={<Avatar alt="Natacha" src={ProfilePic} />}
              label="John Lopez"
              color="primary"
            /> */}
      
          </DropdownToggle> : null}
         
          <DropdownMenu right>
            {/* <Dependents /> */}
            <ProfileConfirmModal  />
            
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
    </>
  )
}

const AlertForNoData = ({completedProfileData}) => {
  let history = useHistory();
  const [openModals, setOpenModals] = useState(false);
  let inCompletedPharmacy = completedProfileData.indexOf("Pharmacy") > -1;
  let inCompletedHealthSection = completedProfileData.replace(", Pharmacy", '').replace("Pharmacy", '');
  const toggle = (index) => {
    setOpenModals(index)
  };

  const goToPage = (pageId) => {
    setOpenModals(false);
    history.push(pageId);
  };

  useEffect(() => {
    if(completedProfileData.length > 0 && window.showIncompleteProfileModal) {
      delete window.showIncompleteProfileModal;
      setOpenModals(true);
    }
  }, [completedProfileData])

  return (
    <div className="nav-bar-profile-pic">
      <span onClick={() => toggle(true)} style={{cursor:'pointer'}}>
    {/* <Col xs="2" className="icon-container" 
    // style={{right: '1.7em'}}
    > */}
      <FontAwesomeIcon icon={faExclamationTriangle} className="profile-warning" />
     {/* </Col> */}
     </span>
     <Modal
            isOpen={openModals}
            toggle={() => toggle(false)}
            centered
          >
            <ModalBody className=" m-3 PharmaciesModal">
            <div className="pharmacy_info">
            <Row>
              <Col md="12">
                <div className="ModalHeader">
                  <h3>Complete your profile</h3>
                  <hr></hr>
                </div>
              </Col>
            </Row>
            <Row>
            <Col md="12">
              <h5>Looks like your health profile is incomplete. For the best medical decisions, it is advisable to provide the following information</h5>
            </Col>
            </Row>
            <Row>
              <div className="itemsExclamationContainer">
                {inCompletedHealthSection && inCompletedHealthSection.length > 2 ?
                (
                  <div className="item" onClick={() => goToPage('/health-profile')}>
                    <img src={healthicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/>
                    <span className="sidebar_title">Health Profile</span>
                    <br></br>
                    <div className="sidebar_title text-center">({inCompletedHealthSection})</div>
                  </div>
                ) : null
                }
              {inCompletedPharmacy ? 
              (
                <div className="item itemTwo" onClick={() => goToPage('/care-team')}>
                  <img src={pharmacyicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/>
                  <span className="sidebar_title">Pharmacies</span>
                </div>
              ) : null }
              </div>
            </Row>
            </div>
            </ModalBody>
            <ModalFooter style={{justifyContent: "center"}}>
              <Button color="secondary" onClick={() => setOpenModals(false)}>Dismiss</Button>
            </ModalFooter>
          </Modal>
  </div>
  )
}

const NavbarComponent = (props) => {
  return (
    <Navbar expand className="navbar-theme" >
      <div style={{color:'black'}} >
      <NavbarToggle toggleFunction={props.toggleFunction} />
      </div>
      {/* <NavbarSearch /> */}
      {/* <div className="navbar-right-icon"> */}
      <ProfilePic />
      {/* </div> */}
      {/* <NavbarDropdowns /> */}
    </Navbar>
  );
};

export default NavbarComponent;

// const ConfirmModal = () => {
//   console.log('confrm');


// const toggle = (index) => {
//   setConfirmModal(index)
// };

// // useEffect(() => {
// //   if(dependentId) {
// //     setConfirmModal(true);
// //   }
// // }, [])

// return (
//   <div className="nav-bar-profile-pic">
//    <Modal
//           isOpen={confirmModal}
//           toggle={() => toggle(false)}
//           centered
//         >
//           <ModalBody className=" m-3 PharmaciesModal">
//           <div className="pharmacy_info">
//           <Row>
//             <Col md="12">
//               <div className="ModalHeader">
//                 <h3>Complete your profile</h3>
//                 <hr></hr>
//               </div>
//             </Col>
//           </Row>
//           <Row>
//           <Col md="12">
//             <h5>Looks like your health profile is incomplete. For the best medical decisions, it is advisable to provide the following information</h5>
//           </Col>
//           </Row>
//           <Row>
            
//           </Row>
//           </div>
//           </ModalBody>
//           <ModalFooter style={{justifyContent: "center"}}>
//             <Button color="secondary" onClick={() => setConfirmModal(false)}>Dismiss</Button>
//           </ModalFooter>
//         </Modal>
// </div>
// )
//   // return (
//   //   <ProfileConfirmModal
//   //     dependentId={dependentId}
//   //     newProfilePic={newProfilePic}
//   //   />
//   // );
// }

// const [confirmModal, setConfirmModal] = useState(false);

// const notifications = [ // eslint-disable-line
//   {
//     type: "important",
//     title: "Update completed",
//     description: "Restart server 12 to complete the update.",
//     time: "2h ago",
//   },
//   {
//     type: "default",
//     title: "Lorem ipsum",
//     description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
//     time: "6h ago",
//   },
//   {
//     type: "login",
//     title: "Login from 192.186.1.1",
//     description: "",
//     time: "8h ago",
//   },
//   {
//     type: "request",
//     title: "New connection",
//     description: "Anna accepted your request.",
//     time: "12h ago",
//   },
// ];



// const NavbarDropdown = ({ // eslint-disable-line
//   children,
//   count,
//   header,
//   footer,
//   icon,
//   active,
//   indicator,
// }) => (
//     <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
//       <DropdownToggle nav style={{color:'blue'}} className="nav-icon dropdown-toggle position-relative">
//         <FontAwesomeIcon icon={icon} className="align-middle" />
//         {indicator ? <span className="indicator" /> : ""}
//       </DropdownToggle>
//       <DropdownMenu right className="dropdown-menu-lg py-0">
//         <div className="dropdown-menu-header position-relative">
//           {count} {header}
//         </div>
//         <ListGroup>{children}</ListGroup>
//         <DropdownItem header className="dropdown-menu-footer">
//           <span className="text-muted">{footer}</span>
//         </DropdownItem>
//       </DropdownMenu>
//     </UncontrolledDropdown>
//   );

// const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => ( // eslint-disable-line
//   <ListGroupItem>
//     <Row noGutters className="align-items-center">
//       <Col xs={2}>{icon}</Col>
//       <Col xs={10} className={spacing ? "pl-2" : null}>
//         <div className="text-dark">{title}</div>
//         <div className="text-muted small mt-1">{description}</div>
//         <div className="text-muted small mt-1">{time}</div>
//       </Col>
//     </Row>
//   </ListGroupItem>
// );

// const NavbarSearch = () => {
//   return (
//     <Form inline>
//       {/* <Input
//         type="text"
//         placeholder={t('search')}
//         aria-label="Search"
//         className={classNames("form-control-lite")}
//       /> */}
//     </Form>
//   );
// };

// const NavbarDropdowns = () => {
//   const { t } = useTranslation();
//   const signOut = () => {
//     cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
//     cookieService.remove('refresh_token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
//     cookieService.remove('dependentID', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
//     cookieService.remove('newProfilePic', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
//     localStorage.removeItem("userDetail");
//     window.location = "/"
//   }
//   return (
//     <Collapse navbar>
//       <Nav className={"ml-auto"} navbar>
//         {/* <NavbarDropdown
//           header={t('newNotifications')}
//           footer={t('showAllNotifications')}
//           icon={faBell}
//           count={notifications.length}
//           indicator
//         >
//           {notifications.map((item, key) => {
//             let icon = (
//               <FontAwesomeIcon icon={faEnvelopeOpen} className="text-warning" />
//             );

//             if (item.type === "important") {
//               icon = <FontAwesomeIcon icon={faBell} className="text-danger" />;
//             }

//             if (item.type === "login") {
//               icon = (
//                 <FontAwesomeIcon icon={faBuilding} className="text-primary" />
//               );
//             }

//             if (item.type === "request") {
//               icon = (
//                 <FontAwesomeIcon icon={faBellSlash} className="text-success" />
//               );
//             }

//             return (
//               <NavbarDropdownItem
//                 key={key}
//                 icon={icon}
//                 title={item.title}
//                 description={item.description}
//                 time={item.time}
//               />
//             );
//           })}
//         </NavbarDropdown> */}

//         <UncontrolledDropdown nav inNavbar className="ml-lg-1">
//           <DropdownToggle nav caret>
//             <FontAwesomeIcon icon={faCog} className="align-middle" />
//           </DropdownToggle>
//           <DropdownMenu right>
//             <Link to="/settings/profile">
//               <DropdownItem>
//                 <div className="">
//                   <img src={settingsicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/>
//                   {t('myAccount')}
//                 </div>
//             </DropdownItem>
//             </Link>
//             <DropdownItem divider />
//             <DropdownItem onClick={signOut}>
//             <div className="">
//                   <img src={logouticon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/>
//                   {t('signOut')}
//                 </div>
//             </DropdownItem>
//           </DropdownMenu>
//         </UncontrolledDropdown>
//       </Nav>
//     </Collapse>
//   );
// };

// const Dependents = () => {
//   const { Details } = useContext(GlobalState)
  

//   const { t } = useTranslation();
//   // let dependentID = cookieService.get('dependentID');

//   return (
//     <div>
//     {/* <DropdownItem onClick={() => profileHandler(0, Details.profileImageURL)}> */}
//       <div className="profile-card">
//         <img
//           alt="profile pic"
//           // src={Details.profileImageURL ? Details.profileImageURL : defaultAvatar}
//           src="http://localhost:8016/viewprofimg/MTY5NjUwMDI0MDI1MjcuanBlZw=="
//           className="rounded-circle img-responsive mt-2"
//           width="64"
//           height="64"
//         />
//       </div>
//       <div className="text-center">
//         <div className="profile-main ml-2">{Details.name}</div>
//         <div className="ml-2 relation-text">Parent </div>
//       </div>
//             {/* </DropdownItem> */}
//             <DropdownItem divider />

//     {Details?.dependents?.map((dep) => (<>
      
//     <DropdownItem >
//                 <div className="flex">
//                   {/* <img src={settingsicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/> */}
//                   <img
//                     alt="Dependent profile pic"
//                     src={dep.profileImageURL ? dep.profileImageURL : defaultAvatar}
//                     className="rounded-circle img-responsive "
//                     width="35"
//                     height="35"
//                   />
//                   <div className="">
//                     <div className="ml-2">{dep.name} </div>
//                     <div className="ml-2 relation-text">{dep.relation} </div>
//                   </div>
//                 </div>
//             </DropdownItem>
//             </>
//             ))}
    
      
//     </div>
//   );
// }

        {/* <Collapse navbar>
      <Nav className={"ml-auto"} navbar>
        

        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            <Link to="/settings/profile">
              <DropdownItem>
                <div className="">
                  <img src={settingsicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/>
                  {t('myAccount')}
                </div>
            </DropdownItem>
            </Link>
            <DropdownItem divider />
            <DropdownItem onClick={signOut}>
            <div className="">
                  <img src={logouticon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/>
                  {t('signOut')}
                </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse> */}

