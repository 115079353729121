import React, { useEffect, useState, useContext } from "react";
import axios from 'axios'
import './OpenTok.css'
import { CardBody } from "reactstrap"
import Publisher from '../OpenTok/Publisher/Publisher'
import { toastr } from "react-redux-toastr";
import { useLocation } from "react-router-dom";
import { GlobalState } from "../../../context/ContextApi";
import CryptoJS from "crypto-js";
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import { cookieService } from '../../../services/cookieService';

const OpenTok = () => {
    // GLOBAL CONTEXT
    const {
        setPhysicianName,
        setPhysicianTitle,
        setAppointmentTime,
        setAppointmentEndTime,
        setAppointmentInfo,
        setSuperUserSession,
        setPatientName,
    } = useContext(GlobalState)

    // LOCAL VARIABLES
    const [OTtoken, setOTtoken] = useState("")
    const [sessionId, setSessionId] = useState("")
    let apiKey = process.env.REACT_APP_OPENTOK_API_KEY;
    const [appointmentId, setAppointmentId] = useState("")

    // ACCESSING APPOINTMENTID FROM URL
    let location = useLocation();
    const getAppoinmentIDFromURL = () => {
        let path = location.pathname;
        let ciphertext = path.slice(20)
        var bytes = CryptoJS.AES.decrypt(ciphertext.toString(), 'lock');
        var id = bytes.toString(CryptoJS.enc.Utf8);
        if (id) setAppointmentId(id)
    }

    // let dependentID = cookieService.get('dependentID');
    const getTokenFromAPI = () => {
        axios.post(`econsult/start-session`, {
            appointmentId: appointmentId,
            // dependentID: dependentID
        })
            .then(function (response) {
                // console.log(response);
                setOTtoken(response.data.data.token);
                setSessionId(response.data.data.sessionId);
                setSuperUserSession(response.data.data.superUserSession);
                console.log('ss', response.data.data.superUserSession);
            })
            .catch(function (error) {
                // console.log(error);
                showToastrWarning(error.response.data.title)
            });
    }

    const getAppointmentDetails = () => {
        axios.get(`patient/appointment/details/${appointmentId}`)
            .then(function (response) {
                // console.log(response);
                setPhysicianTitle(response.data.data.physicianDetails.title);
                setPhysicianName(response.data.data.physicianDetails.name);
                setAppointmentTime(moment.utc(response.data.data.appointmentDetails.schStartTime).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME));
                setAppointmentEndTime(moment.utc(response.data.data.appointmentDetails.schEndTime).local().format("h:mm A"));
                setAppointmentInfo(response.data.data);
                setSuperUserSession(response.data.data.superUserAccess);
                setPatientName(response.data.data.appointmentDetails.name);
                console.log('sua', response.data.data.superUserAccess);
            })
            .catch(function (error) {
                // console.log(error);
                showToastrWarning(error.response.data.title)
            });
    }

    // NOTIFICATION PANEL FOR WARINING
    const showToastrWarning = (message, subMessage = "") => {
        const options = {
            timeOut: parseInt(5000),
            showCloseButton: true,
            progressBar: false,
        };

        const toastrInstance = toastr.error;
        toastrInstance(
            message, subMessage,
            options
        );
    }

    useEffect(() => { if (appointmentId === "") getAppoinmentIDFromURL() }, [appointmentId]); // eslint-disable-line
    useEffect(() => { if (appointmentId) getTokenFromAPI() }, [appointmentId]); // eslint-disable-line
    useEffect(() => { if (appointmentId) getAppointmentDetails() }, [appointmentId]); // eslint-disable-line

    return (
        <CardBody className="opentok-card-body">
            <Publisher props={{
                apiKey: apiKey,
                sessionId: sessionId,
                token: OTtoken,
            }} />
        </CardBody>
    )
}

export default OpenTok
