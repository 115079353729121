import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Button } from "reactstrap";
import axios from 'axios';
import PdfViewer from "./PdfViewer";
import BlankStateCard from './BlankStateCard';//eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import DocImage from "./../doc_image.png";
import TiffImage from "./../tiff_image.png";

const DocumentViewer = (props) => {
  // DEPENDENCY VARIABLES 
  const {
    modalState,
    setModalState,
    fileURL,
    fileExtension,
    setFileURL,
    fileName
  } = props;

  // GET TOKEN
  let token = localStorage.getItem("token");

  // AXIOS REQUEST CANCELLATION VARIABLE
  var CancelToken = axios.CancelToken;
  var source = CancelToken.source();

  // LOCAL VARIABLES
  let pdfWrapper = null;
  const [loading, setLoading] = useState(true);
  const [downloading, setDownload] = useState(false);
  const [document, setDocument] = useState("");
  const [preview, setPreview] = useState("");
  const [pdfContainerWidth, setPdfContainerWidth] = useState();

  // TOGGLE MODAL 
  const toggle = () => {
    source.cancel('Request canceled by the user');
    setLoading(true);
    setDownload(false);
    setDocument("");
    setPreview("");
    setModalState(!modalState);
    setFileURL("");
  };

  const setPdfSize = () => {
    if (pdfWrapper) {
      setPdfContainerWidth(pdfWrapper.getBoundingClientRect().width * 0.9);
    }
  }

  // DOWNLOAD BUTTON 
  const downLoadButton = (
    <Button
      color="primary"
      disabled={loading}
      title={loading ? "Loading..." : "Download File"}
      onClick={() => { downloadFile() }
      }>
      <FontAwesomeIcon
        icon={faCloudDownloadAlt}
        fixedWidth
        className="align-middle mr-2"
      />
    Download File
    </Button>
  )

  // API CALL TO GET SELFIE IMAGE
  const getSelfieImage = () => {
    axios.get(fileURL, {
      headers: {
        Authorization: token,
      },
      responseType: 'arraybuffer',
      cancelToken: source.token,
    })
      .then(function (response) {
        let image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        if (fileExtension.toLowerCase() === "jpg" || fileExtension.toLowerCase() === "jpeg" || fileExtension.toLowerCase() === "png") setDocument(`data:${response.headers['content-type'].toLowerCase()};base64,${image}`);
        if (fileExtension.toLowerCase() === "pdf") setPreview(Buffer.from(response.data, "binary").toString("base64"));
        setLoading(false);
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });

  }

  // API CALL TO DOWNLOAD FILE
  const downloadFile = () => {
    setDownload(true);
    axios.get(fileURL, {
      headers: {
        Authorization: token,
      },
      responseType: 'arraybuffer',
      cancelToken: source.token,
    })
      .then(function (response) {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(response.data, `${fileName}`);
          toastr.success("Done", "Document downloaded successfully");
          setDownload(false);
        }
      })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });

  }

  useEffect(() => { if (fileURL) getSelfieImage() }, [fileURL]) // eslint-disable-line
  useEffect(() => { setPdfSize(); }, []); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={modalState} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Document Viewer
        </ModalHeader>
        <ModalBody>
          <div className="pdf-viewer disable-scrollbars">
            <div className="prescription-pdf-viewer-container" ref={(ref) => pdfWrapper = ref}>
              {loading || downloading ? <div className="doc-viewer-spinner"><Spinner color="default" /></div> : ""}
              {/* IMAGE VIEWER */}
              {!loading
                && (fileExtension.toLowerCase() === "jpg"
                  || fileExtension.toLowerCase() === "jpeg"
                  || fileExtension.toLowerCase() === "png")
                ? <img src={document} alt="document-preview" className="image-viewer" />
                : ""}

              {/* PDF VIEWER */}
              {!loading && fileExtension.toLowerCase() === "pdf"
                ? <PdfViewer width={pdfContainerWidth} url={preview} />
                : ""}

              {/* UNSUPPORTED FILE */}
              {/* {!loading
                && fileExtension.toLowerCase() !== "pdf"
                && fileExtension.toLowerCase() !== "jpg"
                && fileExtension.toLowerCase() !== "jpeg"
                && fileExtension.toLowerCase() !== "png"
                ? <div className="doc-viewer-blank-state"><BlankStateCard /></div>
                : ""} */}
                {!loading && (fileExtension.toLowerCase() === "doc"
                  || fileExtension.toLowerCase() === "docx")
                ? <div className="file-preview">
                  <img src={DocImage} className="doc-type-image" alt="file-preview"></img>
                </div>
                  : ""}

                {!loading && fileExtension.toLowerCase() === "tiff"
                ? <div className="file-preview">
                <img src={TiffImage} className="doc-type-image" alt="file-preview"></img>
              </div>
                : ""}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="top-download-button">{downLoadButton}</div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentViewer;
