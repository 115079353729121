import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import Select from "react-select";
import * as Validation from "./../../../common/FieldValidation";
import { cookieService } from '../../../services/cookieService';

import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Axios from "axios";

const AllergyInput = (props) => {
  const [Allergies, setAllergies] = useState([]);
  const AllergyList = Allergies.map((key) => {
    return { value: key.id, label: key.name };
  });

  const [Allergy, setAllergy] = useState({
    value: "",
    label: "Select an option",
  });

  const [Severities, setSeverities] = useState([ // eslint-disable-line
    { value: "Mild", label: "Mild" },
    { value: "Medium", label: "Medium" },
    { value: "Severe", label: "Severe" },
  ]);

  const [Severity, setSeverity] = useState({
    value: "Mild",
    label: "Mild",
  });

  const [OnSetDate, setOnSetDate] = useState("");
  const [Occurrence, setOccurrence] = useState("");
  const [Reaction, setReaction] = useState("");

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchAllergies = () => {
    Axios.get(API_ENDPOINT+"/common/allergy-list")
      .then((response) => {
        if (response.status === 200) {
          setAllergies(response.data.data);
        }
      })
      .catch((error) => {});
  };

  const setAllergyMap = (key) => {
    setAllergy({
      value: key.id,
      label: key.name,
    });
  };

  const AllergyTypeHandler = (value) => {
    setAllergy(value);
    if (Allergies.length > 0) {
      Allergies.filter((key) => key.id == value).map((filterallergy) => // eslint-disable-line
        setAllergyMap(filterallergy)
      );
    }
  };

  const SeverityHandler = (value) => {
    setSeverity(value);
  };

  const OnsetDateHandler = (date) => {
    // console.log(date)
    setOnSetDate(date);
  };

  const OccurrenceHandler = (value) => {
    Validation.Occurrence(value,setOccurrence,50)
  };

  const ReactionHandler = (value) => {
    Validation.Reaction(value,setReaction,50)
  };
  // let dependentID = cookieService.get('dependentID');
  const submitAllergyForm = () => {
    // console.log(moment(OnSetDate).add(12, 'hours'))
    const formToSubmit = {
      onsetDate:   moment(OnSetDate).add(12, 'hours'),
      allergyId: Allergy.value,
      occurance: Occurrence,
      severity: Severity.value,
      reaction: Reaction,
      // dependentID: dependentID ? dependentID : 0
    };

    Axios.post(
      API_ENDPOINT+"/patient/set-patient-allergy",
      formToSubmit
    )
      .then((res) => {
        if (res.status === 200) {
          toastr.success("Allergy Added", "Allergy Added Successfully");
          props.toggleModal();
          props.TableRenderAllergies();
        }
      })
      .catch((error) => {
        toastr.error("error occurred", "Failed to create new Allergy");
      });
  };

  let SubmitButton = "";

  if (Allergy.value == "" ) { // eslint-disable-line
    SubmitButton = (
      <Button color="primary" disabled onClick={() => submitAllergyForm()}>
        Save
      </Button>
    );
  } else {
    SubmitButton = (
      <Button color="primary" onClick={() => submitAllergyForm()}>
        Save
      </Button>
    );
  }

  useEffect(() => {
    fetchAllergies();
  }, []); // eslint-disable-line

  return (
    <div className="allergy_info">
      {/* <Row>
        <Col md="12">
        <div className="ModalHeader"><h3>Add Allergy</h3></div>
        </Col>
      </Row> */}
      <Row>
        <Col md="4">
          <Label>Type of Allergy{" "}<span className="mandatory-marker">*</span></Label>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={AllergyList}
              onChange={(event) => AllergyTypeHandler(event)}
              value={Allergy}
              isSearchable
              placeholder="Select "
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <Label>Severity{" "}</Label>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={Severities}
              isSearchable
              onChange={(event) => SeverityHandler(event)}
              value={Severity}
              required
              placeholder="Select Severity"
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup className="bordered">
            <Label>Onset Date{" "}</Label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              placeholderText="Select date"
              maxDate={moment().toDate()}
              selected={OnSetDate}
              onChange={(date) => OnsetDateHandler(date)}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>Occurrence{" "}</Label>
            <Input
              required
              onChange={(event) => OccurrenceHandler(event.target.value)}
              type="text"
              name="occurrence"
              placeholder="Occurrence"
              value={Occurrence}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>Reaction{" "}</Label>
            <Input
              required
              onChange={(event) => ReactionHandler(event.target.value)}
              type="text"
              name="reaction"
              placeholder="Reaction"
              value={Reaction}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="row" style={{'justify-content': 'center'}}>
      {SubmitButton}
          <Button color="outline-primary ml-2" onClick={props.toggleModal}>
            Cancel
          </Button>
          
        </Row>
    </div>
  );
};

export default AllergyInput;
