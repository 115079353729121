import React from 'react'
import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import "./Documents.css"
import {
    Card,
    CardBody,
    Col,
    Row
  } from "reactstrap";
import Clinical from './Clinical/Clinical';

 const ClinicalDocuments = () => {
    return (
        <div className="main h-100 w-100 documents">
        <PageLayout>
          <Header>
            <HeaderTitle>My Clinical Records</HeaderTitle>
          </Header>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Clinical />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </PageLayout>
      </div>
    )
}
export default ClinicalDocuments;