import axios from 'axios';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


const instance = axios.create({
    baseURL: API_ENDPOINT
});

instance.interceptors.request.use(request => {
    request.headers['Content-Type'] =  'application/json';
    return request;
  }, error => {
    return Promise.reject(error);
  });
  
  instance.interceptors.response.use(response => {
    return response;
   }, error => {
    return Promise.reject(error);
  });

export default instance;