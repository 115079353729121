import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import Axios from "axios";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from "./../../../context/ContextApi";
import * as Validation from "./../../../common/FieldValidation";
import AdvancedPharmacySearchModal from './AdvancedPharmacySearchModal';
import "../CareTeam.css";
import { cookieService } from '../../../services/cookieService';

const PharmacyInput = (props) => {

  const { Details, patientPharmacy } = useContext(GlobalState);

  const [IsFavourite, setIsFavourite] = useState("0");
  const [Label, setLabel] = useState("");
  const [Countries, setCountries] = useState([]);
  const [pharmacyData, setPharmacyData] = useState([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState({value: 'none', label: 'none', address: 'none'});
  const [pharmacyOptions, setPharmacyOptions] = useState([]);
  const [advancedSearchModal, setAdvancedSearchModal] = useState(false);
  const CountryList = Countries.map((key) => {
    return { value: key.id, label: key.country_name };
  });

  const [CountryName, setCountryName] = useState("");

  const [Country, setCountry] = useState({
    value: "",
    label: "",
  });

  const [Address, setAddress] = useState("");
  const [NewAddress, setNewAddress] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [ContactNumber, setContactNumber] = useState("");

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');

  useEffect(() => {
    Axios.get('common/get-pharmacy-list')
    .then(function (response) {
      if(response && response.data && response.data.data && response.data.data.length > 0) {
        setPharmacyData(response.data.data);
      } else {
        setPharmacyData([]);
      }
    })
    .catch(function (error) {
      setPharmacyData([]);
    });
  }, []);

  useEffect(() => {
    setPharmacyOptions(pharmacyData.map((list) => {
      return { value: list.id, label: list.name, address: list.address, city: list.city,state : list.state, zipcode: list.zipcode };
    }));
  }, [pharmacyData])

  useEffect(() => {
    if(selectedPharmacy.value !== "none") {
      setLabel(selectedPharmacy.label);
      let activePharmacy = pharmacyData.filter(pharmacy => selectedPharmacy.value === pharmacy.id);
      if(activePharmacy.length > 0) {
        if(activePharmacy[0].countryID) {
          setCountry(
            Countries.filter((a) => a.id == activePharmacy[0].countryID).map((b) => ({
              value: b.id,
              label: b.country_name,
            }))
          );
          setCountryName(
            Countries.filter((a) => a.id == activePharmacy[0].countryID).map(
              (b) => b.country_name
            )[0]
          );
        } else {
          setCountry({
            value: "",
            label: "",
          });
          setCountryName("");
        }
        
        if(activePharmacy[0].address) {
          setAddress(activePharmacy[0].address)
          setNewAddress(activePharmacy[0].address)
        } else {
          setAddress("")
          setNewAddress("")
        }
        

        if(activePharmacy[0].state) {
          setState(activePharmacy[0].state)
        } else {
          setState("")
        }

        if(activePharmacy[0].city) {
          setCity(activePharmacy[0].city)
        } else {
          setCity("")
        }

        if(activePharmacy[0].zipcode) {
          setZipCode(activePharmacy[0].zipcode)
        } else {
          setZipCode("")
        }

        if(activePharmacy[0].contactNumber) {
          setContactNumber(activePharmacy[0].contactNumber)
        } else {
          setContactNumber("")
        }
      }
    } else {
      setLabel("");
    }
  }, [selectedPharmacy]) // eslint-disable-line

 const  PopulateOwnCountry =()=>{
  //  console.log(Countries)
  //  console.log(Details.countryCode)
   Countries.filter((a) => a.country_isd_code === Details.countryCode).map((b) => console.log({
    value: b.id,
    label: b.country_name,
  }))
  setCountry(
    Countries.filter((a) => a.country_isd_code === Details.countryCode).map((b) => ({
      value: b.id,
      label: b.country_name,
    }))
  );
  setCountryName(
    Countries.filter((a) => a.country_isd_code === Details.countryCode).map(
      (b) => b.country_name
    )[0]
  );
 }
 
  

  const fetchCountries = () => {
    Axios.get(`${API_ENDPOINT}/common/country-list`)
      .then(function (response) {
        setCountries(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const submitPharmacyForm = () => {
    const formToSubmit = {
      isFavourite: IsFavourite,
      country: Country[0] && Country[0].value,
      label: Label,
      address: NewAddress,
      state: State,
      city: City,
      zipcode: ZipCode,
      contactNumber: ContactNumber,
      pharmacyId: selectedPharmacy.value,
      // patientId: dependentID
    };
    if (props.pharmacy_id) {
      Axios.post(
        API_ENDPOINT + "/patient/pharmacy/update/" + props.pharmacy_id,
        formToSubmit
      )
        .then((res) => {
          if (res.status === 200) {
            toastr.success("Pharmacy Updated", "Pharmacy updated successfully");
            props.toggleModal();
            props.TableRenderPharmacies();
          }
        })
        .catch((error) => {
          // console.log(error)
          toastr.error("error occurred", "Failed to update pharmacy");
        });
    } else {
      /* ########## */
      Axios.post(API_ENDPOINT + "/patient/pharmacy/new", formToSubmit)
        .then((res) => {
          if (res.status === 201) {
            toastr.success("Pharmacy Added", "Pharmacy added successfully");
            props.toggleModal();
            props.TableRenderPharmacies();
          }
        })
        .catch((error) => {
          toastr.error("error occurred", "Failed to create new pharmacy");
        });
    }
  };

  let SubmitButton = "";

  if (
    Label === "" ||
    CountryName === "" ||
    NewAddress === ""
  ) {
    SubmitButton = (
      <Button color="primary" disabled>
        Save
      </Button>
    );
  } else {
    SubmitButton = (
      <Button color="primary" onClick={() => CheckPharmacyList()}>
        Save
      </Button>
    );
  }

  const PharmacyHandler = (event) => {
    setSelectedPharmacy(event);
    
  }

  const CountryHandler = (event) => { // eslint-disable-line
    setCountryName(event.label);
    setCountry(event);
  };

  const FavouriteHandler = () => {
    if (IsFavourite === "0") {
      setIsFavourite("1");
    } else {
      setIsFavourite("0");
    }
  };

  const getSelectedCountry = (countryname) => {
    setCountry(
      Countries.filter((a) => a.country_name === countryname).map((b) => ({
        value: b.id,
        label: b.country_name,
      }))
    );
    setCountryName(
      Countries.filter((a) => a.country_name === countryname).map(
        (b) => b.country_name
      )[0]
    );
  };

  const PopulateFields = () => {
    // let url = `${API_ENDPOINT}/patient/pharmacy/view/` + props.pharmacy_id;
    // if(dependentID) url = `${API_ENDPOINT}/patient/pharmacy/view/` + props.pharmacy_id + `/` + dependentID;
    Axios.get(`${API_ENDPOINT}/patient/pharmacy/view/` + props.pharmacy_id)
      .then(function (response) {
        if(response.data.data.label) {
          let filteredPharmacy = pharmacyData.filter(pharmacy => pharmacy.name === response.data.data.label);
          if(filteredPharmacy.length > 0) {
            setSelectedPharmacy({ value: filteredPharmacy[0].id, label: filteredPharmacy[0].name })
          }
        }
        setIsFavourite(response.data.data.isFavourite);
        setLabel(response.data.data.label);
        setAddress(response.data.data.address);
        setNewAddress(response.data.data.address);
        setState(response.data.data.state);
        setCity(response.data.data.city);
        setZipCode(response.data.data.zipcode);
        setContactNumber(response.data.data.contactNumber);
        getSelectedCountry(response.data.data.country);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []); // eslint-disable-line

  useEffect(() => {
    if(Details.countryCode && Countries &&Countries.length)
    PopulateOwnCountry();
  }, [Details.countryCode,Countries]); // eslint-disable-line

  useEffect(() => {
    if (props.pharmacy_id && Countries) {
      PopulateFields();
    }
  }, [props.pharmacy_id, Countries]); // eslint-disable-line

  useEffect(() => {
    if(props.pharmacy_id && Label != '' && pharmacyData.length > 0) { // eslint-disable-line
      let filteredPharmacy = pharmacyData.filter(pharmacy => pharmacy.name === Label);
      if(filteredPharmacy.length > 0) {
        setSelectedPharmacy({ value: filteredPharmacy[0].id, label: filteredPharmacy[0].name })
      }
    }
  }, [props.pharmacy_id, pharmacyData, Label]);

  const formatOptionLabel = ({ value, label, address, city, state,zipcode }, { context }) => {
    if (context === "value") {
      return <div>{label}</div>;
    } else if (context === "menu") {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{label}</div>
        <div style={{ marginLeft: "10px", color: "grey" }}>
        { address ? `${address}` : "N/A" }{city ? `, ${city}` : "" }{state ? `, ${state}` : "" }{zipcode ? `, ${zipcode}` : "" }
        </div>
        </div>
      );
    }
  };

  const CheckPharmacyList = () => {
    let error = false;

    patientPharmacy.map((item) => {  // eslint-disable-line
      if (!error) {
        if (item.pharmacyId === selectedPharmacy.value && item.id !== props.pharmacy_id) {
          toastr.error("Pharmacy already exist","Please select a different one");
          error = true;
        }
      }
    })

    if (!error) submitPharmacyForm()
  }

  return (
    <div className="pharmacy_info">
      {/* <Row>
        <Col md="12">
          <div className="ModalHeader">
            <h3>
            {props.job === "edit" ? <h3>Edit Pharmacy</h3> : <h3>Add Pharmacy</h3>}
            </h3>
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col md="2">
          <label>Favourite</label>
          <br />
          <FontAwesomeIcon
            icon={faStar}
            color={IsFavourite === "1" ? "#46b7ee" : "#ccc"}
            onClick={FavouriteHandler}
            className="align-middle pharmacy_favourite"
          />
        </Col>
        <Col md="10">
          <label>
            Name <span className="mandatory-marker">*</span>
          </label>
          <FormGroup autoComplete="nope">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={pharmacyOptions}
              onChange={(event) => PharmacyHandler(event)}
              value={pharmacyOptions.filter(option => option.value === selectedPharmacy.value)}
              placeholder= "Select Pharmacy"
              name="pharmacyName"
              formatOptionLabel={formatOptionLabel}
              isSearchable
            />
          </FormGroup>
          <p
            className="float-right cursor-pointer advanced-search-icon"
            onClick={() => { setAdvancedSearchModal(true) }}
          >
            <FontAwesomeIcon icon={faSearch} /> {" "}
                Advanced Search
            </p>
        </Col>
        <Col md="6" style={{ display: "none" }}>
          <label>
            Country <span className="mandatory-marker">*</span>
          </label>{" "}
          {/* <Label>Country</Label> */}
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={CountryList}
              value={Country}
              isSearchable
              isDisabled="true"
              placeholder="Select "
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label>
              Address <span className="mandatory-marker">*</span>
            </label>{" "}
            <Input
              required
              value={Address}
              disabled="true"
              type="textarea"
            />
          </FormGroup>
        </Col>
        <Col md="6" style={{ display: "none" }}>
          <label>
            State
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.State(e.target.value,setState,24)}
              type="text"
              name="state"
              placeholder="State"
              disabled="true"
              value={State}
            />
          </FormGroup>
        </Col>
        <Col md="6" style={{ display: "none" }}>
          <label>
            City
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.City(e.target.value,setCity,24)}
              type="text"
              name="city"
              placeholder="City"
              disabled="true"
              value={City}
            />
          </FormGroup>
        </Col>
        <Col md="6" style={{ display: "none" }}>
          <label>
            Post Code 
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.ZipCode(e.target.value,setZipCode,10)}
              type="text"
              name="postcode"
              placeholder="Post Code"
              disabled="true"
              value={ZipCode}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <label>Contact Number</label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.ContactNumber(e.target.value,setContactNumber,12)}
              type="number"
              name="contactnumber"
              placeholder="Contact Number"
              disabled="true"
              value={ContactNumber}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="row" style={{'justify-content': 'center'}}>
        {SubmitButton}
        <Button color="outline-primary ml-2" onClick={props.toggleModal}>
          Cancel
        </Button>
      </Row>
      <div>
        <AdvancedPharmacySearchModal
          advancedSearchModal={advancedSearchModal}
          setAdvancedSearchModal={setAdvancedSearchModal}
          pharmacyHandler={PharmacyHandler}
          patientDetails={Details}
        />
      </div>
    </div>
  );
};

export default PharmacyInput;
