import React, { useState, useContext } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Modal, ModalBody, Col, Row } from "reactstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit,faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../../../src/context/ContextApi';
import VitalsInput from "./VitalsInput";
import { cookieService } from '../../../services/cookieService';

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const VitalsDeleteModal = (props) => {
  const { completeProfileChanged, setCompleteProfileChanged } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');
  const deleteVitals = (id) => {
    const deleteVitalsId = {
      vitalId: id,
      // dependentID: dependentID ? dependentID : 0
    };

    Axios.post(
      API_ENDPOINT + "/patient/remove-patient-vital",
      deleteVitalsId
    ).then((res) => {
      props.TableRenderVitals();
      toastr.success("Vitals Removed", "Vitals Removed Successfully");
      setCompleteProfileChanged(!completeProfileChanged);
    });
  };

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const initOpenModalsEdit = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const [openModalsEdit, setOpenModalsEdit] = useState(() => initOpenModalsEdit());

  const toggle = (index) => {
    // Toggle selected element 
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const toggleEdit = (index) => {
    // Toggle selected element 
    setOpenModalsEdit((openModalsEdit) =>
      Object.assign({}, openModalsEdit, { [index]: !openModalsEdit[index] })
    );
  };
//outline-primary
  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button className="edit-button-vital mr-2" onClick={() => toggleEdit(index)}>
            <FontAwesomeIcon
              icon={faEdit}
              fixedWidth
              className="align-middle"
            />
          </Button> 
          <Button
            className="delete-button-vital"
            outline
            onClick={() => toggle(index)}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              fixedWidth
              className="align-middle"
            />
          </Button>

          <Modal
            isOpen={openModalsEdit[index]}
            toggle={() => toggleEdit(index)}
            centered
          >
            <h3 className="ModalHeader mt-3">Edit Vitals</h3>
            <div
              className="vital-modal-close"
              onClick={() => toggleEdit(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>
            <ModalBody className="text-center m-3 VitalsModal">
              <VitalsInput
                toggleModal={() => toggleEdit(index)}
                TableRenderVitals={props.TableRenderVitals}
                selectedDataEdit={props.rowData}
              />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
            <ModalBody className="text-center m-3">
              <Row>
                <Col md="12">
                  <p className="text-center">
                    Are you sure want to delete this vitals?
                  </p>
                 
                  <Button
                    color="primary"
                    onClick={() => deleteVitals(props.id)}
                  >
                    Yes
                  </Button>{" "}
                  <Button
                    color="outline-primary mr-2"
                    onClick={() => toggle(index)}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default VitalsDeleteModal;
