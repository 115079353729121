import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { GlobalStateProvider } from "./context/ContextApi";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import ClearCache from "react-clear-cache"; 

class App extends React.Component {

  render() {
    var reloaded = false;
    const toggle = () => {
      reloaded = true;
    }

    return (
      <GlobalStateProvider>
        <Provider store={store}>
          <ClearCache>
            {({ isLatestVersion, emptyCacheStorage }) => (
              <>
                {
                  !isLatestVersion && !reloaded && emptyCacheStorage(), toggle() // eslint-disable-line
                }
              </>
            )}
          </ClearCache>
          <Routes />
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={false}
            closeOnToastrClick
          />
        </Provider>
      </GlobalStateProvider>
    );
  }
}

export default App;
