import React from 'react'
import { Row, Col, Card, CardHeader } from 'reactstrap'
import defaultAvatar from "../../../assets/avatar.png"
import moment from 'moment'
import MomentConfig from '../../../common/MomentConfig'

const ConsultTab = (props) => {
    // DEPENDENCY VARIABLE
    const { physicianDetails, reasonForConsultation, appointmentDetails, specialityText } = props

    const avatar = physicianDetails
        ? physicianDetails.profileImageURL
        : defaultAvatar

    const languageSpoken = physicianDetails
        ? physicianDetails.physicianData[0].spokenLanguagesText.map(
            (item, index) => index > 0 ? `, ${item}` : `${item}`
        )
        : ""

    return (
        <div>
            <Card className="consult-care-provider-info-card">
                <Row>
                    <Col xs="4">
                        <div className="care-provider-image">
                            <img
                                src={avatar}
                                className="img-fluid rounded-circle mb-2"
                                alt="Profile pic"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="consult-care-provider-info">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Care Provider</td>
                                        <td> : </td>
                                        <td> {physicianDetails ? physicianDetails.name : "N/A"} </td>
                                    </tr>
                                    <tr>
                                        <td>Credentials</td>
                                        <td> : </td>
                                        <td> {physicianDetails ? physicianDetails.physicianData[0].credentials : "N/A"} </td>
                                    </tr>
                                   {/*  <tr>
                                        <td>Speciality</td>
                                        <td> : </td>
                                        <td> {specialityText ? specialityText.specialityText : "N/A"} </td>
                                    </tr> */}
                                    <tr>
                                        <td>Language</td>
                                        <td> : </td>
                                        <td> {languageSpoken ? languageSpoken : "N/A"} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card className="consult-care-provider-info-card">
                <CardHeader tag="h4">Appointment Info {specialityText ? `(${specialityText.specialityText})` : "N/A"}</CardHeader>
                <Row>
                    <Col className="reason-for-consultation-container">
                        {appointmentDetails
                            ? `Booked Time: 
                                ${moment.utc(appointmentDetails.schStartTime).local().format(MomentConfig.MOMENT_FORMAT_DATE)}, 
                                ${moment.utc(appointmentDetails.schStartTime).local().format('hh:mm A')} 
                                to ${moment.utc(appointmentDetails.schEndTime).local().format('hh:mm A')}`
                            : "N/A"}
                            
                    </Col>
                </Row>
                <Row>
                    <Col className="reason-for-consultation-container">
                        <p>
                            <strong>Chief Complaint</strong>
                            <br />
                            {reasonForConsultation ? reasonForConsultation : "N/A"}
                            
                        </p>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default ConsultTab
