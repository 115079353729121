import React, { useEffect, useContext } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { Card, CardBody, Spinner, Badge } from "reactstrap";
import PharmacyDeleteModal from "./PharmacyDeleteModal";
import PharmaciesModal from "./PharmaciesModal";
import { GlobalState } from "../../../context/ContextApi"

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const PharmaciesTable = (props) => {
  // CONTEXT VARIABLES 
  const { setPatientPharmacy } = useContext(GlobalState);

  const FavouriteHandler = (cell, row) => {
    const formToSubmit = {
      isFavourite: cell === "0" ? "1" : "0",
      country: row.countryId,
      label: row.label,
      address: row.address,
      state: row.state,
      city: row.city,
      zipcode: row.zipcode,
      contactNumber: row.contactNumber,
      pharmacyId: row.pharmacyId
    };

    axios.post(
      API_ENDPOINT + "/patient/pharmacy/update/" + row.id,
      formToSubmit
    )
      .then((res) => {
        if (res.status === 200) {
          toastr.success(
            "Favourite Updated",
            "Favourite pharmacy updated successfully"
          );
          props.TableRenderPharmacies();
        }
      })
      .catch((error) => {
        toastr.error("Error Occurred", "Failed to update favourite pharmacy");
      });
  };

  const FavouriteFormatter = (cell, row) => {

    return (
      <FontAwesomeIcon
        icon={faStar}
        color={cell === "1" ? "#46b7ee" : "#ccc"}
        onClick={() => FavouriteHandler(cell, row)}
        className="align-middle pharmacy_favourite"
      />
    );
  };

  const StatusFormatter = (cell) => {
    let color = cell === "1" ? "success" : "danger"
    let label = cell === "1" ? "Active" : "Inactive"

    return (
      <p><Badge className="pharmacy-badge" color={color}>{label}</Badge></p>
    )
  }

  const ActionFormatter = (cell, row) => {
    return (
      <>
       <PharmaciesModal
          job="edit"
          pharmacy_id={row.id}
          rowData={row}
          TableRenderPharmacies={props.TableRenderPharmacies}
        />
        {row.isFavourite !== "1" ? (
          <PharmacyDeleteModal
            id={row.id}
            TableRenderPharmacies={props.TableRenderPharmacies}
            isFavourite={row.isFavourite}
          />
        ) : null}

       
      </>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "--"
  }

  const tableColumns = [
    {
      dataField: "isFavourite",
      text: "Favourite",
      formatter: FavouriteFormatter,
    },
    {
      dataField: "label",
      text: "Name",
      formatter: basicValidation,
    },
    {
      dataField: "country",
      text: "Country",
      formatter: basicValidation,
    },
    {
      dataField: "state",
      text: "State/ Province/ County",
      formatter: basicValidation,
    },
    {
      dataField: "address",
      text: "Address",
      formatter: basicValidation,
    },
    {
      dataField: "city",
      text: "City/Town",
      formatter: basicValidation,
    },
    {
      dataField: "zipcode",
      text: "Post Code",
      formatter: basicValidation,
    },
    {
      dataField: "contactNumber",
      text: "Contact Number",
      formatter: basicValidation,
    },
    {
      dataField: "m_status",
      text: "Status",
      formatter: StatusFormatter,
    },
    {
      text: "Actions",
      formatter: ActionFormatter,
    },
  ];

  let loader = null;
  if (props.PharmaciesLoading) {
    loader = <Spinner />;
  }

  useEffect(() => {
    props.TableRenderPharmacies();
  }, []); // eslint-disable-line

  useEffect(() => {
    if(props.data) setPatientPharmacy(props.data)
  }, [props.data]); // eslint-disable-line


  return (
    <div className="PharmaciesTable">
      {loader}
      <Card>
        <CardBody className="health-card">
          {props.PharmaciesNoDataFound ? (
            <p>No Data Found</p>
          ) : (
            <BootstrapTable
              keyField="id"
              data={props.data}
              columns={tableColumns}
              bordered={false}
              bootstrap4
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PharmaciesTable;
