import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const cookieService = {
    get,
    set,
    remove
};

function get(key) {
    return cookies.get(key);
}

function set(key, value, options){
    cookies.set(key, value, options)
}

function remove(key, options){
    return cookies.remove(key, options);
}