import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";

import SubNav from "../SubNav";

import styles from './Language.module.css';

const options = [
  { value: "ENG", label: "English" },
  { value: "ESP", label: "Español" }
];

const LanguageInfo = () => {
  const { t } = useTranslation();
  return(
  <Card>
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        {t('language')}
      </CardTitle>
    </CardHeader>
    <CardBody className={styles.cardBody}>
      <Form>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label>{t('siteLanguage')}</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={options}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button color="primary">{t('save')}</Button>
      </Form>
    </CardBody>
  </Card>
);
}

const Language = () => {
  const { t } = useTranslation();
  return (
  <PageLayout>
    <Header>
      <HeaderTitle>{t('myAccount')}</HeaderTitle>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/settings">{t('myAccount')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('language')}</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Row>
      <Col md="3" xl="2">
        <SubNav />
      </Col>
      <Col md="9" xl="10">
        <LanguageInfo />
      </Col>
    </Row>
  </PageLayout>
);
}

export default Language;
