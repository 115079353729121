import React, { useState, useEffect } from "react";
import { Row, Col, Label, FormGroup, Input, Button } from "reactstrap";
import Select from "react-select";
import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { useDropzone } from "react-dropzone";
import * as Validation from "./../../../../common/FieldValidation"
import "../Identification/Identification.css"
import { cookieService } from '../../../../services/cookieService';

const AddDocument = (props) => {
  const [Title, setTitle] = useState("");
  const [Categories, setCategories] = useState([]);
  const [Category, setCategory] = useState("");
  const [CategoryValue, setCategoryValue] = useState("");
  const [Files, setFiles] = useState([]);
  const [FileSize, setFileSize] = useState("");
  const [FileList, setFileList] = useState([]);
  const [SpinnerEnable, setSpinnerEnable] = useState(false);
  const [FileExtension, setFileExtension] = useState("");
  const CategoryList = Categories.map((key) => {
    return { value: key.id, label: key.name };
  });

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const fileSize = Number(process.env.REACT_APP_PROFILE_PICTURE_FILE_SIZE);
  const fileLimit = Number(process.env.REACT_APP_BOOK_APPOINTMENT_FILE_LIMIT)
  // let dependentID = cookieService.get('dependentID');

  const OverlayLoader = () => (
    <div class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content">
          <span class="spinner"></span>
        </div>
      </div>
    </div>
  );

  const CategoryHandler = (value) => {
    setCategory(value);
    Categories.filter((a) => a.id == value.value).map((b) => { // eslint-disable-line
      setCategoryValue(b.id);
    });
  };

  const fetchCategories = () => {
    Axios.get(API_ENDPOINT + "/patient/document/type/identification")
      .then((response) => {
        if (response.status === 200) {
          setCategories(response.data.data);
        }
      })
      .catch((error) => {});
  };

  function Uploader(props) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ // eslint-disable-line
      onDrop: (acceptedFiles) => {
        setFileExtension(
          acceptedFiles.map((file) => file.name.split('.').pop()).toString()
        );
        setFileSize(acceptedFiles.map((file) => file.size));
        setFiles(acceptedFiles.map((file) => file));
        setFileList(
          acceptedFiles.map((file) => (
            <li key={file.path}>
              {file.path} - {(file.size / 1024).toFixed(0)} KB
            </li>
          ))
        );
      },
      multiple: false,
    });

    return (
      <section className="container uploader">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p className="text-center darker">
            Drag 'n' drop some file here, or click to select a file <br></br>
            (Jpeg, jpg, png, tiff, doc, docx, pdf)
          </p>
        </div>
        <aside>
          <ul className="pl-3">{FileList}</ul>
        </aside>
      </section>
    );
  }

  useEffect(() => {
    fetchCategories();
  }, []); // eslint-disable-line

  const SubmitForm = () => {
    if (Files.length === 0) {
      toastr.error("Empty Document", "Document should not be empty");
    } else if (Title === "") {
      toastr.error("Empty Title", "Title should not be empty");
    } else if (CategoryValue === "") {
      toastr.error("Select a Category", "Category should not be empty");
    } else if (FileSize > (fileSize * 1048576)) {
      toastr.error("Memory Exceeded", `Please upload file less than ${fileSize} MB`);
    } else if (
      FileExtension !== "jpg" &&
      FileExtension !== "JPG" &&
      FileExtension !== "jpeg" &&
      FileExtension !== "JPEG" &&
      FileExtension !== "png" &&
      FileExtension !== "PNG" &&
      FileExtension !== "tiff" &&
      FileExtension !== "TIFF" &&
      FileExtension !== "doc" &&
      FileExtension !== "DOC" &&
      FileExtension !== "docx" &&
      FileExtension !== "DOCX" &&
      FileExtension !== "pdf" &&
      FileExtension !== "PDF"
    ) {
      toastr.warning(
        "Unsupported File",
        "File is not supported for the upload  "
      );
    } else {
      setSpinnerEnable(true);
      const bodyFormData = new FormData();
      bodyFormData.append("file", Files[0], Files[0].name);
      bodyFormData.set("type", CategoryValue);
      bodyFormData.set("title", Title);
      // if(dependentID)
      // bodyFormData.set("dependentID" , dependentID);

      Axios.post("/patient/document/upload", bodyFormData)
        .then((response) => {
          if (response.status === 200) {
            toastr.success(
              "Document Uploaded",
              "Document uploaded successfully"
            );
            setSpinnerEnable(false);
            props.RenderDocuments();
            props.setNoDataFound(false);
            props.ToggleModal();
          } else {
            setSpinnerEnable(false);
            toastr.warning("Document Not Uploaded", "please try again");
          }
        })
        .catch(function (error) {
          setSpinnerEnable(false);
          toastr.error("Limit Exceeds", `please upload file less than ${fileLimit}MB`);
        });
    }
  };

  return (
    <>
      <Row>
        <Col md="6">
          <Label>
            Category <span className="mandatory-marker">*</span>
          </Label>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={CategoryList}
              onChange={(event) => CategoryHandler(event)}
              value={Category}
              isSearchable
              placeholder="Select category "
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label>
              Title <span className="mandatory-marker">*</span>
            </Label>
            <Input
              required
              onChange={(e) =>  Validation.IdentificationTitle(e.target.value,setTitle,255)}
              type="text"
              name="title"
              value={Title}
              placeholder="Title"
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <Uploader />
        </Col>
        <Col md="12" className="text-center mt-4">
          <Button color="primary" size="md" onClick={() => SubmitForm()}>
            Save
          </Button>{" "}
          <Button
            size="md"
            className="outline-primary mr-2"
            onClick={() => props.ToggleModal()}
          >
            Cancel
          </Button>
        </Col>
      </Row>
      {SpinnerEnable ? <OverlayLoader /> : null}
    </>
  );
};

export default AddDocument;
