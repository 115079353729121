import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import AppointmentDetails from "./AppointmentDetails";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const DetailsModal = (props) => {

  const { isRedirected } = props

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  
  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
    if (index === 0) window.name = "";
  };
  
  useEffect(() => { if (isRedirected) toggle(0) }, [isRedirected]);

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button
            color="primary"
            title="Appointment Details"
            onClick={() => toggle(index)}
          >
            Appointment Details
          </Button>
          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
            className="appointment_details_modal"
          >

            <h3 className="ModalHeader text-center mt-3" >Appointment Details</h3>
            <div className="modal-close">
              <FontAwesomeIcon icon={faTimesCircle} onClick={() => toggle(index)} />
            </div>
            <div className="modal-border mb-3"></div>

            <ModalBody>
              <AppointmentDetails id={props.id} consultationDetails={props.consultationDetails} />
            </ModalBody>
          </Modal>
          
        </React.Fragment>
      ))}
    </>
  );
};

export default DetailsModal;
