import React from "react";
import { Link } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  CustomInput
} from "reactstrap";
import { useTranslation } from "react-i18next";

import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";

import SubNav from "../SubNav";

const LanguageInfo = () => {
  const { t } = useTranslation();
  return (
  <Card>
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        {t('notification')}
      </CardTitle>
    </CardHeader>
    <CardBody className="card-body">
      <Form>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label for="exampleCheckbox">{t('smsNotificationsOnMobile')}</Label>
              <div>
                <CustomInput
                  type="radio"
                  id="radio1"
                  name="customRadio"
                  label={t('yesLabel')}
                  className="mb-2"
                  defaultChecked
                  inline
                />
                <CustomInput
                  type="radio"
                  id="radio2"
                  name="customRadio"
                  label={t('noLabel')}
                  className="mb-2"
                  inline
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Button color="primary">{t('save')}</Button>
      </Form>
    </CardBody>
  </Card>
);
}

const Notification = () => {
  const { t } = useTranslation();
  return (
  <PageLayout>
    <Header>
      <HeaderTitle>{t('myAccount')}</HeaderTitle>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/settings">{t('myAccount')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('notification')}</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Row>
      <Col md="3" xl="2">
        <SubNav />
      </Col>
      <Col md="9" xl="10">
        <LanguageInfo />
      </Col>
    </Row>
  </PageLayout>
);
}
export default Notification;
