import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from '../../axios.js';
import { cookieService } from '../../services/cookieService';
import './Verification.css';
import logo from "../../assets/equipo-logo.svg";

const Verification = (props) => {
  const [hasNoError, setNoErrors] = useState(null);
  const token = props.match.params.verifyToken;

  useEffect(() => {
    axios.post('/user/activate-profile', { token })
      .then(function (response) {
        if (response.status === 202) {
          localStorage.removeItem("userDetail");
          cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
          cookieService.remove('refresh_token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
          setNoErrors(true);
        } else {
          setNoErrors(false);
        }
      })
      .catch(function (error) {
        setNoErrors(false);
      })
  }, [token]);

  return (
    <div className="email-verification-wrapper">
      <div className="container verification-container">
        <div className="card">
          <header className="verification-header">
            <div className="text-center verify-img-wrapper">
              <img
                src={logo}
                alt="Doc APP logo"
                className="img-fluid"
              />
            </div>
            <h1 className="text-center verification-title">Verified</h1>
          </header>
          <main className="verification-content">
          {hasNoError === true ? (
              <>
                <p>
                  Your email has been successfully verified. Please login by clicking  {'\u00A0'}
                  <Link to="/" >
                    here
                      </Link>
                </p>
                <p>
                  If you have any questions, please send an email to <a href="mailto:info@equipohealth.com">info@equipohealth.com</a>.
                </p>
                <p>Cheers,<br />Equipo Health Team</p>
              </>
            ) : hasNoError === false ? (
              <>
                <p>
                  Sorry, the verification link seems to be invalid or expired. Please resend a confirmation mail by logging into Equipo Health <Link to="/" >
                    click here
                  </Link>
                </p>
                <p>
                  If you have any questions, just reply to this email—we're always happy to help out.
                </p>
                <p>Cheers,<br />Equipo Health Team</p>
              </>
            ) : (
              <>
                <p>
                  Please wait we are verifying your account
                </p>
                <p>Cheers,<br />Equipo Health Team</p>
              </>
            )
          }
          </main>
        </div>
      </div>
    </div>
  );
}

export default Verification;
