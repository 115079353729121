/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Collapse,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import { useTranslation } from "react-i18next";
import DependentsTable from "./Dependents/DependentsTable";
import DependentsModal from "./Dependents/DependentsModal";
import Axios from "axios";
import { GlobalState } from '../../context/ContextApi';
import "./Dependents.css";

const DependentsList = () => {
  const [isOpenPharmacies, setIsOpenPharmacies] = useState(true);
  const togglePharmacies = () => setIsOpenPharmacies(!isOpenPharmacies);

  const [isOpenPhysicians, setIsOpenPhysicians] = useState(true);
  const togglePhysicians = () => setIsOpenPhysicians(!isOpenPhysicians);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


  const [PhysiciansList, setPhysiciansList] = useState([]);
  const [PhysiciansNoDataFound, setPhysiciansNoDataFound] = useState(false);
  const [PhysiciansLoading, setPhysiciansLoading] = useState(true);


  const fetchDependents = () => {
    Axios.get(API_ENDPOINT + "/patient/dependent/list")
      .then((res) => {
        setPhysiciansList(res.data.data);
        if (res.data.data.length === 0) {
          setPhysiciansNoDataFound(true);
        } else {
          setPhysiciansNoDataFound(false);
        }
        setPhysiciansLoading(false);
      })
      .catch((error) => {
        setPhysiciansNoDataFound(true);
        setPhysiciansLoading(false);
      });
  };

  useEffect(() => {
    fetchDependents();
  }, []); // eslint-disable-line

  const { t } = useTranslation();

  return (
    <div className="CareInfo">
      <Card>
        <CardBody className="card-body">
        <div className="care-info-collapse">
            <div
              color="primary"
              role="button"
              className="care-info-header-container"
            >
              <FontAwesomeIcon
                onClick={togglePharmacies}
                icon={isOpenPhysicians ? faAngleDown : faAngleRight}
                className="align-middle"
              />
              <h2 className="care-info-header" onClick={togglePhysicians}>
                {t("Dependents")}
              </h2>
              <DependentsModal  TableRenderPhysicians={fetchDependents} />
            </div>
            <Collapse isOpen={isOpenPhysicians}>
              {
                <DependentsTable
                  data={PhysiciansList}
                  PhysiciansNoDataFound={PhysiciansNoDataFound}
                  PhysiciansLoading={PhysiciansLoading}
                  TableRenderPhysicians={fetchDependents}
                  
                />
              }
            </Collapse>
            <Collapse isOpen={isOpenPhysicians}>
              
            </Collapse>
          </div>
          
          
        </CardBody>
      </Card>
    </div>
  );
};

const Dependents = () => {

  return (
    <PageLayout>
      <Header>
        <HeaderTitle>My Dependents</HeaderTitle>
      </Header>

      <Row>
        <Col md="12">
          <DependentsList />
        </Col>
      </Row>
    </PageLayout>
  );
};
export default Dependents;
