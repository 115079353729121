import React, { useState, useEffect, useContext } from "react";
import { Row, Spinner, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import "./UpcomingConsultations.css"
import { useTranslation } from "react-i18next";
import ConsultationCard from "./../../Consultation/MyAppointments/ConsultationCard";
import Axios from "axios";
import { GlobalState } from '../../../context/ContextApi'
import { cookieService } from '../../../services/cookieService';

const UpcomingConsultation = () => {
  const { consultComplete } = useContext(GlobalState);
  const location = useLocation();
  const { t } = useTranslation();
  const [ConsultationList, setConsultationList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [NodataFound, setNodataFound] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false); // eslint-disable-line
  const [responseFields, setResponseFields] = useState({ // eslint-disable-line
    message: "Error occured",
    status: false,
  });

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const fetchAppointmentList = () => {
    // let dependentID = cookieService.get('dependentID');
    // let data = {}
    // if(dependentID){
    //   data = {'dependentID': dependentID}
    // }
    Axios.post(
      API_ENDPOINT+"/patient/appointment/upcoming"
    )
      .then((res) => {
        if (res.status === 200) {
          setConsultationList(res.data.data);
          // console.log(res.data.data)
          setLoading(false)
          if (res.data.data.length ===0 || res.data.status === 404) {
            setNodataFound(true);
          } else {
            setNodataFound(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false)
        setNodataFound(true);
      });
  };

  const card = ConsultationList.map((consultation) => {
    return <ConsultationCard
    paymentNote={consultation.paymentNote} 
    key={consultation.id} 
    id={consultation.id}
    physicianId={consultation.physicianId ? consultation.physicianId : null}
    physician_title={consultation.physician_title}
    physician_name={consultation.physician_name} 
    consultation_time={consultation.schStartTime} 
    status={consultation.app_status} 
    chief_complaint={consultation.chiefComplaint}
    schStartTime={consultation.schStartTime}
    schEndTime={consultation.schEndTime}
    profile_image={consultation.profileImageURL}
    specialityId = {consultation.specialityId}
    specialityText = {consultation.specialityText}
    />;
  });
  
  useEffect(() => {
    const data = location.state && location.state.detail;
    if (data === "checkoutSuccess") {
      responseFields.message = t("checkoutSuccessMessage");
      responseFields.status = true;
      setErrorStatus(true);
      window.scrollTo(0, 0);
    }
  }, []);// eslint-disable-line

  useEffect(() => {
    fetchAppointmentList();
  }, []); // eslint-disable-line


  useEffect(() => {
    if (consultComplete) fetchAppointmentList();
  }, [consultComplete]) // eslint-disable-line

  let loader = null;
  if (Loading) {
    loader = <Spinner />;
  }

  const signOut = () => {
    cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('refresh_token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('dependentID', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('newProfilePic', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    localStorage.removeItem("userDetail");
    window.location = "/"
  }

  return (
    <>
      <Row>{loader} {!NodataFound ? card : <Col md="12"><div className="h3 bg-light empty-content">No Upcoming Consultation </div></Col>}</Row>
      {/* <div onClick={signOut}>Signout</div> */}
    </>
  );
};

export default UpcomingConsultation;
