import React, { useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import "./Payment.css";

export default function Payment({setPaymentDetails, setError, setDisabled}) {
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    setPaymentDetails({stripe: stripe, elements: elements, CardElement: CardElement});
  }, [stripe, elements, CardElement]); // eslint-disable-line

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  return (
    <CardElement id="card-element" hidePostalCode={true} options={cardStyle} onChange={handleChange} />
  );
}
