import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { cookieService } from './services/cookieService';


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

axios.defaults.baseURL = API_ENDPOINT;
axios.defaults.timeout = 60000;

axios.interceptors.request.use(request => {
  let token = cookieService.get('token')
    request.headers['Authorization'] =  'Bearer '+ token;
    request.headers['Content-Type'] =  'application/json';
 return request;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  return response;
 }, error => {
  if(error.response && error.response.status === 401) {
    cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('refresh_token', {  path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT",  Domain: window.location.hostname });
    localStorage.removeItem("userDetail");
    window.location = "/";
  }
  return Promise.reject(error);
});

 
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
