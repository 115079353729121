import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, CardTitle, Col, Label, Row, Input, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import SubNav from "../SubNav";
import Axios from "axios";
import { toastr } from 'react-redux-toastr';
import DatePicker from "react-datepicker";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { cookieService } from "../../../services/cookieService";

const InsuranceDetailsComponent = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const [spinnerEnable, setSpinnerEnable] = useState(false); //eslint-disable-line
  const [holderName, setHolderName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [rowID, setRowID] = useState(""); //eslint-disable-line
  let dependentID = cookieService.get('dependentID');

  const fetchPatientInsuranceDetails = () => {
    let url = API_ENDPOINT + "/patient/get-insurance-details";
    if(dependentID != 0){
      url = API_ENDPOINT + "/patient/get-insurance-details/" + dependentID;
    }

    Axios.get(url)
      .then((res) => {
        // console.log('st code',res.data.data.code)
        if (res.status === 200) {
          setSpinnerEnable(true);
          setRowID(res.data.data.data[0].id)
          if(res.data.data.data[0].insuranceID !== 0 && res.data.data.data[0].insuranceID !== undefined && res.data.data.data[0].insuranceID !== null){setSelectedInsurance([{ id: res.data.data.data[0].insuranceID, name: res.data.data.data[0].name }]);}
          setHolderName(res.data.data.data[0].holderName);
          setPolicyNumber(res.data.data.data[0].policyNumber);
          var x = res.data.data.data[0].startDate;
          // console.log('x', x)
          // const startDateObj = new Date(x);
          // const formattedStartDate = startDateObj.toISOString().split('T')[0];
          setStartDate(x);
          var y = res.data.data.data[0].expiryDate
          // const expiryDateObj = new Date(y);
          // const formattedExpiryDate = expiryDateObj.toISOString().split('T')[0];
          setExpiryDate(y);
          // console.log('insurance',res.data.data.data[0].name);
          setSpinnerEnable(false);
          // console.log('st code',res.status.data.code)
        } else {
          // setNoDataFound(true);
          toastr.warning("Insurance details not found!")
          setSpinnerEnable(false);
          // console.log('no data found');
        }
      })
      .catch((error) => {
        // setNoDataFound(true);
        // console.log(error)
        toastr.warning("No insurance details found!")
        // toastr.error("Failed", "Something went wrong !")
        setSpinnerEnable(false);
      });
  };
  // const confirmation = () => {
  //   if (!startDate) {
  //     toastr.error("Start Date Is Required", "Please pick a date!");
  //   }

  //   if (!expiryDate
  //   ) {
  //     toastr.error("Expiry Date Is Required", "Please pick a date!");
  //   }

  //   if (startDate && expiryDate && rowID) {
  //     updateInsuranceDetails();
  //   } else if (startDate && expiryDate) {
  //     saveInsuranceDetails();
  //   }
  // };
  const confirmation = () => {
    if (rowID) {
      updateInsuranceDetails();
    } else {
      saveInsuranceDetails();
    }
  };

  const toggle = () => {
    setHolderName("");
    setPolicyNumber("");
    setSelectedInsurance([]);
    setStartDate("");
    setExpiryDate("");
  }
  const loadOptionsForInsurance = async (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = {
        insuranceName: inputValue
      };
      return Axios.post(`${API_ENDPOINT}/common/insurance-search`, searchParams)
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    } else {
      return []; // Return an empty array when input length is less than 4
    }
  };
  

  const saveInsuranceDetails = () => {
    setSpinnerEnable(true);
    const params =
    {
      "insuranceID": selectedInsurance ? selectedInsurance.id : '',
      "holderName": holderName,
      "policyNumber": policyNumber,
      "startDate": startDate,
      "expiryDate": expiryDate,
      "dependentID": (dependentID != 0) ? dependentID : ''
    }
    Axios.post(`${API_ENDPOINT}/patient/insurance/new`, params, {

    })
      .then(response => {
        // console.log('RespONse',response)
        toastr.success("Success", "Insurance details added");
        setSpinnerEnable(false);
      })
      .catch(error => {
        toastr.error("Failed", "Something went wrong !");
        setSpinnerEnable(false);
      })
  }
  const updateInsuranceDetails = () => {
    setSpinnerEnable(true);
    const params =
    {
      // "id": rowID,
      "insuranceID": selectedInsurance ? selectedInsurance.id : '',
      "holderName": holderName,
      "policyNumber": policyNumber,
      "startDate": startDate,
      "expiryDate": expiryDate,
      "dependentID": (dependentID != 0) ? dependentID : ''
    }
    Axios.post(`${API_ENDPOINT}/patient/insurance/update/${rowID}`, params, {

    })
      .then(response => {
        // console.log('RespONse', response)
        toastr.success("Success", "Insurance details updated");
        setSpinnerEnable(false);
      })
      .catch(error => {
        toastr.error("Failed", "Something went wrong !");
        setSpinnerEnable(false);
      })
  }

  useEffect(() => {
    fetchPatientInsuranceDetails();
  }, []); // eslint-disable-line

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Insurance Details
          </CardTitle>
        </CardHeader>
        <CardBody >
          <Row>
            <Col md="4" className="mb-2">
            <Tooltip
                  title={selectedInsurance ? selectedInsurance.name : ""}
                  placement="top"
                  arrow
                >
                  <div>
              <Label for="insurance_name">Insurance Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                placeholder="Type insurance name for options"
                loadOptions={loadOptionsForInsurance}
                value={selectedInsurance}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                onChange={(e) => {
                  console.log('sddkjdk',selectedInsurance)
                  setSelectedInsurance(e)
                }}
              />
              </div>
              </Tooltip>
              </Col>
            <Col md="4" className="mb-2">
              <Label for="holder_name">Holder Name</Label>
              <Input
                id="holder_name"
                value={holderName}
                placeholder="Enter the Insurance Holder Name"
                onChange={(e) => {
                  setHolderName(e.target.value);
                }
                }
              /></Col>
            <Col>
              <Label for="policy_number">Policy Number</Label>
              <Input
                id="policy_number"
                type="text"
                value={policyNumber}
                placeholder="Enter Policy Number"
                onChange={(e) => {
                  setPolicyNumber(e.target.value);
                }
                }
              /></Col><br></br></Row><Row>
            <Col md="6" className="mb-2">
              <Label for="startDate">Start Date</Label>
              <div className="sign-up-dob-input">
                {/* <Input
                  id="startDate"
                  type="date"
                  value={startDate}
                  placeholder="Enter the Start Date "
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    // console.log(new Date().toISOString())
                  }}
                /> */}
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  showYearDropdown
                  maxDate={new Date()}
                  showMonthDropdown
                  selected={
                    startDate ? moment(startDate).toDate() : ""
                  }
                  // minDate={moment(minDate).toDate()}
                  onSelect={(date) => {
                    setStartDate(date);
                  }}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
              </div></Col>
            <Col md="6" className="mb-2">
              <Label for="expiryDate">Expiry Date</Label>
              <div className="sign-up-dob-input">
                {/* <Input
                  id="expiryDate"
                  type="date"
                  value={expiryDate}
                  placeholder="Enter the Expiration Date "
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setExpiryDate(e.target.value);
                    // console.log(new Date().toISOString())
                  }}
                /> */}
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  showYearDropdown
                  minDate={new Date()}
                  showMonthDropdown
                  selected={
                    expiryDate ? moment(expiryDate).toDate() : ""
                  }
                  // minDate={moment(minDate).toDate()}
                  onSelect={(date) => {
                    setExpiryDate(date);
                  }}
                  onChange={(date) => {
                    setExpiryDate(date);
                  }}
                />
              </div>
              
            </Col>
            
          </Row>
         <Row>
          <Col>
         {spinnerEnable && (
                <div style={{ display:"flex", justifyContent: 'center' }}>
                  <Spinner 
                    color="dark"
                    type="grow"
                    className="mr-2"
                    size="sm"
                  />
                  <Spinner
                    color="dark"
                    type="grow"
                    className="mr-2"
                    size="sm"
                  />
                  <Spinner
                    color="dark"
                    type="grow"
                    className="mr-2"
                    size="sm"
                  />
                </div>
              )}
              </Col>
         </Row>
          <Row>
            <br></br>
            <Col className="save-btn-wrapper">
              <br></br>
              <Button
                className="profile-save-btn mt-3"
                color="primary"
                onClick={() => {
                  confirmation()
                }}

              >Save</Button>{' '}
              <Button
                className="profile-save-btn mt-3"
                onClick={toggle}
              >Cancel</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      
    </>
  );
};


const Insurance = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Header>
        <HeaderTitle>{t("myAccount")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings/insurance">{t("myAccount")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("Insurance Details")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="3" xl="2">
          <SubNav />
        </Col>
        <Col md="9" xl="10">
          <InsuranceDetailsComponent />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default Insurance;
