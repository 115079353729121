import React, { useState, useEffect, useContext } from "react";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { GlobalState } from '../../../../src/context/ContextApi';
import '../HealthProfile.css';
import { cookieService } from '../../../services/cookieService';

const ConditionsInput = (props) => {
  const [ConditionsTypes, setConditionsTypes] = useState([]);
  const [ConditionTouched, setConditionTouched] = useState(false);
  const [SelectedCondition, setSelectedCondition] = useState([]); // eslint-disable-line
  const [ConditionToPost, setConditionToPost] = useState([]);
  const { completeProfileChanged, setCompleteProfileChanged } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');
  let data = {}
  // if(dependentID){
  //   data = {'dependentID': dependentID}
  // }

  const getDefaultConditions = (array) => {
    const setConMap = (key) => {
      let newItem = {
        value: key.id,
        label: key.category,
      };

      SelectedCondition.push(newItem);
    };

    if (array) {
      for (let id in array) {
        const abc = array[id].toString();
        ConditionsTypes.filter((key) => key.id === abc).map((key) =>
          setConMap(key)
        );
      }
    }
  };

  const fetchConditions = () => {
    Axios.post(API_ENDPOINT + "/patient/get-patient-conditions", data)
      .then((res) => {
        getDefaultConditions(res.data.data.conditions);
          if(res.data.title != "No data found"){ // eslint-disable-line
            setCompleteProfileChanged(!completeProfileChanged)
          }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchConditionsTypes = () => {
    Axios.get(API_ENDPOINT + "/common/get-condition-list")
      .then((res) => {
        setConditionsTypes(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const ConditionsList = ConditionsTypes.map((key) => {
    return { value: key.id, label: key.category };
  });

  const ConditionHandler = (event) => {
    setConditionTouched(true);

    if (event) {
      const aaa = event.map((key) => key.value).join(",");
      setConditionToPost("[" + aaa + "]");
    } else {
      setConditionToPost("");
    }
  };

  const ConditionsSubmit = () => {
    let formToSubmit = "";
   
    if (ConditionToPost && ConditionTouched) {
      // if(dependentID){
      //   formToSubmit = {
      //     conditions: ConditionToPost,
      //     dependentID: dependentID
      //   };
      // }else{
    //     formToSubmit = {
    //       conditions: ConditionToPost,
    //     };
    //   }
    // } else {
      formToSubmit = {
        conditions: ConditionToPost,
      };
    }
    // console.log(SelectedCondition.length)
    // console.log(ConditionTouched)
    // console.log(ConditionToPost.length)
    

    if (
      (SelectedCondition.length===0 &&  !ConditionTouched && ConditionToPost.length === 0 ) ||
      (SelectedCondition.length===0 &&  ConditionTouched && ConditionToPost.length === 0 ) ||
      (SelectedCondition.length===0 &&  ConditionTouched && ConditionToPost.length === 2 )
    ) {
      toastr.error(
        "Empty Conditions",
        "You should enter atleast one condition"
      );
    } else {
      Axios.post(API_ENDPOINT + "/patient/set-patient-condition", formToSubmit)
        .then((res) => {
          if (res.status === 200) {
            toastr.success("Conditions Saved", "Conditions Saved Successfully");
          }
        })
        .catch((error) => {
          toastr.error("error occurred", "Failed to save Conditions Saved");
        });
    }
  };

  useEffect(() => {
    fetchConditionsTypes();
  }, []);// eslint-disable-line

  useEffect(() => {
    fetchConditions();
  }, [ConditionsTypes]); // eslint-disable-line

  return (
    <Row>
      <Col md="12" className="conditions-drop-down">
        <FormGroup>
          <Select
            onChange={(event) => ConditionHandler(event)}
            closeMenuOnSelect={false}
            isMulti
            placeholder="Select your conditions"
            defaultValue={SelectedCondition}
            options={ConditionsList}
          />
          <Label>
            To add more than one condition simply start typing the condition, select matching condition, press enter and then save
          </Label>
        </FormGroup>
      </Col>
      <Col >
        <Button
          color="primary"
          className="health-profile-btn save-condition-btn"
          onClick={ConditionsSubmit}
        >
          Save Conditions
        </Button>
      </Col>
    </Row>
  );
};

export default ConditionsInput;
