import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { cookieService } from '../../services/cookieService'
import { useDropInput } from '../../hooks/drop-down-hook';
import axios from '../../../src/axios.js'
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../src/axios.js';
import { Button, Card, CardBody, FormGroup, Label, Col, Row, } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import '../../components/MobileVerification/OTP.css';
import { useTranslation } from "react-i18next";
import { useInput } from "../../hooks/input-hook";
import logo from "../../assets/logo.svg";
import Select from "react-select";
import { GlobalState } from '../../context/ContextApi';


const MobileNumberVerification = () => {
    const { t } = useTranslation();
    let authToken = cookieService.get("token");
    const { Details, setDetails } = useContext(GlobalState);
    let history = useHistory();
    const { value: phoneNumber, bind: bindPhone, setValue: setPhone } = useInput("");
    const [countryMap, setCountryList] = useState([]);
    let message = "Equipo Health mobile";

    let countryList = countryMap.map(function (list) {
        return { key: list.country_name, value: list.country_name, label: list.country_name };
    });

    let countryCodeList = countryMap.map(function (list) {
        return { key: list.country_isd_code, value: list.country_isd_code, label: list.country_isd_code };
    });

    const {
        value: countryCode,
        bind: bindCountryCode, // eslint-disable-line
        setValue: setCountryCode,
    } = useDropInput({ value: "", label: "" });

    const [country, setCountry] = useState({
        value: "",
        label: "Select Country",
    });

    const getCountryList = () => {
        axiosInstance
            .get("/common/country-list")
            .then(function (response) {
                if (response.status === 200) {
                    setCountryList(response.data.data);
                    let countryList = response.data.data;
                    countryList
                        .filter((key) => key.country_isd_code == countryCode) // eslint-disable-line
                        .map((filterCountry) => setCountryMap(filterCountry));
                }
            })
            .catch(function (error) { });
    }

    function setCountryMap(country) {
        
        setCountry({
            value: country.country_name,
            label: country.country_name,
        })
        setCountryCode({
            value: country.country_isd_code,
            label: country.country_isd_code,
        });
    }

    const onChangeList = (event) => {
        if (countryMap.length > 0) {
            countryMap
                .filter((key) => key.country_name == event.value) // eslint-disable-line
                .map((filterCountry) => setCountryMap(filterCountry));
        }
    };

    const sendVerificationDetails = () => {
        const fieldData = {
            'countryName': country.label,
            'countryCode': countryCode.label,
            'mobileNumber': phoneNumber
        }

        axios
            .post("/mobile-number-verification", fieldData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setDetails("")
                    if (response.data.data.data_update_boolean) {
                        toastr.success('Success', "Details updated successfully");
                    }
                }
                history.push({
                    pathname: "/otp-verification",
                    state: { detail: { message } }
                });
            })
            .catch(function (error) {
                console.log(error)

            });
    };

    useEffect(() => {
        if (Details && countryMap.length > 0) {
             countryMap.filter((key) => { // eslint-disable-line
                if (key.country_name === Details.is_patient.country) {
                    setCountryCode({ "value": key.id, "label": key.country_isd_code })
                    setCountry({ "value": key.id, "label": key.country_name })
                    setPhone(Details.phone)
                }
            })
        }
    }, [Details, countryMap])// eslint-disable-line

    useEffect(() => { getCountryList() }, []) // eslint-disable-line


    return (
        <>
            <div className="main h-100 w-100">
                <div className="container h-100">

                    <div className="otp-wrapper">
                        <Card>
                            <CardBody className="otp-card-body">
                                <div className="m-sm-4">
                                    <div className="text-center mb-3">
                                        <img
                                            src={logo}
                                            alt={t("altTextAppLogo")}
                                            className="otp-img-fluid"

                                        />
                                    </div>
                                    <br />
                                    <div className="otp-text-wrapper">
                                        <h3 className="text-center">Mobile Number Verification</h3><br />
                                        <p>We will send you a 6-digit code to the below mentioned phone number.</p>
                                        <p>If there are any changes, please update the details given below.</p>
                                        <AvForm>
                                            <Row>
                                                <Col lg="5" md="5">
                                                    <FormGroup>
                                                        <Label>{t("country")}{" "} <span className="mandatory-marker">*</span></Label>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            options={countryList}
                                                            onChange={onChangeList}
                                                            value={country}
                                                            required
                                                            errorMessage="This field is required"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3" md="3">
                                                    <FormGroup>
                                                        <Label>{t("countryCode")}{" "}</Label>
                                                        <Select
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                            value={countryCode}
                                                            isDisabled
                                                            options={countryCodeList}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4" md="4">
                                                    <Label>{t("phoneNumber")}{" "} <span className="mandatory-marker">*</span></Label>
                                                    <AvField
                                                        name="phone"
                                                        type="text"
                                                        placeholder={t("phoneNumber")}
                                                        id="phone"
                                                        validate={{
                                                            pattern: { value: '^[0-9]+$', errorMessage: 'only numbers allowed' },
                                                            maxLength: { value: 12 }
                                                        }}
                                                        {...bindPhone}
                                                        errorMessage="This field is required"
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                    <br />
                                    <div className="otp-wrapper">
                                    <div className="text-center">
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                sendVerificationDetails();
                                            }}
                                            className="text-center"
                                        >
                                            Send Code
                                            </Button>
                                        {" "}
                                    </div> 
                                    <div className="text-center">
                                    <Link to="/home">
                                    <button className="btn btn-outline-primary pull-right" style={{marginLeft: '10px'}}>
                                           
                                            Skip for now
                                            </button>
                                        {" "}
                                        </Link>
                                    </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="contact-info-container">
                                    <div className="email-and-phone text-center">
                                        <Col xs={12}>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                fixedWidth
                                            />
                                            <a href="mailto:info@equipohealth.com" >info@equipohealth.com</a>
                                        </Col>
                                        <Col xs={12}>
                                            <FontAwesomeIcon
                                                icon={faPhoneAlt}
                                                fixedWidth
                                            />
                                            <span >+353 (0) 1524 0476</span>
                                        </Col>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileNumberVerification;