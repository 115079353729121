import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

import logo from "../../assets/logo.svg";
import { useInput } from '../../hooks/input-hook';

import './ForgotPassword.css';

const ForgotPassword = () => {

  let history = useHistory();
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [responseMessage, setResponseMessage] = useState([]);
  const [responseFields, setResponseFields] = useState( // eslint-disable-line
    { message: 'Error occured', status: false }
  );
  const [errorStatus, setErrorStatus] = useState(false);

  const { value: email, bind: bindEmail } = useInput('');
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  

  const handleSubmit = (event, errors, values) => {
    setErrorStatus(false);
    setSpinnerEnable(!spinnerEnable);
    const request = {
      email: email,
      userType: "patient"
    };
    axios.post(API_ENDPOINT+'/user/forgot-password', request)
      .then(response => {
        if(response.status === 202){
          setErrorStatus(!errorStatus);
          setResponseMessage([...responseMessage, response.data.title]);
          toastr.success(
            "An email has been sent to you to reset your password", "Please follow the instructions mentioned inside the Equipo Health email."
          )
          setSpinnerEnable(false);
          responseFields.status = true
          localStorage.setItem('isPasswordReset', true);
          history.push('/');
        }
      })
      .catch(function (error) {
        setErrorStatus(!errorStatus);
        const errorMsg = (error && error.response && error.response.data&& error.response.data.message) || (error && error.response && error.response.data&& error.response.data.title) || (error && error.response && error.response.statusText);
        setErrorStatus(true);
        responseFields.message = errorMsg;
        responseFields.status = false
        toastr.error(
          responseFields.message
        )
        setSpinnerEnable(false);
      });
  }
  const { t } = useTranslation();
  return (
    <>
      <div className="main h-100 w-100">
        <div className="container h-100">
          <div className="login-wrapper">
            <Card className="reset-password-wrapper">
              <CardBody className="card-body">
                <div className="m-sm-4">
                  <div className="text-center image-wrapper">
                    <img
                      src={logo}
                      alt={t('altTextAppLogo')}
                      className="img-fluid"
                    />
                  </div>
                  <AvForm onValidSubmit={handleSubmit}>
                    <FormGroup>
                      <Label>{t('email')}</Label>  
                      <AvField
                        bsSize="lg"
                        name="email"
                        type="email"
                        placeholder={t('enterEmailPH')}
                        validate={{email: true}}
                        errorMessage={t('emailErrorMessage')}
                        {...bindEmail}
                        required />
                      {spinnerEnable &&
                        (<div className="spinner-wrapper">
                          <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                          <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                          <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                        </div>)
                      }
                    </FormGroup>
                    <div className="text-center mt-3 button-wrapper forget-password-btn-wrapper">
                      <Button color="primary" size="lg">
                        {t('continue')}
                      </Button>
                    </div>
                    <Link className="button-link" to="/">
                      <Button color="primary" size="lg"  outline>
                        {t('back')}
                      </Button>
                    </Link>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword;
