
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from "react-html-parser";

import { Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

import styles from "./ProviderDetails.module.css";
import getSymbolFromCurrency from 'currency-symbol-map';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar
} from "@fortawesome/free-solid-svg-icons";
import avatar from "../../../assets/avatar.png";

const ProviderDetails = (props) => {

  const { searchedSpeciality } = props

  const [postCode, setPostCode] = useState("")
  const [currency, setCurrency] = useState("")
  const [amount, setAmount] = useState("")
  const [specialityText, setSpecialityText] = useState("")

  const populatePostCode = () => {
    if(PhysicianDetails.physicianData[0].preferredContact === "office") {
      setPostCode(PhysicianDetails.physicianData[0].zipcode)
    }
    else {
      setPostCode(PhysicianDetails.physicianData[0].homeZipCode)
    }
  }

  const PhysicianDetails = props.data;
  const Profile = ReactHtmlParser(PhysicianDetails.physicianData[0].profileDescription);
  const { t } = useTranslation();
  const Languages = PhysicianDetails.physicianData[0].spokenLanguagesText.join(", ");
  

  useEffect(() => {
    populatePostCode()
  }, [PhysicianDetails.physicianData[0].preferredContact]); // eslint-disable-line

  useEffect(() => {
    PhysicianDetails.physicianData.appointmentFee.filter((item) => { // eslint-disable-line
      if (item.specialityID === searchedSpeciality) {
        setSpecialityText(item.specialityText)
        setCurrency(item.currency_code);
        setAmount(item.totalPayable)
      }
    })
  }, [PhysicianDetails.physicianData.appointmentFee, searchedSpeciality])

  return (
    <Card className={styles.providerDetailsCard}>
      <CardBody>
        <Row>
          <Col md={2}>
            <div className={styles.detailsProfImg}>
              <img
                src={PhysicianDetails.profileImageURL}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avatar;
                }}
                className="rounded-circle"
                alt="Avatar"
              />
            </div>
          </Col>
          <Col md={7}>
            <div className={styles.detailsProfContent}>
              <h3>{PhysicianDetails.title ? PhysicianDetails.title + " " : null}{PhysicianDetails.name}<span className={styles.detailsProfContentStarRating}><div>4.5<FontAwesomeIcon icon={faStar} className={styles.iconLeftSpacing} /></div></span></h3>
              <h5>{PhysicianDetails.physicianData[0].gender}</h5>
              <h5>{PhysicianDetails.physicianData[0].credentials}</h5>
              <p>{PhysicianDetails.speciality}</p>
            </div>
          </Col>
          <Col md="3">
            <div className={styles.fee_card_content_label}>
              Consultation Fee
                <p className={styles.consult_fee_green_box}> {specialityText}</p>
          </div>
            <div className={styles.fee_card}>
              <div className={styles.fee_card_content}>
                <h4>{`${getSymbolFromCurrency(currency)}${amount}`}</h4>
                <p>For {props.duration} mins</p>
              </div>
            </div>

          </Col>
        </Row>
        <hr />
        {Profile && <Row>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>{t("profile")}</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>{Profile}</div>
          </Col>
        </Row>}
        {/* <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>Years of Experience</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}></div>
          </Col>
        </Row> */}
        {(PhysicianDetails?.physicianData[0]?.affiliations) &&<Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>{t("affiliations")}</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>
              {PhysicianDetails.physicianData[0].affiliations}
            </div>
          </Col>
        </Row>}
        {Languages && <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>{t("languages")}</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>{Languages}</div>
          </Col>
        </Row>}
        {/* <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>Phone Number</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>
              {PhysicianDetails.phone}
            </div>
          </Col>
        </Row> */}
        {PhysicianDetails && <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>Practice Address</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>
              {`${PhysicianDetails.address}${","} ${ postCode }`}
            {/*   {`${PhysicianDetails.address}${","} ${PhysicianDetails.city}${","} ${PhysicianDetails.state}${","} ${PhysicianDetails.physicianData[0].countryName}${","} ${PhysicianDetails.physicianData[0].zipcode}`} */}
            </div>
          </Col>
        </Row>}
        {(PhysicianDetails?.physicianData[0]?.medicalSchool) && <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>Medical School</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>
              {PhysicianDetails.physicianData[0].medicalSchool}
            </div>
          </Col>
        </Row>}
        {(PhysicianDetails?.physicianData[0]?.countryOfRegistration) && <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>Countries of Medical Registration</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>
              {PhysicianDetails.physicianData[0].countryOfRegistration}
            </div>
          </Col>
        </Row>}
        {(PhysicianDetails?.physicianData[0]?.website) && <Row className={styles.detailProfRow}>
          <Col md={3} sm={5}>
            <div className={styles.detailProfLabel}>Website</div>
          </Col>
          <Col md={9} sm={7}>
            <div className={styles.detailProfValue}>
              {PhysicianDetails.physicianData[0].website}
            </div>
          </Col>
        </Row>}
      </CardBody>
    </Card>

  );
};

export default ProviderDetails;
