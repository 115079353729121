import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalState } from '../../context/ContextApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
} from "reactstrap";

import {
    faCheckCircle,
    faHeartbeat,
    faClinicMedical,
    faExclamationTriangle,
    faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import "./BookingConfirmation.css";
import { ICalendar } from 'datebook';

const PATIENT_APP_URL = process.env.REACT_APP_PATIENT_APP_URL;
const BookingConfirmation = () => {
    const location = useLocation();
    const history = useHistory();
    const appointmentDetails = location.state.detail;
    const { isIncompleteInfoPresent, setIsIncompleteInfoPresent } = useContext(GlobalState); // eslint-disable-line
    const { completedProfileData, setCompletedProfileData } = useContext(GlobalState); // eslint-disable-line
    let inCompletedPharmacy = completedProfileData.indexOf("Pharmacy") > -1;
    let inCompletedHealthSection = completedProfileData.replace(", Pharmacy", '').replace("Pharmacy", '');
    
    const goToPage = (route) => {
        history.push({
            pathname: route,
        });
    }


    const options = {
        title: `Tele consult with ${appointmentDetails.physicanName}`,
        description: `Tele consult with ${appointmentDetails.physicanName} on ${appointmentDetails.appointmentDate} Please login to ${PATIENT_APP_URL}`,
        start: new Date(appointmentDetails.appointmentStartTime),
        end: new Date(appointmentDetails.appointmentEndTime),
    }

    // const googleCalendar = new GoogleCalendar(options)
    const calendar = new ICalendar(options)

    return (
        <>
            <PageLayout>
                <Header>
                    <HeaderTitle>Booking Confirmation</HeaderTitle>
                </Header>
                <Row>
                    <Col>
                        <Card className='bookingSummary_docDetails_wrap'>
                            <CardBody>
                                <div className="container">
                                    <FontAwesomeIcon className="check-icon" icon={faCheckCircle} />
                                    <div className="title">Appointment Confirmed</div>
                                    <p>Good News !!! We have confirmed your appointment with <span className="highlight">{appointmentDetails.physicanName}</span> on <span className="highlight">{appointmentDetails.appointmentDate}</span></p>
                                    {/* <NavLink style={{cursor:'pointer'}} onClick={()=>calendar.download()}>Click to save the appointment to your calendar</NavLink> */}

                                    {/* <Row>
                                        <Col md={{size:4}} style={{cursor:'pointer'}} onClick={()=>icalendar.download()}>  
                                            <FontAwesomeIcon icon={faGoogle} />
                                            <span>Google</span>
                                        </Col>

                                        <Col>
                                            <FontAwesomeIcon icon={faApple} />
                                            <span>Apple</span>
                                        </Col>

                                        <Col  md={{size:4}}>
                                            <FontAwesomeIcon icon={faMicrosoft} />
                                            <span>Outlook</span>
                                        </Col>
                                    </Row> */}
                                    {isIncompleteInfoPresent ?
                                        <div className="pharmacy_info_section">
                                            <Row>
                                                <Col md="12">
                                                    <div className="ModalHeader">
                                                        <FontAwesomeIcon className="profile-warning" icon={faExclamationTriangle} />
                                                        <span className="incomplete-profile-heading">Complete your profile</span>
                                                        <hr></hr>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <h5>Looks like your health profile is incomplete. For the best medical decisions, it is advisable to provide the following information</h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="itemsExclamationContainer">
                                                    {
                                                        inCompletedHealthSection && inCompletedHealthSection.length > 2 ?
                                                            (
                                                                <div className="item" onClick={() => goToPage('/health-profile')}>
                                                                    <FontAwesomeIcon
                                                                        icon={faHeartbeat}
                                                                        fixedWidth
                                                                        className="align-middle mr-2"
                                                                    />{" "}
                                                                    <span className="sidebar_title">Health Profile</span>
                                                                    <br></br>
                                                                    <div className="sidebar_title text-center">({inCompletedHealthSection})</div>
                                                                </div>
                                                            ) : null
                                                    }
                                                    {inCompletedPharmacy ?
                                                        (
                                                            <div className="item itemTwo" onClick={() => goToPage('/care-team')}>
                                                                <FontAwesomeIcon
                                                                    icon={faClinicMedical}
                                                                    fixedWidth
                                                                    className="align-middle mr-2"
                                                                />{" "}
                                                                <span className="sidebar_title">Pharmacies</span>
                                                            </div>
                                                        ) : null}
                                                </div>
                                            </Row>
                                        </div> : null
                                    }
                                </div>
                                <div className="text-center">
                                <Button color="primary" className="mr-2" size="lg" onClick={() => calendar.download()}><FontAwesomeIcon icon={faCalendarAlt} /> Add to Calendar </Button>
                                <Button color="primary" size="lg" onClick={() => goToPage('/home')}>View Appointments</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </PageLayout>
        </>

    );
}
export default BookingConfirmation;