import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Button,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpcomingAppointments from "./Upcoming/UpcomingConsultation";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { GlobalState } from './../../context/ContextApi'
import './Index.css';
import { cookieService } from '../../services/cookieService';
import AllUpcomingAppointments from "../Consultation/MyAppointments/AllUpcomingAppointments";
import Tasks from "./Tasks";


const Index = (props) => {
  const { LastName, FirstName, Details } = useContext(GlobalState);
  let dependentID = cookieService.get('dependentID');
  let objectLength = Details.dependents ?  Object.keys(Details.dependents).length : '';
  return (
    <>
      <div className="main h-100 w-100">
        <PageLayout>
          <Header>
            <HeaderTitle>Welcome {FirstName+ " " +LastName }</HeaderTitle>
          </Header>
          <Row>
            <Col>
              <Card>
                <CardHeader className="index-header">
                  <CardTitle tag="h5" className="mb-0">
                    Upcoming Appointments
                  </CardTitle>
                  <div className="btns-title">
                    <Link to="/my-appointments">
                        <button className="btn btn-outline-primary pull-right">
                        My Appointments
                        </button>
                      </Link>
                      <a href="/book-appointment">
                        <Button
                          color="primary"
                          size="md"
                          className="pull-right mr-2"
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            fixedWidth
                            className="align-middle mr-2"
                          />
                          New Appointment
                        </Button>
                      </a>
                  </div>
                </CardHeader>
                <CardBody>
                  {dependentID == 0 && objectLength > 0  ? <AllUpcomingAppointments />
                   : <UpcomingAppointments />
                  }
                </CardBody>
                
                {/* <CardBody>
                  <Tasks />
                </CardBody> */}
              </Card>
            </Col>
          </Row>

          
        </PageLayout>
      </div>
    </>
  );
};

export default Index;
