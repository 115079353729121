import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faExclamationTriangle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";

function FileShareDropZone(props) {
    // DEPENDENCY VARIABLE 
    const { setFilesUploaded, showMaxFileWaring, setShowMaxFileWaring } = props
    // LOCAL VARIABLES
    const fileSize = Number(process.env.REACT_APP_BOOK_APPOINTMENT_FILE_SIZE)
    const fileLimit = Number(process.env.REACT_APP_BOOK_APPOINTMENT_FILE_LIMIT)
    const [acceptedFileItems, setAcceptedFileList] = useState([])
    const [fileRejectionItems, setFileRejectionItems] = useState([])

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '5px',
        marginBottom: '10px',
        width: '100%',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone( // eslint-disable-line
        {
            accept: '.jpeg, .png, .jpg, .tiff, .doc, .docx, .pdf',
            multiple: true,
            maxSize: 1048576 * fileSize
        }
    );

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
        // eslint-disable-next-line
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const writeFileRejectionItems = () => {
        let allFiles = fileRejectionItems.concat(fileRejections)
        setFileRejectionItems(allFiles);
    }

    const writeAcceptedFileItems = () => {
        let allFiles = acceptedFileItems.concat(acceptedFiles)
        setAcceptedFileList(allFiles);
    }
    
    const removeAcceptedFile = (index) => {
        let acceptedFileData = [...acceptedFileItems];
        acceptedFileData.splice(index, 1);
        setAcceptedFileList(acceptedFileData);
    }

    const removeRejectedFile = (index) => {
        let rejectedFileData = [...fileRejectionItems];
        rejectedFileData.splice(index, 1);
        setFileRejectionItems(rejectedFileData);
    }

    const removeAllFile = () => {
        setAcceptedFileList([]);
        setFileRejectionItems([]);
        setShowMaxFileWaring(false)
    }

    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes === 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const handleMaxFileCount = () => {
        acceptedFileItems.length > fileLimit ? setShowMaxFileWaring(true) : setShowMaxFileWaring(false)
    }

    useEffect(() => { writeAcceptedFileItems() }, [acceptedFiles]) // eslint-disable-line
    useEffect(() => { writeFileRejectionItems() }, [fileRejections]) // eslint-disable-line
    useEffect(() => { setFilesUploaded(acceptedFileItems); handleMaxFileCount() }, [acceptedFileItems]) // eslint-disable-line

    return (
        <section className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p className="text-center react-dropzone-text">
                    Drag 'n' drop some files here, or click to select files
                    <br />
                    (Maximum {fileLimit} files of less than {fileSize}mb each. Should be jpeg, jpg, png, tiff, doc, docx or pdf)
                 </p>
            </div>
            {showMaxFileWaring
                ? <div className="warning-label">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                    /> {" "}
                    <label className="warning-text">
                        The max number of upload allowed is {fileLimit}. Please remove extra files
                    </label>
                </div>
                : ""
            }
            <div className="react-dropzone-remove-button-container">
                {acceptedFileItems.length > 0 || fileRejectionItems.length > 0
                    ? <FontAwesomeIcon
                        className="float-right margin-bottom"
                        title="Remove All Files"
                        icon={faTrashAlt}
                        onClick={() => { removeAllFile() }}
                    />
                    : ""}
            </div>
            <aside className={acceptedFileItems.length > 0 || fileRejectionItems.length > 0 ? "file-share-list disable-scrollbars" : "display-none"}>
                {acceptedFileItems && acceptedFileItems.map((acceptedFileItem, index) => {
                    return (
                        <Row key={index} className="react-dropzone-list-item">
                        <Col>
                            <p key={index}>
                                {`${acceptedFileItem.path} - (${formatFileSize(acceptedFileItem.size)})`}
                            </p>
                        </Col>
                        <Col sm="1">
                            <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="align-middle mr-2 cursor-pointer"
                                onClick={() => { removeAcceptedFile(index) }}
                            />
                        </Col>
                    </Row>
                    );
                })} 
                {fileRejectionItems && fileRejectionItems.map(({ file:rejectedFile, errors }, index) => {
                    return (
                        <Row key={index} className="react-dropzone-list-item">
                            <Col>
                                <p key={index}>
                                    {`${rejectedFile.path} - (${formatFileSize(rejectedFile.size)})`}
                                    {errors.map(e => {
                                        let message = ""
                                        if (e.code === "file-invalid-type") message = "This file type is not supported"
                                        if (e.code === "file-too-large") message = `File size is larger than ${fileSize}MB`
                                        return <p className="react-dropzone-error-text">{`${message ? message : ""}`}</p>
                                    }
                                    )}
                                </p>
                            </Col>
                            <Col sm="1">
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    fixedWidth
                                    className="align-middle mr-2 cursor-pointer"
                                    onClick={() => { removeRejectedFile(index) }}
                                />
                            </Col>
                        </Row>
                    );
                })} 
            </aside>
        </section>
    );
}

export default FileShareDropZone