import { useState } from "react";

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        if(event.target.value !== ""){
          setValue(event.target.value);
        }
        // else{
        //   setValue(event.target.id);
        // }
      }
    }
  };
};
