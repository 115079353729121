import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import axios from "axios";
import { GlobalState } from "./../../../context/ContextApi";
import * as Validation from "./../../../common/FieldValidation";
import GeoLocation from '../../../components/Geolocation/Geolocation';
import { cookieService } from '../../../services/cookieService';
import Tooltip from "@mui/material/Tooltip";

const PhysiciansInput = (props) => {
  const { Details } = useContext(GlobalState);

  const [Countries, setCountries] = useState([]);
  const CountryList = Countries.map((key) => {
    return { value: key.id, label: key.country_name };
  });
  const[PhysicianCareTypes,setPhysicianCareTypes]= useState([]);

  const [PhysicianCareType, setPhysicianCareType] = useState({
    value: "",
    label: "Select an option",
  });

  const[PhysicianCareTypeValue,setPhysicianCareTypeValue] = useState("");

  const PhysicianCareTypesList = PhysicianCareTypes.map((key) => {
    return { value: key.id, label: key.name };
  });

  const [CountryName, setCountryName] = useState("");

  const [Country, setCountry] = useState({
    value: "",
    label: "Select an option",
  });


  const[PhysicianName,setPhysicianName]= useState("");

  const [Address, setAddress] = useState("");
  const [NewAddress, setNewAddress] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [ContactNumber, setContactNumber] = useState("");


  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');


 const  PopulateOwnCountry =()=>{
  setCountry(
    Countries.filter((a) => a.id == Details.is_patient.countryID).map((b) => ({
      value: b.id,
      label: b.country_name,
    }))
  );
  // setCountryName(
  //   Countries.filter((a) => a.country_isd_code === Details.countryCode).map(
  //     (b) => b.country_name
  //   )[0]
  // );
  setCountryName(
    Countries.filter((a) => a.id == Details.is_patient.countryID).map((b) => b.country_name)[0]
  );
 }
 
  

  const fetchCountries = () => {
    axios.get(`${API_ENDPOINT}/common/country-list`)
      .then(function (response) {
        setCountries(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };


  const PhysicianCareTypeHandler =(event)=>{
    setPhysicianCareType(event)
    setPhysicianCareTypeValue(event.value)
  }




  const submitPhysicianForm = () => {
    const formToSubmit = {

      careType :PhysicianCareTypeValue,
      name:PhysicianName,
      country: CountryName,
      address: NewAddress,
      state: State,
      city: City,
      zipcode: ZipCode,
      contactNumber: ContactNumber,
      // patientId: dependentID
    };

    if (props.physician_id) {
      axios.post(
        API_ENDPOINT + "/patient/careteam/update/"+props.physician_id,
        formToSubmit
      )
        .then((res) => {
          if (res.status === 200) {
            toastr.success("Care Provider Updated", "Care provider updated successfully");
            props.toggleModal();
            props.TableRenderPhysicians();
          }
        })
        .catch((error) => {console.log(error.response);
          toastr.error("Update Unsuccessful", "Primary specialist already exists");
        });
    } else {
      axios.post(API_ENDPOINT + "/patient/careteam/new", formToSubmit)
        .then((res) => {
          if (res.status === 201) {
            toastr.success("Care provider Added", "Care provider added successfully");
            props.toggleModal();
            props.TableRenderPhysicians();
          }
        })
        .catch((error) => {
            toastr.error("Primary Specialist Exists", "Cannot add multiple primary specialist");
        });
    }
  };

  let SubmitButton = "";

  if (
    PhysicianCareTypeValue === "" ||
    PhysicianName===""||
    CountryName === "" ||
    NewAddress === ""||
    State === ""||
    City === ""
  ) {
    SubmitButton = (
      <Button color="primary" disabled>
        Save
      </Button>
    );
  } else {
    SubmitButton = (
      <Button color="primary" onClick={() => submitPhysicianForm()}>
        Save
      </Button>
    );
  }

  const onChangeGeolocation = (event) => {
    if (event.length > 500) {
      toastr.warning(
        "Limit Exceeded",
        "Address should not be greater than 500 Characters"
      );
    } else {
      setNewAddress(event);
      setAddress(event);
    }
  };
  const onSelectGeolocation = (event) => {
    setTimeout(() => {
      if (event.administrative_area_level_1 === "long_name") {
        setState(State);
      } else {
        setState(event.administrative_area_level_1);
        setZipCode(event.postal_code)
      }
      if (event.locality === "long_name" && event.administrative_area_level_2 === "long_name") {
        setCity(City);
      } else {
        if (event.locality !== "long_name"){
          setCity(event.locality);
          setZipCode(event.postal_code)
       } else if(event.administrative_area_level_2 !== "long_name"){
        setCity(event.administrative_area_level_2);
        setZipCode(event.postal_code)
       } else {
        setCity("");
       } 
      }
    }, 500);
  };

  const CountryHandler = (event) => {
    setCountryName(event.label);
    setCountry(event);
  };


  const getSelectedCountry = (countryname) => {
    setCountry(
      Countries.filter((a) => a.country_name === countryname).map((b) => ({
        value: b.id,
        label: b.country_name,
      }))
    );
    setCountryName(
      Countries.filter((a) => a.country_name === countryname).map(
        (b) => b.country_name
      )[0]
    );
  };

  const getSelectedPhysicianCareType = (id)=>{

    
    setPhysicianCareType(
      
      PhysicianCareTypes.filter((a) => a.id === id).map((b) => ({
        value: b.id,
        label: b.name,
      })))
      
      
    setPhysicianCareTypeValue(id)

  }

  const PopulateFields = () => {
   //  const patientId = dependentID ? dependentID : '';
    // let url = `${API_ENDPOINT}/patient/careteam/view/` + props.physician_id
    // if(patientId){
    //  url = `${API_ENDPOINT}/patient/careteam/view/` + props.physician_id + `/` + patientId
    // }
    axios.get(`${API_ENDPOINT}/patient/careteam/view/` + props.physician_id)
      .then(function (response) {
        setPhysicianName(response.data.data.name);
        getSelectedPhysicianCareType(response.data.data.careTypeId);
        setAddress(response.data.data.address);
        setNewAddress(response.data.data.address);
        setState(response.data.data.state);
        setCity(response.data.data.city);
        // setZipCode(response.data.data.pincode);
        setContactNumber(response.data.data.contactNumber);
        getSelectedCountry(response.data.data.country);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []); // eslint-disable-line

  useEffect(() => {
    if(Details.countryCode && Countries &&Countries.length)
    PopulateOwnCountry();
  }, [Details.countryCode,Countries]); // eslint-disable-line

  useEffect(() => {
    if (props.physician_id && Countries) {
      PopulateFields();
    }
  }, [props.physician_id, Countries]); // eslint-disable-line


  useEffect(()=>{
if(props.PhysicianCareTypes){
 setPhysicianCareTypes(props.PhysicianCareTypes)
}
  },[props.PhysicianCareTypes])

  return (
    <div className="physician_info">
      {/* <Row>
        <Col md="12">
          <div className="ModalHeader">
            {props.job === "edit" ? <h3>Edit Physician</h3> : <h3>Add Physician</h3>}
            
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col md="6">
          <label>
            Care Type <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
          <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={PhysicianCareTypesList}
              onChange={(event) => PhysicianCareTypeHandler(event)}
               value={PhysicianCareType}
              isSearchable
              placeholder="Select "
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <label>
            Name <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.PhysicianName(e.target.value,setPhysicianName,120)}
              type="text"
              name="name"
              placeholder="Name"
              value={PhysicianName}
            />
          </FormGroup>
        </Col>
        {/* <Col md="2">
        <label>
            External
          </label>
            <CustomInput
              type="switch"
              id="switch2"
              // checked={props.ELT? true :false}
              name="external"
              // onClick={() => ToggleELT()}
            />
        </Col> */}
        <Col md="6">
          <label>
            Country <span className="mandatory-marker">*</span>
          </label>{" "}
          {/* <Label>Country</Label> */}
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={CountryList}
              onChange={(event) => CountryHandler(event)}
              value={Country}
              isSearchable
              placeholder="Select "
            />
          </FormGroup>
        </Col>
        <Col md="6">
      <FormGroup>
      <Tooltip
                  title={Address ? NewAddress : ""}
                  placement="right"
                  arrow
                >
        <label>
          Address <span className="mandatory-marker">*</span>
        </label>{' '}
        <GeoLocation
          required
          preSelectedValue={Address}
          showAllFields={false}
          isStateVisible={false}
          isCityVisible={false}
          isPinCodeVisible={false}
          addressLabelText={""}
          pincodeLabelText={"Zip code"}
          onChange={onChangeGeolocation}
          onSelect={onSelectGeolocation}
       
        />
      </Tooltip>
      </FormGroup>
    </Col>
        <Col md="6">
          <label>
            State <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.State(e.target.value,setState,24)}
              type="text"
              name="state"
              placeholder="State"
              value={State}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <label>
            City <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.City(e.target.value,setCity,24)}
              type="text"
              name="city"
              placeholder="City"
              value={City}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <label>
            Post Code 
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.ZipCode(e.target.value,setZipCode,10)}
              type="text"
              name="postcode"
              placeholder="Post Code"
              value={ZipCode}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <label>Contact Number</label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.ContactNumber(e.target.value,setContactNumber,12)}
              type="number"
              name="contactnumber"
              placeholder="Contact Number"
              value={ContactNumber}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="row" style={{'justify-content': 'center'}}>
        {SubmitButton}
        <Button color="outline-primary ml-2" onClick={props.toggleModal}>
          Cancel
        </Button>
      </Row>
    </div>
  );
};

export default PhysiciansInput;
