import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toastr } from "react-redux-toastr";
import Axios from "axios";
import Pdfviewer from "./Pdfviewer";
import DocImage from "./../doc_image.png";
import TiffImage from "./../tiff_image.png";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import MomentConfig from "./../../../common/MomentConfig";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const FileViewModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const [Preview, setPreview] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const getBase64 = () => {
    Axios.get(`${props.path}`, {
      responseType: "arraybuffer",
    }).then((response) => {
      setPreview(Buffer.from(response.data, "binary").toString("base64"))
    }
    );
  }

  const CurrentTime = moment(new Date()).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME);
  

  const DownloadFile = (index) => {
    const DefaultFileName = props.shortTitle+"_"+CurrentTime  ;
    Axios.get(`${props.path}/download`, { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            DefaultFileName+"."+props.filetype
          );
          toastr.success(
            "Document Downloaded",
            "Document downloaded successfully"
          );
          toggle(index);
        }
      })
      .catch((error) => { });
  };

  const handleDeleteFile = (index) => {
    Axios.get(`/patient/document/delete/${props.id}`)
      .then((res) => {
        if (res.status === 200) {
          props.RenderDocuments();
          toastr.success("Document Deleted", "Document deleted successfully");
          toggle(index);
        }
      })
      .catch((error) => { });
  };

  const OverlayLoader = () => (
    <div class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content">
          <span class="spinner"></span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    getBase64();
  }, []); // eslint-disable-line

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };
  const history = useHistory();
  const handleClick = () => {
    // Navigate to the communication tab
    history.push('/communication');
  };
  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <span onClick={() => toggle(index)}>{props.title}</span>

          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
            
            <ModalBody>
              <div className="document-title disable-scrollbars">
                <p>
                  <strong>Title : </strong>
                  {props.title}
                </p>
              </div>

              {!Preview ? <div className="doc-viewer-spinner"><OverlayLoader /></div> : ""}

              {Preview && props.filetype === "jpg" ||
              props.filetype === "JPG" ||
              props.filetype === "jpeg" ||
              props.filetype === "JPEG" ||
              props.filetype === "png" ||
              props.filetype === "PNG" ?  (
                <div className="file-preview">
                  <img
                    src={`data:image/jpeg;base64,${Preview}`}
                    className="img-fluid"
                    alt="file-preview"
                  />
                </div>
              ) : null}

              {Preview && props.filetype === "pdf" ? (
                <div className="file-preview">
                  <Pdfviewer url={Preview} />
                </div>
              ) : null}

              {Preview && props.filetype === "doc" ? (
                <div className="file-preview">
                  <img src={DocImage} className="doc-type-image" alt="file-preview"></img>
                </div>
              ) : null}

              {Preview && props.filetype === "tiff" ? (
                <div className="file-preview">
                  <img src={TiffImage} className="doc-type-image" alt="file-preview"></img>
                </div>
              ) : null}


              <div className="preview-buttons">
                <Button
                  color="primary"
                  className=" btn-md ml-2"
                  onClick={() => DownloadFile(index)}
                >
                  Download
                </Button>

                <Button
                  className="outline-primary btn btn-secondary btn-md ml-2 "
                  onClick={() => handleDeleteClick()}
                >
                  Delete
                </Button>
                {/* <Button
                  className="custom-buttons"
                  onClick={() => handleClick()}
                >
                   Connect with care team
                </Button> */}
                <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
                  <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}} >Delete Document</ModalHeader>
                  <ModalBody className="text-center m-3">
                    Are you sure you want to delete the document?
                  </ModalBody>
                  <ModalFooter className="justify-content-center">
                    <Button color="primary" onClick={handleDeleteFile}>
                      Yes
                    </Button>{' '}
                    <Button color="outline-primary mr-2" onClick={toggleDeleteModal}>
                      No
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <div className="modal-close">
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => toggle(index)}
                />
              </div>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default FileViewModal;
