/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { cookieService } from '../../services/cookieService';

import {
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Spinner,
  Label,
  CustomInput,
  FormGroup,
} from "reactstrap";

import {
  faMapMarkerAlt,
  faMoneyBillWaveAlt,
  faCheckCircle,
  faAngleRight,
  faChevronCircleRight,
  faCheck,
  faTimes,
  faTicketAlt,
  faTrashAlt,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import "./SlotCheckout.css";
import { useLocation } from "react-router-dom";
import logo from "../../assets/equipo-logo.svg";
import avatar from "../../assets/avatar.png";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../Payment/Payment";
import CvcForm from "../Payment/CvcForm";
import {GlobalState} from './../../context/ContextApi';
import getSymbolFromCurrency from 'currency-symbol-map';
import TermsOfUseModal from '../TermsOfUseModal/TermsOfUseModal';
import PrivacyPolicyModal from '../PrivacyPolicyModal/PrivacyPolicyModal';
import * as Validation from "./../../common/FieldValidation"
import FileShareDropZone from './FileShareDropZone';
import CancelAppointmentModal from '../Consultation/MyAppointments/CancelAppointmentModal';

const SlotCheckout = (props) => {
  // CONTEXT VARIABLES 
  const { FirstName, LastName, ruleCount, setRuleCount, searchedSpeciality } = useContext(GlobalState);

  // LOCAL VARIABLES 
  let history = useHistory();
  let payPalTransactionId = null;
  const location = useLocation();
  
  const [modal, setModal] = useState(false);
  const [termsOfUseModal, setTermsOfUseModal] = useState(false)
  const [privacyPolicyModal, setPrivacyPolicy] = useState(false)
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [applyCouponList, setApplyCouponList] = useState(false);
  const [speciality, setSpeciality] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(0);
  let responseFields = { message: 'Error occurred', status: false };
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [slotDetails, setSlotDetails] = useState({});
  const [physician, setPhysician] = useState({});
  const [isDifferentCountry, setIsDifferentCountry] = useState();
  const [credentials, setCredentials] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState(getSymbolFromCurrency('USD'));
  const [fee, setFee]= useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [errorStatus, setErrorStatus] = useState(false); // eslint-disable-line
  const [interstitialScreenVisible, setInterstitialScreenVisible] = useState(false);
  const [removeCardModalOpen, setRemoveCardModalOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [complaints, setComplaints] = useState("");
  const [submittedCoupon, setSubmittedCoupon] = useState("");
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [emptyCoupon, setEmptyCoupon] = useState(false);
  const [couponSpinner, setCouponSpinner] = useState(false);
  const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [showComplaintsError, setShowComplaintsError] = useState(false);
  const [showCVCSection, setShowCVCSection] = useState(false);
  const [showPaypalSection, setShowPaypalSection] = useState(false);
  const [currentCardValue, setCurrentCardValue] = useState("");
  const [saveCardFuture, setSaveCardFuture] = useState(true);
  const [defaultCardSection, setDefaultCardSection] = useState(false);
  const [spinnerEnableCC, setSpinnerEnableCC] = useState(true);
  const couponTextInput = useRef(null);
  const [savedCards, setSavedCards] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState("")
  const [showMaxFileWaring, setShowMaxFileWaring] = useState(false)
  const [isVatApplicable, setIsVatApplicable] = useState(false)
  const [vatFee, setVatFee] = useState(false)
  const [vatPercent, setVatPercent] = useState("")
  const [totalPayable, setTotalPayable] = useState("") 
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState(false)

  const toggle = () => { 
    setModal(!modal);
  };

  const applyCoupon  = (coupon) => {
    couponTextInput.current.value = coupon.couponCode;
    setAppliedCoupon(coupon);
    submitCoupon();
  }

  useEffect(() => {
    if(currentCardValue === "paypal") {
      createPayPalButton();
    }
  }, [currentCardValue]); // eslint-disable-line

  useEffect(() => {
    if(savedCards && savedCards.length > 0) {
      selectCardCheckout(savedCards[0]);
    } else {
      selectCardCheckout("newCard");
    }
  },[savedCards]); // eslint-disable-line

  useEffect(() => {
    setSavedCards([]);
    axios.get('/user/payment-methods').then(function (response) {
      if(response.data && response.data.data && response.data.data.length > 0) {
        setSavedCards(response.data.data);
      }
      setSpinnerEnableCC(false)
    }).catch((error) => {
      setSpinnerEnableCC(false)
    })
  }, []);
  
  const complaintsHandler = (value) => {
    if(value.trim() === '' ) {
      setShowComplaintsError(true)
    } else {
      setShowComplaintsError(false)
    }
    Validation.ChiefComplaint(value,setComplaints,500)
  };

  const toggleCouponList = () => {
    setApplyCouponList(true);
  }

  const removeCoupon= () => {
    couponTextInput.current.disabled = false;
    couponTextInput.current.value = '';
    setDiscountedPrice(0);
    setTotalFee(totalPayable);
    setSubmittedCoupon('');
    let collection = document.getElementsByClassName('applyCoupon_item');
    for (let item of collection) {
      item.classList.remove("selected");
    }
    document.getElementById('chief_complaints').style.minHeight= '156px';
    setEmptyCoupon(false);
  }

  const handleAgreement = () => {
    setAgreement(!agreement);
  }

  const handleSaveCard = () => {
    setSaveCardFuture(!saveCardFuture);
  }

  const submitCoupon = () => {
    if(couponTextInput.current.value !== "") {
      setCouponSpinner(true);
      setInvalidCoupon(false);
      let textAreaEle = document.getElementById('chief_complaints');
      axios.get('/patient/coupon/validate/'+ location.state.detail.id+"/"+ couponTextInput.current.value)
      .then(function (response) {
        setCouponSpinner(false);
        if(response.data.status === 200) {
          if(response.data.data && response.data.data) {
            let couponDetails = response.data.data;
            setSubmittedCoupon(couponDetails.couponCode);
            setDiscountedPrice((totalPayable * couponDetails.couponValue).toFixed(2));
            setTotalFee((totalPayable - (totalPayable * couponDetails.couponValue).toFixed(2)).toFixed(2));
            setTimeout(() => {
              if(couponTextInput.current) {
                couponTextInput.current.disabled = true;
              }
            }, 500);
            couponDetails.couponValue === '1' ? textAreaEle.style.minHeight= '105px' : textAreaEle.style.minHeight= '234px';
            setEmptyCoupon(true);
          } else {
            setInvalidCoupon(true);
            setSubmittedCoupon('');
            setDiscountedPrice(0);
            setTotalFee(totalPayable);
          }
        } else {
          setInvalidCoupon(true);
          setSubmittedCoupon('');
          setDiscountedPrice(0);
          setTotalFee(totalPayable);
        }
      }).catch(function (error) {
        setCouponSpinner(false);
        setInvalidCoupon(true);
        setSubmittedCoupon('');
        setDiscountedPrice(0);
        setTotalFee(totalPayable);
      });
    }
  }
  useEffect(() => {
    if(modal) {
      setTimeout(() => {
        if(couponTextInput.current) {
          couponTextInput.current.value = submittedCoupon;
        }
      }, 500);
    }
  });

  useEffect(() => {
    if (location && location.state && location.state.detail && location.state.detail.physician && location.state.detail.physician.physicianData) {
      setAppointmentDate(moment.utc(location.state.detail.slot.startAt).local().format(MomentConfig.MOMENT_FORMAT_DATE));
      setSlotDetails(location.state.detail.slot);
      setSpeciality(location.state.detail.speciality);
      setCredentials(location.state.detail.physician.physicianData["0"].credentials);
      setPhysician(location.state.detail.physician);
      setIsDifferentCountry(location.state.detail.isDifferentCountry);
      getCoupons(location.state.detail.id);
      let appointmentFeeArray = location.state.detail.physician.physicianData.appointmentFee
      let feeAmount = ""
      let totalFeeAmount = ""
      let currencySymbol = ""
      let vat = ""
      let vatFee = ""
      let vatPercent = ""
      
      appointmentFeeArray.filter((item) => { //eslint-disable-line
        if (item.specialityID === searchedSpeciality) {
          feeAmount = item.totalPayableBeforeVat
          totalFeeAmount = item.totalPayable
          currencySymbol = item.currency_code
          vat = item.isVatApplicable
          vatFee = item.vatFee
          vatPercent = item.vatPercent
        }
      })

      setFee(feeAmount);
      setTotalFee(totalFeeAmount);
      setTotalPayable(totalFeeAmount);
      setCurrencySymbol(getSymbolFromCurrency(currencySymbol));
      setIsVatApplicable(vat === "1" ? true : false);
      setVatFee(vatFee);
      setVatPercent(vatPercent * 100);
    }
  }, [location, searchedSpeciality]);

    const cancelCheckout = () => {
      history.goBack()
    }

    const getCoupons = (userID) => {
      axios.get('/patient/coupon/list/'+userID)
      .then(function (response) {
        if(response.data.status === 200) {
          setAvailableCoupons(response.data.data);
        } else {
          setAvailableCoupons([]);
        }
      }).catch(function (error) {
        setAvailableCoupons([]);
      });
    }

    const initiateNewPayment = (event, errors, values) => {
      if(showComplaintsError || complaints.trim() === '') {
        setShowComplaintsError(true);
        return;
      }
      setErrorStatus(false);
      setSpinnerEnable(!spinnerEnable);
      event.preventDefault();
      handlePayment();
    }

    const submitCvv = (event, paymentMethod) => {
      if(showComplaintsError || complaints.trim() === '') {
        setShowComplaintsError(true);
        return;
      }
      setErrorStatus(false);
      setSpinnerEnable(!spinnerEnable);
      event.preventDefault();
      triggerBookAppointmentAPI(paymentMethod, true);
    }

    const handleSubmit = (event, errors, values) => {
      // console.log(location.state.detail.slot);
      if(showComplaintsError || complaints.trim() === '') {
        setShowComplaintsError(true);
        return;
      }
      setErrorStatus(false);
      setSpinnerEnable(!spinnerEnable);
      event.preventDefault();
      const request = getRequestData();
      setInterstitialScreenVisible(true);
      axios.post('/patient/appointment/book-appointment', request)
      .then(function (response) {
        setInterstitialScreenVisible(false);
        if(response.data.status == 201) { // eslint-disable-line
          if (response.data.data && response.data.data.transactionId) {
            showConfirmationPage();
            setSpinnerEnable(false);
          } else {
            setErrorStatus(true);
            responseFields.message = 'Error occurred';
            toastr.error(responseFields.message);
            responseFields.status = false;
            setSpinnerEnable(false);
            window.scrollTo(0, 0);
          }
        } else {
          setInterstitialScreenVisible(false);
          setErrorStatus(true);
          responseFields.message = 'Error occurred';
          toastr.error(responseFields.message);
          responseFields.status = false
          setSpinnerEnable(false);
          window.scrollTo(0, 0);
        }
      })
      .catch(function (error) {
        setInterstitialScreenVisible(false);
        const errorMsg = (error && error.response && error.response.data&& error.response.data.message) || (error && error.response && error.response.data&& error.response.data.title) || (error && error.response && error.response.statusText);
        setErrorStatus(true);
        responseFields.message = errorMsg;
        toastr.error(responseFields.message);
        responseFields.status = false
        setSpinnerEnable(false);
        window.scrollTo(0, 0);
      });
    }

  const handlePayment = async () => {
    setProcessing(true);
    setInterstitialScreenVisible(true);
    console.log('paym', LastName + " " + FirstName);
    const payload = await paymentDetails.stripe.createPaymentMethod({ // eslint-disable-line
      type: 'card',
      card: paymentDetails.elements.getElement(paymentDetails.CardElement),
      billing_details: {
        name: LastName + " " + FirstName
      }
    }).then(function (result) {
      if (result.error) {
        setInterstitialScreenVisible(false);
        setErrorStatus(true);
        toastr.error(result.error.message, { timeOut: 1500 });
        setProcessing(false);
      } else if (result.paymentMethod && result.paymentMethod.id) {
        triggerBookAppointmentAPI(result.paymentMethod.id, false);
      } else {
        setInterstitialScreenVisible(false);
      }
    });
  }

  const triggerBookAppointmentAPI = (paymentMethod, recollectCvc) => {
    const request = getRequestData(paymentMethod)
    setInterstitialScreenVisible(true);
    axios.post('/patient/appointment/book-appointment', request)
    .then(async (response) => {
      if(response.data.status == 201) { // eslint-disable-line
        let requiresAction = response.data.data && response.data.data.requiresAction || null; // eslint-disable-line
        if(requiresAction) {
          setProcessing(true);
          const payload = await paymentDetails.stripe.confirmCardPayment(response.data.data.clientSecret, {
            payment_method: {
              card: paymentDetails.elements.getElement(paymentDetails.CardElement),
              billing_details: {
                name: LastName + " " + FirstName
              }
            }
          });
          setInterstitialScreenVisible(false);
          if (payload.error) {
            axios.get('/patient/appointment/transaction/cleanup/' + response.data.data.transactionId);
            setErrorStatus(true);
            responseFields.title = "Card Authentication Failed";
            responseFields.message = "Please try again with a different payment method";
            toastr.error(responseFields.title, responseFields.message);
            responseFields.status = false
          } else {
            setErrorStatus(true);
            toastr.success("Your booking has been confirmed", {timeOut: 1500});
            showConfirmationPage();
          }
          setProcessing(false);
          setSpinnerEnable(false);
        } else if(recollectCvc && response.data.data && response.data.data.clientSecret) {
          paymentDetails.stripe.confirmCardPayment(response.data.data.clientSecret, {
            payment_method: paymentMethod,
            payment_method_options: {
              card: {
                cvc: paymentDetails.elements.getElement(paymentDetails.CardCvcElement),
              }
            },
          }).then(function(result) {
            setInterstitialScreenVisible(false);
            setSpinnerEnable(false);
            if (result.error) {
              axios.get('/patient/appointment/transaction/cleanup/' + response.data.data.transactionId);
              setErrorStatus(true);
              responseFields.title = "Card Authentication Failed";
              responseFields.message = "Please try again with a different payment method";
              toastr.error(responseFields.title, responseFields.message);
              responseFields.status = false
            } else {
              setErrorStatus(true);
              toastr.success("Your booking has been confirmed", {timeOut: 1500});
              showConfirmationPage();
            }
          });
        } else if(response.data.data && response.data.data.isConfirmed === false && response.data.data.clientSecret && response.data.data.transactionId) {
          confirmPayment(response.data.data.clientSecret, response.data.data.transactionId)
        } else if (response.data.data && response.data.data.transactionId) {
          showConfirmationPage();
          setSpinnerEnable(false);
        } else {
          setErrorStatus(true);
          responseFields.message = 'Error occured';
          toastr.error(responseFields.message);
          responseFields.status = false;
          setSpinnerEnable(false);
          window.scrollTo(0, 0);
        }
      } else {
        setErrorStatus(true);
        responseFields.message = 'Error occurred';
        toastr.error(responseFields.message);
        responseFields.status = false
        setSpinnerEnable(false);
        window.scrollTo(0, 0);
      }
    })
    .catch(function (error) {
      setInterstitialScreenVisible(false);
      const errorMsg = (error && error.response && error.response.data&& error.response.data.message) || (error && error.response && error.response.data&& error.response.data.title) || (error && error.response && error.response.statusText);
      setErrorStatus(true);
      responseFields.message = errorMsg;
      toastr.error(responseFields.message);
      responseFields.status = false
      setSpinnerEnable(false);
      window.scrollTo(0, 0);
    });
  }

  const confirmPayment = async (clientSecret, transactionId) => {
    setProcessing(true);
    const payload = await paymentDetails.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: paymentDetails.elements.getElement(paymentDetails.CardElement),
        billing_details: {
          name: LastName + " " + FirstName
        }
      }
    });
    setInterstitialScreenVisible(false);
    if (payload.error) {
      axios.get('/patient/appointment/transaction/cleanup/' + transactionId);
      setErrorStatus(true);
      responseFields.message = payload.error.message;
      toastr.error(responseFields.message);
      responseFields.status = false
    } else {
      setErrorStatus(true);
      toastr.success("Your booking has been confirmed", {timeOut: 1500});
      showConfirmationPage();
    }
    setProcessing(false);
    setSpinnerEnable(false);
  }

  const imgShow = (imagesrc) => {
    let imageVal;
    if(["visa", "mastercard", "amex", "discover"].indexOf(imagesrc) > -1) {
      imageVal = require("../../assets/" + imagesrc + ".png");
    } else {
      imageVal = require("../../assets/default_creditcard.png");
    }
 
    return (
        <img
          src={imageVal}
          className="img-fluid mb-2 cardImage"
          alt="credit card logo"
        />
    );
  }

  const getRequestData = (paymentMethod, source) => {
    const data = location.state.detail;
        let timeZone = "";
        try {
          timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {

        }
        // let dependentID = cookieService.get('dependentID');
    
    
        const request = new FormData();

        // FUNCTION TO APPEND MULTIPLE FILES
        const appendFiles = () => {
          filesUploaded && filesUploaded.map(
            (file) => { // eslint-disable-line 
              request.append('files[]', file)
            }
          )
        }

        request.set('appointmentDay', data.slot.appointmentDay)
        request.set('startAt', data.slot.startAt)
        request.set('endAt', data.slot.endAt)
        request.set('status', data.slot.status)
        request.set('specialityId', searchedSpeciality)
        request.set('physicianUserId', data.id)
        request.set('couponCode', submittedCoupon)
        request.set('chiefComplaints', complaints)
        request.set('timezone', timeZone)
        // if(dependentID != 0){
        //   request.set('dependentID', dependentID)
        // }
        if(source) {
          request.set('source', source)  
        } 
        if(paymentMethod) {
          request.set('method', paymentMethod)
        }
        request.set('isSaveCard', saveCardFuture ? 1 : 0)
        if(isDifferentCountry) {
          request.set('patientConsent', 1);
        }
        appendFiles();
        return request;
  }

  const pollPaypalPaymentStatus = (cb, retry) => {
    retry++;
    axios.get('/patient/appointment/transaction/' + payPalTransactionId).then(function(res) {
      if(res.data && res.data.data && res.data.data.appointmentDetails && res.data.data.appointmentDetails["id"]) {
        cb(true);
      } else {
        if(retry > 20) {
          cb(false);
        } else {
          setTimeout(() => {
            pollPaypalPaymentStatus(cb, retry);
          }, 3000);
        }
      }
    }).catch(function(err) {
      if(retry > 20) {
        cb(false);
      } else {
        setTimeout(() => {
          pollPaypalPaymentStatus(cb, retry);
        }, 3000);
      }
    });
  }

  const createPayPalButton = () => {
    window.paypal && window.paypal.Button.render({
      env: process.env.REACT_APP_PAYPAL_ENVIRONMENT,
      payment: function(dataVal, actions) {
        const request = getRequestData(false, 'paypal');
        return axios.post('/patient/appointment/book-appointment', request).then(function(res) {
          payPalTransactionId =  res.data.data.transactionId;
          return res.data.data.paymentId;
        }).catch(function(error) {
          const errorMsg = (error && error.response && error.response.data&& error.response.data.message) || (error && error.response && error.response.data&& error.response.data.title) || (error && error.response && error.response.statusText);
          setErrorStatus(true);
          responseFields.message = errorMsg;
          toastr.error(responseFields.message);
          responseFields.status = false
          setSpinnerEnable(false);
          window.scrollTo(0, 0);
          if(payPalTransactionId) {
            axios.get('/patient/appointment/transaction/cleanup/' + payPalTransactionId);  
          }
        });;
      },
      onAuthorize: function(data, actions) {
        const request = new FormData();
        request.set('paymentID', data.paymentID);
        request.set('payerID', data.payerID);
        request.set('transactionId', payPalTransactionId);
        return axios.post('/patient/appointment/payment/paypal/execute', request).then(function(res) {
          setInterstitialScreenVisible(true);
          pollPaypalPaymentStatus((data) => {
            setInterstitialScreenVisible(false);
            if(data) {
              showConfirmationPage();
            } else {
              const errorMsg = "Unable to confirm the appointment, please try again";
              setErrorStatus(true);
              responseFields.message = errorMsg;
              toastr.error(responseFields.message);
              responseFields.status = false
              setSpinnerEnable(false);
              window.scrollTo(0, 0);
              if(payPalTransactionId) {
                axios.get('/patient/appointment/transaction/cleanup/' + payPalTransactionId);  
              }
            }
          }, 0);
        }).catch(function(error) {
          const errorMsg = (error && error.response && error.response.data&& error.response.data.message) || (error && error.response && error.response.data&& error.response.data.title) || (error && error.response && error.response.statusText);
          setErrorStatus(true);
          responseFields.message = errorMsg;
          toastr.error(responseFields.message);
          responseFields.status = false
          setSpinnerEnable(false);
          window.scrollTo(0, 0);
          if(payPalTransactionId) {
            axios.get('/patient/appointment/transaction/cleanup/' + payPalTransactionId);  
          }
        });
      },
      onCancel: function(data, actions) {
        if(payPalTransactionId) {
          axios.get('/patient/appointment/transaction/cleanup/' + payPalTransactionId);  
        }
      }
    }, '#paypal-button');
  }

  const selectCardCheckout = (cards) => {
    payPalTransactionId = null;
    if(cards === "newCard"){
      setSaveCardFuture(true);
      setDefaultCardSection(true);
      setShowCVCSection(false);
      setShowPaypalSection(false);
      setCurrentCardValue("newCard");
    } else if(cards === "paypal") {
      setSaveCardFuture(false);
      setDefaultCardSection(false);
      setShowPaypalSection(true); 
      setShowCVCSection(false);
      setCurrentCardValue("paypal");
    } else {
      setSaveCardFuture(false);
      setShowPaypalSection(false);
      setDefaultCardSection(false);
      setShowCVCSection(true);
      setCurrentCardValue(cards.id)
    }
  }

  const showConfirmationPage = () => {
    history.push({
      pathname: '/booking-confirmation',
      state: { 
        detail: { 
            physicanName: physician.title ? physician.title + " " + physician.name : physician.name, 
            appointmentDate: appointmentDate + " " + slotDetails.localTime,
            appointmentStartTime:moment
            .utc(slotDetails.startAt)
            .local()
            .format('YYYY-MM-DDTHH:mm'),
            appointmentEndTime:moment
            .utc( slotDetails.endAt)
            .local()
            .format('YYYY-MM-DDTHH:mm')
           
        } 
      }
    });
  }

  const deleteCard = (card) => {
    setCardToDelete(card);
    setRemoveCardModalOpen(true);
  }
  
  const skipCardDelete = () => {
    setCardToDelete(null);
    setRemoveCardModalOpen(false);
  }

  const confirmCardDelete = (card) => {
    skipCardDelete();
    let savedCardsData = JSON.parse(JSON.stringify(savedCards));
    savedCardsData = savedCardsData.filter(item => item.id !== card.id);
    setSavedCards(savedCardsData);
    selectCardCheckout("newCard");
    axios.post('/user/remove-card', {paymentMethod: card.id})
      .then(function (response) {

      }).catch(function (err) {

      });
  }

  const getPayButtonTootTip = () => {
    if(agreement) {
      if(showComplaintsError || complaints.trim() === '') {
        return "Please fill the reason for consultation to proceed to appointment booking"
      } else if(totalFee !== "0.00" && (error || disabled)) {
        return "Please fill correct card details to proceed to appointment booking"
      } else if (showMaxFileWaring) {
        return "Please remove extra files" 
      } else {
        return "Proceed";
      }
    } else {
      return "Please agree to the terms and conditions to proceed to appointment booking";
    }
  }

  const getPaypalBlockMessage = () => {
    if(agreement) {
      if(showComplaintsError || complaints.trim() === '') {
        return "Please fill the reason for consultation to proceed to appointment booking"
      } else if (showMaxFileWaring) {
        return "Please remove extra files" 
      } else {
        return "";
      }
    } else {
      return "Please agree to the terms and conditions to proceed to appointment booking";
    }
  }

  const getPayPalSectionClassName = () => {
    let payPalBlockMessage = getPaypalBlockMessage();
    if(payPalBlockMessage !== '') {
      return 'paypalSection cursor-not-allowed'
    }
    return 'paypalSection';
  }

  const getPayPalButtonClassName = () => {
    let payPalBlockMessage = getPaypalBlockMessage();
    if(payPalBlockMessage !== '') {
      return 'no-click'
    }
    return '';
  }
  
  // SETTING RULE COUNT EMPTY WILL TRIGGER AN API CALL ON THE CONTEXT API
  useEffect(() => { setRuleCount("") }, []) //eslint-disable-line

  return (
    <>
    <PageLayout>
      <Header>
        <HeaderTitle>Booking Summary</HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card className='bookingSummary_docDetails_wrap'>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Row noGutters>
                    <Col md={4}>
                      <div className='bookingSummary_docDetails_img'>
                        <img
                          src={physician.profileImageURL}
                          onError={(e)=>{e.target.onerror = null; e.target.src=avatar}}
                          className="rounded-circle"
                          alt="Avatar"
                        />
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className='bookingSummary_docDetails'>
                        <h2>{physician.title?physician.title+" ":null}{physician.name}</h2>
                        <h4> {credentials}</h4>
                        <p>{speciality}</p>
                        {/* <p>{physician.email}</p>
                        <div className='startRatings'>
                          <FontAwesomeIcon className="text-warning" icon={faStar} />
                          <FontAwesomeIcon className="text-warning" icon={faStar} />
                          <FontAwesomeIcon className="text-warning" icon={faStar} />
                          <FontAwesomeIcon className="text-warning" icon={faStar} />
                          <FontAwesomeIcon className="disabled" icon={faStar} />
                          <span className="startRating_value">35</span>
                        </div> */}
                        <div className="bookingSummary_location">
                          <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-right-spacing"/>
                          <span>{physician.address}</span>
                        </div>
                       {/*  <div className="bookingSummary_location">
                          <FontAwesomeIcon icon={faPhone} className="icon-right-spacing"/>
                          <span>{physician.countryCode} - {physician.phone}</span>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col md={12} className="booking-summary-block">
                      <h3 className="booking-title">Booking Summary</h3>
                        <div class="booking-item-wrap">
                          <ul class="booking-date">
                            <li>Appointment Date and Time <span className="appt-date-time">{appointmentDate} {slotDetails.localTime}</span></li>
                            <li className="reason-consultation">
                              <span className="reason-label">Chief Complaint</span>
                            <span>
                              <Input
                                type="textarea"
                                name="text"
                                id="chief_complaints"
                                value = {complaints}
                                className={showComplaintsError ?  'error' : ''}
                                maxLength = {225}
                                placeholder="Please mention your concerns / complaint"
                                onChange={(event) => complaintsHandler(event.target.value)}
                              />
                            </span>
                            {showComplaintsError ? 
                              <span style={{textTransform: 'none', textAlign: 'left', color: 'red'}}>*Please specify your reason for consultation to proceed</span> 
                            : null 
                            }
                            <span className="reason-label" style={{textTransform: 'none', textAlign: 'right'}}>max 225 characters allowed</span>
                          </li>
                        </ul>
                      </div>
                     
                      <h3 className="booking-title">Have any documents to share with your healthcare provider?</h3>
                      <div className="file-upload-text">Sharing your clinical documents will help your healthcare provider provide effective consultation and value for your money. For large files please upload to your health profile.</div>
                      <div className="coupon-wrapper">
                        <div className={`applyCoupon_wrapper ${emptyCoupon ? 'coupon-applied-wrapper' : ''}`}>
                          <div className={`input-wrapper`} >
                            
                              <FileShareDropZone
                                setFilesUploaded={setFilesUploaded}
                                showMaxFileWaring={showMaxFileWaring}
                                setShowMaxFileWaring={setShowMaxFileWaring}
                              />

                          </div>
                        </div>
                      </div>   
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <div class="booking-summary">
                    <div className="price-details-coupon">
                    <div>
                        <AvForm className="agree_bookingSummary_wrap">
                          <AvInput
                            tag={CustomInput}
                            type="checkbox"
                            name="customCheckbox"
                            className="agree_bookingSummary_element"
                            /* label="I agree to the following:" */
                            label={<div>I confirm that I have read and understood <a onClick={() => { setTermsOfUseModal(true) }}>Terms of Use </a> &amp; <a onClick={() => { setPrivacyPolicy(true) }}> Privacy Policy</a></div>}
                            onChange={handleAgreement}
                            required
                          />

                            
                          
                        </AvForm>
                      </div>
                      <hr/>
                    </div>
                      <div class="booking-total">

                        <CancelAppointmentModal
                          cancelAppointmentModal={cancelAppointmentModal}
                          setCancelAppointmentModal={setCancelAppointmentModal}
                          parent="bookingScreen"
                        />

                        <div className="price-details-container">
                          <h3 className="booking-title">Price Details</h3>
                          {ruleCount > 0
                            ? <FontAwesomeIcon
                              icon={faExclamationCircle}
                              className="align-middle"
                              title="Cancellation Charges"
                              onClick={() => { setCancelAppointmentModal(true) }}
                            />
                            : ""}
                        </div>
                        <ul class="booking-fee">
                          <li>Price for consultation<span className="consult-fee">{currencySymbol} {fee}</span></li>
                        </ul>
                        {isVatApplicable
                          ? <ul class="booking-fee">
                            <li>VAT ({vatPercent}%)<span className="consult-fee">{currencySymbol} {vatFee}</span></li>
                          </ul>
                          : ""}
                        {submittedCoupon !== '' ? (
                        <div class="discount-item-wrap">
                          <ul class="booking-date">
                            <li className="discount-color">Discount Applied <span>{currencySymbol} {discountedPrice}</span></li>
                          </ul>
                        </div>
                      ) : null }
                        <ul class="booking-total-list">
                          <li>
                            <span>You Pay</span>
                            <span class="total-cost">{currencySymbol} {totalFee}</span>
                          </li>
                        </ul>
                        {spinnerEnable && totalFee == 0 ? ( // eslint-disable-line
                          <div className="spinner-wrapper">
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                          </div>
                          ) : null
                        }
                        {totalFee == 0 ? ( // eslint-disable-line
                        <div className="btn-block" style={{textAlign: 'right'}}>
                          <Button color="primary" size="lg" className="proceedPaymentBtn" title={getPayButtonTootTip()} disabled={agreement && !showMaxFileWaring ? false : true} onClick={handleSubmit}>Book</Button>
                        </div>
                        ) : null }
                      </div>
                      <hr/>
                      {totalFee > 0 ? (
                        <div className="payment-block">
                          <h3 className="booking-title">Payment Methods</h3>
                          <h6 className="section-title">Coupon</h6>
                          <hr className="green-outline"/>
                          <div className="coupon-wrapper">
                          <div className={`applyCoupon_wrapper ${emptyCoupon ? 'coupon-applied-wrapper' : ''}`}>
                            <div className={`input-wrapper`} >
                              <Input innerRef={couponTextInput} maxLength={120} type="text" name="input" placeholder="Enter coupon code here" autoComplete="off" className={`${invalidCoupon ? 'invalidCoupon': ''} coupon-input`}></Input>
                            </div>
                            {!emptyCoupon ?
                            (
                            <div className="btn-wrapper coupon-btn" >
                              <Button color="primary" className="mr-1 mb-1" title={agreement ? "Proceed" : "Please agree to the terms and conditions to proceed to appointment booking"}  disabled={!agreement} onClick={submitCoupon}>
                                <FontAwesomeIcon icon={faCheckCircle}/>
                              </Button>
                            </div>
                            ): (
                              <>
                              <div className="btn-wrapper button-wrapper--remove" onClick={removeCoupon}>
                                Remove
                              </div>
                              <span className="coupon-label">
                                Coupon Code
                              </span>
                            </>
                            )
                          }
                          </div>
                        </div>
                      {couponSpinner ? (
                          <div className="spinner-wrapper margin-1em">
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                          </div>
                          ) : null
                      }
                      {emptyCoupon && (
                        <div className="coupon-applied-info">
                          <span className="list"><FontAwesomeIcon icon={ faCheck } className="icon-right-spacing"/>Congrats! you just saved {currencySymbol} {discountedPrice}.</span>
                        </div>
                        )
                      }
                      {invalidCoupon && (
                        <div className="coupon-invalid-info">
                          <span className="list"><FontAwesomeIcon icon={ faTimes } className="icon-right-spacing"/>Looks like the coupon you tried is no longer valid.</span>
                        </div>
                        )
                      }
                        {!applyCouponList && (
                          <a className="coupon-show-link" style={{display: 'none'}} onClick={toggleCouponList}>available coupons</a>
                        )}
                        {applyCouponList && (
                          <div className="applyCoupon_list">
                            <h5>Coupons Available:</h5>
                            <ListGroup>
                              {availableCoupons.map(coupon => {
                                return (
                                  <ListGroupItem>
                                    <span className={`applyCoupon_item ${appliedCoupon.couponCode === coupon.couponCode ? 'selected' : ''}`} onClick={e => applyCoupon(coupon)}>
                                      <FontAwesomeIcon icon={appliedCoupon.couponCode === coupon.couponCode ? faChevronCircleRight : faTicketAlt } className="icon-right-spacing"/>
                                      {coupon.couponCode}
                                    </span>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </div>
                        )}
                        <h6 className="section-title">Credit / Debit / ATM Card</h6>
                        <hr className="green-outline"/>
                          {spinnerEnableCC ? (
                          <div className="spinner-wrapper">
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                          </div>
                          ) : 
                          <FormGroup>
                            {savedCards && savedCards.map(cards => {
                                  return (
                                    <ListGroupItem>
                                    <div className="list-item-card">
                                      <CustomInput
                                        type="radio"
                                        id={cards.id}
                                        name="customRadio"
                                        label= ""
                                        className="mb-2 gender-flex cardRadio"
                                        value={cards.id}
                                        checked={cards.id === currentCardValue}
                                        onClick={() => selectCardCheckout(cards)}
                                      />
                                      <span class="cardImg">{imgShow(cards.card.brand)}</span> 
                                      <span class="cardName"><div>ending in {cards.card.last4}</div></span>
                                      <div class="cardExp">Expires on: {cards.card.exp_month > 9 ? cards.card.exp_month : '0' + cards.card.exp_month}/{cards.card.exp_year}</div>
                                      <div class="cardDelete"><FontAwesomeIcon icon={faTrashAlt} fixedWidth className="align-middle mr-2 pointer" onClick = {() => {deleteCard(cards)}}/></div>
                                    </div>

                                    {showCVCSection && currentCardValue == cards.id ? ( // eslint-disable-line
                                    <div class="cvcSection">
                                      <Row>
                                        <Col lg={4}>
                                          <Elements stripe={promise}>
                                            <CvcForm setPaymentDetails={setPaymentDetails} setDisabled={setDisabled} setError={setError}/>
                                          </Elements>
                                        </Col>
                                            <Col lg={4}><Button color="primary" size="lg" disabled={processing || error || disabled || !agreement || showMaxFileWaring} className="payBtnCvc" title={getPayButtonTootTip()} onClick={(event) => submitCvv(event, cards.id)}>Pay</Button></Col>
                                      </Row>
                                    </div>) : null
                                    }
                                    
                                    </ListGroupItem>
                                  );
                                  
                            })}
                            <ListGroupItem>
                            <div className="list-item-card">
                                    <CustomInput
                                      type="radio"
                                      id="newCard"
                                      name="customRadio"
                                      label= {<b className="card-info">Add Credit / Debit / ATM Card</b>}
                                      className="mb-2 gender-flex"
                                      value="newCard"
                                      checked={currentCardValue === "newCard"}
                                      onClick={() => selectCardCheckout("newCard")}
                                    />
                            </div>      
                          {defaultCardSection && (
                            <>
                          <div className="payment-wrappers payment-block-section">
                            <Elements stripe={promise}>
                              <PaymentForm setPaymentDetails={setPaymentDetails} setDisabled={setDisabled} setError={setError}/>
                            </Elements>
                          </div>
                          {error ? <div className="errorText">{error}</div>:null}
                          {spinnerEnable &&
                            (<div className="spinner-wrapper">
                              <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                              <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                              <Spinner color="dark" type="grow" className="mr-2" size="sm"/>
                            </div>)
                          }
                          <div>
                            <AvForm className="list-item-card">
                              <AvInput
                                tag={CustomInput}
                                type="checkbox"
                                name="saveCardFuture"
                                className="agree_bookingSummary_element"
                                label="Save this card for future use"
                                defaultChecked={saveCardFuture === true}
                                onChange={handleSaveCard}
                              />
                            </AvForm>
                          </div>  
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                          <Button color="primary" size="lg" title={getPayButtonTootTip()} disabled={processing || error || disabled || !agreement || showMaxFileWaring}  className="proceedPaymentBtn gridAuto proceedPaymentNewCardBtn" onClick = {initiateNewPayment}>Pay</Button>
                          </div>
                          </>
                          )}
                          </ListGroupItem>
                          </FormGroup>
                          }
                          <h6 className="section-title">More Payment Options</h6>  
                          <hr className="green-outline"/> 
                          <ListGroupItem>
                            <div className="list-item-card">
                              <CustomInput
                                type="radio"
                                id="paypal"
                                name="customRadio"
                                label= {<b className="card-info">Paypal</b>}
                                className="mb-2 gender-flex cardRadio"
                                value="paypal"
                                checked={currentCardValue === 'paypal'}
                                onClick={() => selectCardCheckout('paypal')}
                              />
                            </div>
                      </ListGroupItem>    
                      {showPaypalSection ? (
                        <div className={getPayPalSectionClassName()}>
                          <p>{getPaypalBlockMessage()}</p>
                          <div id="paypal-button" className={getPayPalButtonClassName()}></div>
                        </div>) : null
                      }
                        </div>
                      ) : null }
                      <Button color="secondary" size="lg" className="cancelPaymentBtn pull-right" onClick = {cancelCheckout}>Cancel</Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered modal-dialog-scrollable">
        <ModalHeader toggle={toggle}>
          <h3>Apply Coupon</h3>
        </ModalHeader>
        <ModalBody>
        
          <div className="applyCoupon_wrapper">
            <div className="input-wrapper">
              <FontAwesomeIcon icon={faMoneyBillWaveAlt} className="input-icon"/>
              <Input innerRef={couponTextInput} type="text" name="input" placeholder="Enter coupon code here" className={`${invalidCoupon ? 'invalidCoupon': ''}`}></Input>
            </div>
            <div className="btn-wrapper" onClick={submitCoupon}>
              <Button color="primary" className="mr-1 mb-1">
                <FontAwesomeIcon icon={faCheckCircle}/>
              </Button>
            </div>
          </div>

          <div className="applyCoupon_list">
            <h5>Coupons Available:</h5>
            <ListGroup>
              {availableCoupons.map(coupon => {
                return (
                  <ListGroupItem>
                    <span className={`applyCoupon_item ${submittedCoupon === '' ? 'selected' : ''}`}
                      onClick={e => applyCoupon(coupon)}>
                      <FontAwesomeIcon
                        icon={appliedCoupon.couponCode == coupon.couponCode ? faChevronCircleRight : faAngleRight} // eslint-disable-line
                        className="icon-right-spacing"
                      />{coupon.couponCode}
                    </span>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size="md" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </PageLayout>
    <Modal
      isOpen={removeCardModalOpen}
      toggle={skipCardDelete}
      centered
    >
      <ModalBody className=" m-3 PharmaciesModal">
        <Label style={{fontWeight: '500', fontSize: '1em', color: '#fe5d69'}}>Are you sure you want to delete this card ? </Label>
        <Row>
          {cardToDelete ? 
          <div className="list-item-card" style={{width: '100%', justifyContent: 'space-evenly', padding: '7px 2px 0 2px'}}>
            <div class="cardImg">{imgShow(cardToDelete.card.brand)}</div> 
            <div class="cardName" style={{padding: '0 1rem'}}><div>ending in {cardToDelete.card.last4}</div></div>
            <div class="cardExp">Expires on: {cardToDelete.card.exp_month > 9 ? cardToDelete.card.exp_month : '0' + cardToDelete.card.exp_month}/{cardToDelete.card.exp_year}</div>
          </div>
         :null }
        </Row>
      </ModalBody>
      <ModalFooter style={{justifyContent: "center"}}>
        <Button color="primary" style={{width: '30%'}} onClick={() => confirmCardDelete(cardToDelete)}>Yes</Button>
        <Button color="secondary" style={{width: '30%'}} onClick={skipCardDelete}>No</Button>
      </ModalFooter>
    </Modal>
    <TermsOfUseModal
              termsOfUseModal={termsOfUseModal}
              setTermsOfUseModal={setTermsOfUseModal}
            />
            <PrivacyPolicyModal
              privacyPolicyModal={privacyPolicyModal}
              setPrivacyPolicy={setPrivacyPolicy}
            />
    <PaymentLoaderPage rate={totalFee} currencySymbol={currencySymbol} visiblity={interstitialScreenVisible}/>
    </>
  );
}

const PaymentLoaderPage = (props) => (
  <div className={`payment-wrapper ${props.visiblity ? 'show-loader': 'hide-loader'}`}>
    <div className="container payment-card">
      <div className="logo-container">
        <img
          src={logo}
          alt="Doc APP logo"
          className="img-fluid"
          width="220"
        />
      </div>
      <div className="loader-wrapper"></div>
      <div className="loader"></div>
      <div className="description">
        <h4>Your payment of <b className="payment-rate">{props.currencySymbol} {props.rate}</b> is processing please wait!</h4>
      </div>
    </div>
      <div class="right-triangle">
    </div>
  </div>
);


export default SlotCheckout;
