/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import AttachmentTab from '../VideoConsultation/AttachmentTab';
import { GlobalState } from '../../../context/ContextApi';
import { toastr } from "react-redux-toastr";
import axios from 'axios';
import { useLocation, useHistory } from "react-router-dom";
import moment from 'moment';

const ManageFilesModal = (props) => {
    // DEPENDENCY VARIABLES 
    const { manageFilesModal, setManageFilesModal, schEndTime } = props;
    // CONTEXT VARIABLES 
    const { Details } = useContext(GlobalState)
    // LOCAL VARIABLES 
    let history = useHistory();
    const fileSize = Number(process.env.REACT_APP_BOOK_APPOINTMENT_FILE_SIZE)
    const [spinner, setSpinner] = useState(false)
    const [appointmentId, setAppointmentId] = useState("")
    const [physicianId, setPhysicianId] = useState("")
    const [status, setStatus] = useState("")
    const [file, setFile] = useState("")
    const [reloadDocumentList, setReloadDocumentList] = useState(false)
    const [showUploadOption, setShowUploadOption] = useState(false)
    

    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes === 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const checkFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase()
        if (
            extension === "jpeg" ||
            extension === "jpg" ||
            extension === "png" ||
            extension === "tiff" ||
            extension === "doc" ||
            extension === "docx" ||
            extension === "pdf"
        ) {
            return true
        } else {
            return false
        }
    }

    const checkFileSize = (size) => {
        if (size <= (fileSize * 1048576)) {
            return true
        } else {
            return false
        }
    }

    const handleFile = (file) => {
        const isFileTypeOk = checkFileType(file.name)
        const isFileSizeOk = checkFileSize(file.size)

        if (isFileTypeOk) {
            setFile(file)
        } else {
            setFile("")
            toastr.error(
                "This file type is not supported",
                "Supported types are ( jpeg, png, jpg, tiff, doc, docx, pdf )"
            )
        }

        if (isFileTypeOk && !isFileSizeOk) {
            setFile("")
            toastr.error(
                `File size is larger than ${fileSize}MB`,
                `Please upload a file smaller that ${fileSize}MB`
            )
        }
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const getAppointmentIDFromURL = () => {
        let aid = query.get("aid")
        if (aid) setAppointmentId(atob(aid))
    }

    const getPhysicianIDFromURL = () => {
        let pid = query.get("pid")
        if (pid) setPhysicianId(atob(pid))
    }

    const getStatusFromURL = () => {
        let status = query.get("status")
        status = atob(status)
        setStatus(status)
    }

    const calculateAndEnableUpload = () => {
        const date = new Date();
        const currentTime = moment.utc(date).local();
        const endTime = moment.utc(schEndTime).local();
        let difference = moment.duration(endTime.diff(currentTime))._milliseconds;
        let timeDuration = difference < -3600000 ? 0 : difference;
        
        // SAVE DURATION TO GLOBAL CONTEXT
        if(timeDuration) setShowUploadOption(true);
    }

    const uploadedDocument = () => {
        setSpinner(true)
        setReloadDocumentList(false)
        const request = new FormData()

        request.set('appointmentId', appointmentId)
        request.set('physicianId', physicianId)
        request.append('file', file)

        axios.post('/patient/appointment/document-upload', request
        )
            .then(function (response) {
                // console.log(response)
                setSpinner(false)
                setReloadDocumentList(true)
                setFile("")
                toastr.success("File Uploaded Successfully")
            })
            .catch(function (error) {
                // console.log(error)
                setSpinner(false)
                toastr.error(error.response.data.title, error.response.data.data.validation)
            });
    }

    const toggle = () => {
        setManageFilesModal(!manageFilesModal);
        setFile("");
        history.push("/my-appointments")
    }

    const fileRelatedInfo = (
        <div className="file-related-info">
            <span>Choose a file to upload</span>
            <i>Should be jpeg, jpg, png, tiff, doc, docx or pdf file of less than {fileSize}mb</i>
        </div>
    )

    useEffect(() => { getAppointmentIDFromURL(), [] }) // eslint-disable-line
    useEffect(() => { getPhysicianIDFromURL(), [] }) // eslint-disable-line
    useEffect(() => { getStatusFromURL(), [] }) // eslint-disable-line
    useEffect(() => { calculateAndEnableUpload(), [] }) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={manageFilesModal} toggle={toggle} >
                <ModalHeader toggle={toggle} cssModule={{'modal-title': 'w-100 text-center'}}  ><h3>Manage Files</h3></ModalHeader>
                <ModalBody>
                    <div className="manage-file-card-container disable-scrollbars">
                        {spinner ?
                            <div className="manage-file-modal-spinner">
                                <Spinner color="primary" />
                            </div>
                            : ""}
                        <AttachmentTab
                        parent="manageFile" 
                        userId={Details.id} 
                        reloadDocumentList={reloadDocumentList} 
                        />
                    </div>
                    {status === "Scheduled" && showUploadOption
                        ? <div className="manage-file-upload-container">
                            <Label className="manage-file-upload">
                                <FontAwesomeIcon icon={faUpload} /> {" "}{" "} Upload
                                <Input
                                    type="file"
                                    onChange={(event) => handleFile(event.target.files[0])}
                                />
                            </Label>
                            {file.name ? `${file.name} ( ${formatFileSize(file.size)} )` : fileRelatedInfo}
                        </div>
                        : ""
                    }

                </ModalBody>
                <ModalFooter className="manage-file-modal-footer">
                    {file ? <Button color="primary" onClick={() => { uploadedDocument() }}>Upload File</Button> : ""} {' '}
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ManageFilesModal;