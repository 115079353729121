/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
  Button,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";


import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";

import SubNav from "../SubNav";

import styles from './Security.module.css';

import QRCode from "../../../assets/download.jpeg";


const LanguageInfo = () => {
  const [securityStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAuth, setmodalAuth] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleAuth = () => setmodalAuth(!modalAuth);
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="mb-0">
        {t('twoFactorAuthentication')}
        </CardTitle>
      </CardHeader>
      <CardBody className={styles.cardBody}>
        <Form>
          <Row>
            <Col md="12">
            <FormGroup className={styles.securityStatusWrapper}>
                <Label className={styles.securityStatusLabel}>{t('status')}</Label>
                <div className={
                  classNames(
                    styles.securityStatusValue,
                    securityStatus ? 
                      styles.securityStatusEnabled : 
                      styles.securityStatusDisabled
                  )
                    }
                  >
                    {securityStatus ? t('enabled') : t('disabled')}
                  </div>
              </FormGroup>
              <FormGroup>
                <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle tag="div">
                  <a aria-haspopup="true" className={styles.securityStatusBtn} aria-expanded="false">
                    <span className={classNames("margin-left-10", styles.securityStatusBtnSpan)}>{t('enable')}</span>
                    <FontAwesomeIcon icon={faChevronDown} />{" "}
                  </a>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={toggle}>{t('sms')}</DropdownItem>
                  <DropdownItem onClick={toggleAuth}>{t('authenticatorApp')}</DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown>
                <ModalTemplateSMS isOpen={modal} toggle={toggle}/>
                <ModalTemplateAuthenticatorAPP isOpen={modalAuth} toggle={toggleAuth}/>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

const ModalTemplateSMS = ({isOpen, toggle}) => {
  const { t } = useTranslation();

  return (
  <Modal
    isOpen={isOpen} toggle={toggle}
    centered
  >
    <ModalHeader toggle={toggle} className="text-center">
    {t('enableTwoFactorAuth')}
    </ModalHeader>
    <Form>
      <ModalBody className="text-center m-3">
        <Row>
          <Col md="12">
            <FormGroup>
              <Label>{t('enterVerificationNumber')} <span className="font-weight-bold">+919496328220</span></Label>
              <Input
                bsSize="lg"
                type="text"
                name="phone"
                placeholder={t('enterEmail')}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className={styles.securityStatusFooterModal}>
      <Button color="primary">{t('submit')}</Button>{" "}
      <Button color="secondary" onClick={toggle}>
      {t('close')}
      </Button>{" "}
      </ModalFooter>
    </Form>
  </Modal>
  )
};

const ModalTemplateAuthenticatorAPP = ({isOpen, toggle}) => {
  const { t } = useTranslation();

  return (
  <Modal
    isOpen={isOpen} toggle={toggle}
    centered
  >
    <ModalHeader toggle={toggle} className="text-center">
    {t('enableTwoFactorAuth')}
    </ModalHeader>
    <Form>
      <ModalBody className="text-center m-3">
      <p className={styles.securityStatusModalWarning}>{t('deleteAuthAppreAdding')}</p>
        <Row>
          <Col md="12">
            <div>
              <img
                  alt="Chris Wood"
                  src={QRCode}
                  className="img-responsive mt-2"
                  width="148"
                  height="148"
                />
            </div>
            <FormGroup>
              <Label>{t('enterCodeAuthApp')}</Label>
              <Input
                bsSize="lg"
                type="text"
                name="phone"
                placeholder="Enter displayed code"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className={styles.securityStatusFooterModal}>
      <Button color="primary">{t('submit')}</Button>{" "}
      <Button color="secondary" onClick={toggle}>
      {t('close')}
      </Button>{" "}
      </ModalFooter>
    </Form>
  </Modal>
)
};

const Language = () => {
  const { t } = useTranslation();

  return(
  <PageLayout>
    <Header>
      <HeaderTitle>{t('myAccount')}</HeaderTitle>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/settings">{t('myAccount')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('security')}</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Row>
      <Col md="3" xl="2">
        <SubNav />
      </Col>
      <Col md="9" xl="10">
        <LanguageInfo />
      </Col>
    </Row>
  </PageLayout>
  )
};

export default Language;
