import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { Button, CustomInput, Col, Label, FormGroup, Input, Row } from "reactstrap";
import axios from "axios";
import { GlobalState } from "./../../../context/ContextApi";
import * as Validation from "./../../../common/FieldValidation";
import GeoLocation from '../../../components/Geolocation/Geolocation';
import DatePicker from "react-datepicker";
import { useInput } from '../../../hooks/input-hook';
import { useDropInput } from "../../../hooks/drop-down-hook";
import moment from "moment"; 
import MomentConfig from '../../../common/MomentConfig';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";

import defaultAvatar from "../../../assets/avatar.png";


const DependentsInput = (props) => {
  let token = localStorage.getItem("token");

  const { Details, setDetails, isProfileUpdated } = useContext(GlobalState);
  const avatar = defaultAvatar;
  const [countryMap, setCountryList] = useState([]);
  const [CountryName, setCountryName] = useState("");
  const [CountryID, setCountryID] = useState("");
  // const [Countries, setCountries] = useState([]);
  // const CountryList = Countries.map((key) => {
  //   return { value: key.id, label: key.country_name };
  // });
  // const[PhysicianCareTypes,setPhysicianCareTypes]= useState([]);

  // const [PhysicianCareType, setPhysicianCareType] = useState({
  //   value: "",
  //   label: "Select an option",
  // });

  // const[PhysicianCareTypeValue,setPhysicianCareTypeValue] = useState("");

  // const PhysicianCareTypesList = PhysicianCareTypes.map((key) => {
  //   return { value: key.id, label: key.name };
  // });

  // const [CountryName, setCountryName] = useState("");

  // const [Country, setCountry] = useState({
  //   value: "",
  //   label: "Select an option",
  // });


  const[FirstName,setFirstName]= useState("");
  const[MiddleName,setMiddleName]= useState("");
  const[LastName,setLastName]= useState("");

  const [Address, setAddress] = useState("");
  const [NewAddress, setNewAddress] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [ContactNumber, setContactNumber] = useState("");

  const { t } = useTranslation();
  const [gender, setGender] = useState("");
  const { value: dob, bind: bindDOB, setValue: setDOB } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail  } = useInput('');
  // const [countryID, setCountryID] = useState("");
  // const [countryMap, setCountry] = useState([]);
  const [Titles, setTitles] = useState([]);
  const [Title, setTitle] = useState({ value: "", label: "Select Title" });
  const [TitleValue, setTitleValue] = useState("");
  const [states, setStates] = useState([]);
  const [UserState, setUserState] = useState("");
  const [Photo, setPhoto] = useState("");
  const [PicPreview, setPicPreview] = useState("");
  const fileSize = Number(process.env.REACT_APP_PROFILE_PICTURE_FILE_SIZE);


  let countryList = countryMap.map(function (list) {
    return { value: list.country_name, label: list.country_name, id: list.id };
  });
  
 
  // function setCountryDetail(countryCode) {
  //   if (countryCode == "" || countryCode == undefined) { // eslint-disable-line
  //     countryCode = "";
  //   }
  //   setCountryCode({ value: countryCode, label: countryCode });
  //   if (countryMap.length > 0) {
  //     countryMap
  //       .filter((key) => key.country_isd_code == countryCode) // eslint-disable-line
  //       .map((filterCountry) => setCountryMap(filterCountry));
  //   } else {
  //     axios
  //       .get("/common/country-list")
  //       .then(function (response) {
  //         if (response.status === 200) {
  //           setCountryList(response.data.data);
  //         }
  //       })
  //       .catch(function (error) { });
  //   }
  // }
  // function setCountryDetail(country) {
    
  //   setCountryCode({ value: country, label: country });
  //   if (countryMap.length > 0) {
  //     countryMap
  //       .filter((key) => key.country_name === country) 
  //       .map((filterCountry) => setCountryMap(filterCountry));
  //   } else {
  //     axiosInstance
  //       .get("/common/country-list")
  //       .then(function (response) {
  //         if (response.status === 200) {
  //           setCountryList(response.data.data);
  //           let countryList = response.data.data;
  //           countryList
  //             .filter((key) => key.country_name === country) 
  //             .map((filterCountry) => setCountryMap(filterCountry));
  //         }
  //       })
  //       .catch(function (error) { });
  //   }
  // }

  // function setCountryMap(country) {
  //   setCountryList({
  //     value: country.country_name,
  //     label: country.country_name,
  //   });
  //   setCountryCode({
  //     value: country.country_isd_code,
  //     label: country.country_isd_code,
  //   });
  //   setCountryName(country.country_name);
  //   setCountryID(country.id)
  // }
  

  // const onChangeList = (event, errors, values) => {
  //   setCountryList(event);
  //   if (countryMap.length > 0) {
  //     countryMap
  //       .filter((key) => key.country_name == event.value) // eslint-disable-line
  //       .map((filterCountry) => setCountryMap(filterCountry));
  //   }
  // };

  // let countryList = countryMap.map(function (list) {
  //   return { value: list.country_name, label: list.country_name };
  // });
  let countryCodeList = countryMap.map(function (list) {
    return { value: list.country_isd_code, label: list.country_isd_code };
  });
  const {
    value: countryCode,
    bind: bindCountryCode, // eslint-disable-line
    setValue: setCountryCode,
  } = useDropInput({ value: "", label: "" });
  // const [country, setCountryList] = useState({
  //   value: "",
  //   label: "Select Country",
  // });
  const [country, setCountry] = useState({
    value: "",
    label: "Select Country",
  });

  useEffect(() => { setCountryDetail() }, []) // eslint-disable-line
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;



//  const  PopulateOwnCountry =()=>{
//   setCountry(
//     Countries.filter((a) => a.id == Details.is_patient.countryID).map((b) => ({
//       value: b.id,
//       label: b.country_name,
//     }))
//   );
//   // setCountryName(
//   //   Countries.filter((a) => a.country_isd_code === Details.countryCode).map(
//   //     (b) => b.country_name
//   //   )[0]
//   // );
//   setCountryName(
//     Countries.filter((a) => a.id == Details.is_patient.countryID).map((b) => b.country_name)[0]
//   );
//  }
 
  

  // function setCountryDetail(countryCode) {
  //   if (countryCode == "" || countryCode == undefined) { // eslint-disable-line
  //     countryCode = "";
  //   }
  //   setCountryCode({ value: countryCode, label: countryCode });
  //   if (countryMap.length > 0) {
  //     countryMap
  //       .filter((key) => key.country_isd_code == countryCode) // eslint-disable-line
  //       .map((filterCountry) => setCountryMap(filterCountry));
  //   } else {
  //     axios
  //       .get("/common/country-list")
  //       .then(function (response) {
  //         if (response.status === 200) {
  //           setCountry(response.data.data);
  //         }
  //       })
  //       .catch(function (error) { });
  //   }
  // }

  // function setCountryMap(country) { // eslint-disable-line
  //   setCountryList({
  //     id: country.id,
  //     value: country.country_name,
  //     label: country.country_name,
  //   });
  //   setCountryCode({
  //     value: country.country_isd_code,
  //     label: country.country_isd_code,
  //   });
  //   setCountryName(country.country_name);
  //   setCountryID(country.id);
  // }

  function setCountryDetail(country) {
    
    setCountryCode({ value: country, label: country });
    if (countryMap.length > 0) {
      countryMap
        .filter((key) => key.country_name === country) 
        .map((filterCountry) => setCountryMap(filterCountry));
    } else {
      axios
        .get("/common/country-list")
        .then(function (response) {
          if (response.status === 200) {
            setCountryList(response.data.data);
            let countryList = response.data.data;
            countryList
              .filter((key) => key.country_name === country) 
              .map((filterCountry) => setCountryMap(filterCountry));
          }
        })
        .catch(function (error) { });
    }
  }

  // useEffect(()=> {
  //   setStates([])
  //   setUserState("")
  //   if(countryID){
  //     // let country = countryID ? countryID: country?.id;
  //     axios.get(`common/state-list/${countryID}`)
  //   .then(
  //     response => {
  //       const StatesList = response.data.data.map((item)=> {
  //         return { value: item.shortCode, label: item.stateName }
  //       });
  //       setStates(StatesList)
  //     })
  //     .catch(error => {
  //       console.log("Error Loading States List",error);
  //     });
  //   }
  // },[countryID])

  useEffect(()=> {
    setStates([])
    // setUserState("")
      console.log("Inside State List Fetch")
      axios.get(`common/state-list/${CountryID}`)
    .then(
      response => {
        const StatesList = response.data.data.map((item)=> {
          if(CountryID === '223')
            return { value: item.shortCode, label: item.stateName }
          else if(CountryID === '103')
            return { value: item.countyCode, label: item.countyName }
        });
        setStates(StatesList)
      })
      .catch(error => {
        console.log("Error Loading States List",error);
      });
  },[CountryID])

  function setCountryMap(country) {
    // country.id === '223' ? setZipText('Zip Code') : setZipText('Post Code');
    setCountry({
      value: country.country_name,
      label: country.country_name,
    });
    // setCurrency({
    //   value: country.country_currency,
    //   label: country.country_currency,
    // });
    setCountryCode({
      value: country.country_isd_code,
      label: country.country_isd_code,
    });
    setCountryName(country.country_name);
    setCountryID(country.id);
  }

  const onChangeList = (event, errors, values) => {
    // event.id==='223' ? setZipText('Zip Code') : setZipText('Post Code');
    if (countryMap.length > 0) {
      countryMap
        .filter((key) => key.country_name == event.value) // eslint-disable-line
        .map((filterCountry) => {setCountryMap(filterCountry);console.log(filterCountry)});
    }
  };

  const FetchTitles = () => {
    axios
      .get(`${API_ENDPOINT}/user/titles/relation`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setTitles(response.data.data);
      })
      .catch(function (error) { });
  };
  const TitleList = Titles.map((key) => {
    return { value: key, label: key };
  });

  // console.log(TitleList);

  const getUserDetails = () => {
    axios
      .get(`${API_ENDPOINT}/user/details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setDetails(response.data.data[0]);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const submitDependentForm = () => {
      // const formToSubmit = {
  
        
      //   fname: FirstName,
      //   lname: LastName,
      //   mname: MiddleName,
      //   gender: gender,
      //   dob: moment(dob).format('YYYY-MM-DD'),
      //   relation: Title.value,
      //   email: email,
      //   country: CountryName,
      //   countryID: countryID,
      //   countryCode: countryCode.value,
      //   address: NewAddress,
      //   state: UserState,
      //   city: City,
      //   zipcode: ZipCode,
      //   mobile: ContactNumber,
      //   isDependent: 1,
      //   userImge: Photo
      // };

      const bodyFormData = new FormData();

    bodyFormData.set("fname", FirstName);
    bodyFormData.set("mname", MiddleName);
    bodyFormData.set("lname", LastName);
    bodyFormData.set("gender", gender);
    bodyFormData.set("dob", moment(dob).format('YYYY-MM-DD'));
    bodyFormData.set("relation", Title.value);
    bodyFormData.set("email", email);
    bodyFormData.set("country", CountryName);
    bodyFormData.set("countryID", CountryID);
    bodyFormData.set("countryCode", countryCode.value);
    bodyFormData.set("address", NewAddress);
    bodyFormData.set("state",UserState);
    bodyFormData.set("city", City);
    bodyFormData.set("zipcode", ZipCode);
    bodyFormData.set("mobile", ContactNumber);
    bodyFormData.set("isDependent", 1);
    if(Photo)
    bodyFormData.append("userImge", Photo);
   



  
      if (props.dependent_id) {
        axios.post(
          API_ENDPOINT + "/patient/dependent/update/"+props.dependent_id,
          bodyFormData
        )
          .then((res) => {
            if (res.status === 200) {
              toastr.success("Dependent Updated", "Dependent updated successfully");
              props.toggleModal();
              props.TableRenderPhysicians();
              // window.location.reload();
              getUserDetails();
            }
          })
          .catch((error) => {console.log(error.response);
            toastr.error("Update Unsuccessful", "");
          });
      } else {
        axios.post(API_ENDPOINT + "/patient/new-dependent", bodyFormData)
          .then((res) => {
            if (res.status === 201) {
              toastr.success("Dependent", "Dependent added successfully");
              props.toggleModal();
              props.TableRenderPhysicians();
              getUserDetails();
              // window.location.reload();
            }
          })
          .catch((error) => {console.log(error);
            if(error.response.status === 406)
            {
              toastr.error(error.response.data.data.validation);
            }
              // toastr.error("Dependent Exists");
          });
      }
    };

  let SubmitButton = "";

  if (
    FirstName === "" ||
    LastName === "" ||
    gender === "" ||
    dob === "" ||
    email === "" ||
    Title.value === "" ||
    ContactNumber === "" ||
    CountryName === "" ||
    NewAddress === ""||
    // UserState === ""||
    City === ""
  ) {
    SubmitButton = (
      <Button color="primary" disabled>
        Save
      </Button>
    );
  } else {
    SubmitButton = (
      <Button color="primary" onClick={() => submitDependentForm()}>
        Save
      </Button>
    );
  }

  const onChangeGeolocation = (event) => {
    if (event.length > 500) {
      toastr.warning(
        "Limit Exceeded",
        "Address should not be greater than 500 Characters"
      );
    } else {
      setNewAddress(event);
      setAddress(event);
    }
  };
  const onSelectGeolocation = (event) => {
    setTimeout(() => {
      if (event.administrative_area_level_1 === "long_name") {
        // setState("");
        setUserState("")
      } else {
        setUserState(event.stateFullDetail.short_name)
        // setState(event.administrative_area_level_1);
      }
      // if (event.administrative_area_level_1 === "long_name") {
      //   setState(State);
      // } else {
      //   setState(event.administrative_area_level_1);
      // }
      if (event.locality === "long_name" && event.administrative_area_level_2 === "long_name") {
        setCity(City);
      } else {
        if (event.locality !== "long_name"){
          setCity(event.locality);
       } else if(event.administrative_area_level_2 !== "long_name"){
        setCity(event.administrative_area_level_2);
       } else {
        setCity("");
       } 
      }

      if (event.postal_code === "long_name") {
        setZipCode("");
      } else {
        setZipCode(event.postal_code);
      }
    }, 500);
  };

  const CountryHandler = (event) => {
    setCountryName(event.label);
    setCountry(event);
  };


  // const getSelectedCountry = (countryname) => {console.log(countryname);
  //   // setCountry(
  //   //   Countries.filter((a) => a.country_name === countryname).map((b) => ({
  //   //     value: b.id,
  //   //     label: b.country_name,
  //   //   }))
  //   // );
  //   setCountryList({value:countryname, label: countryname})
  //   setCountryName(
  //     Countries.filter((a) => a.country_name === countryname).map(
  //       (b) => b.country_name
  //     )[0]
  //   );
  // };


  const PopulateFields = () => {
    axios.get(`${API_ENDPOINT}/patient/dependent/view/` + props.dependent_id)
      .then(function (response) {
        let patient = response.data.data;
        // console.log(patient);
        // setPhysicianName(response.data.data.name);
        // getSelectedPhysicianCareType(response.data.data.careTypeId);
        //const dob = response.data.data.dob; // moment(response.data.data.dob).format('DD-MMM-YYYY');

        //     const DOB = response.data.data
        // ? moment(response.data.data.dob).format(
        //     MomentConfig.MOMENT_FORMAT_DATE
        //   )
        // : "";
        // moment(response.data.data.dob).format('YYYY-MM-DD')
        // console.log(URL.createObjectURL(patient.profileImageURL));
        setPicPreview(patient.profileImageURL);
        setFirstName(patient.first);
        setMiddleName(patient.middle);
        setLastName(patient.last);
        setGender(patient.gender);
        setDOB(patient.dob);
        setEmail(patient.email);
        setTitle({value:patient.relation, label:patient.relation});
        setTitleValue(response.data.data.relation);
        // setCountryList({value:response.data.data.country, label:response.data.data.country});
        // setCountryName(response.data.data.country);
        setCountryDetail(patient.country);
        setCountryID(patient.countryID);
        setCountryCode({ value: patient.countryCode, label: patient.countryCode });
        setAddress(patient.address);
        setNewAddress(patient.address);
        setUserState(patient.stateID);
        setCity(patient.city);
        setZipCode(patient.zipcode);
        setContactNumber(patient.mobliePhone);
        // getSelectedCountry(patient.country);
        
          
        
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  // const dateChange = (date) => {
  //   // return typeof(date);
  // }

  useEffect(() => {
    // fetchCountries();
    FetchTitles();
  }, []); // eslint-disable-line

  // useEffect(() => {
  //   if(Details.countryCode && Countries &&Countries.length)
  //   PopulateOwnCountry();
  // }, [Details.countryCode,Countries]); // eslint-disable-line

  useEffect(() => {
    if (props.dependent_id && countryMap) {
      PopulateFields();
    }
  }, [props.dependent_id, countryMap]); // eslint-disable-line

  const handlePhoto = (photo) => {
    if (photo.size <= (fileSize * 1048576)) {
      setPhoto(photo);
      setPicPreview(URL.createObjectURL(photo));
    } else {
      toastr.error(
        "Please use a smaller picture",
        `The profile picture size cannot exceed ${fileSize} MB`
      );
    }
  };

//   useEffect(()=>{
// if(props.PhysicianCareTypes){
//  setPhysicianCareTypes(props.PhysicianCareTypes)
// }
//   },[props.PhysicianCareTypes])

  return (
    <div className="physician_info">
      {/* <Row>
        <Col md="12">
          <div className="ModalHeader">
            {props.job === "edit" ? <h3>Edit Physician</h3> : <h3>Add Physician</h3>}
            
          </div>
        </Col>
      </Row> */}
      <Row className="profile-picture">
              <Col md="12">
                <div className="text-center">
                  <img
                    alt="Profile pic"
                    src={PicPreview ? PicPreview : avatar}
                    className="rounded-circle img-responsive mt-2"
                    width="128"
                    height="128"
                  />
                  <div className="mt-2">
                    <Label className="custom-file-upload">
                      <FontAwesomeIcon icon={faUpload} />
                      {"\u00A0"}
                      {"\u00A0"}Upload
                      <Input
                        type="file"
                        onChange={(event) => {
                          handlePhoto(event.target.files[0]);
                        }}
                      ></Input>
                    </Label>
                  </div>
                  <small>{t("imgdesc")}</small>
                </div>
              </Col>
            </Row>
      <Row>
        {/* <Col md="6">
          <label>
            Care Type <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
          <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={PhysicianCareTypesList}
              onChange={(event) => PhysicianCareTypeHandler(event)}
               value={PhysicianCareType}
              isSearchable
              placeholder="Select "
            />
          </FormGroup>
        </Col> */}
        
        <Col md="4">
          <label>
            First Name <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.FirstName(e.target.value,setFirstName,120)}
              type="text"
              name="firstname"
              placeholder="First Name"
              value={FirstName}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <label>
            Middle Name 
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.MiddleName(e.target.value,setMiddleName,120)}
              type="text"
              name="middlename"
              placeholder="Middle Name"
              value={MiddleName}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <label>
           Last Name <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.LastName(e.target.value,setLastName,120)}
              type="text"
              name="lastname"
              placeholder="Last Name"
              value={LastName}
            />
          </FormGroup>
        </Col>

        <Col md="8">
                        <FormGroup>
                          <Label for="exampleCheckbox">{t("gender")}{" "}<span className="mandatory-marker">*</span></Label>
                          <div>
                            <CustomInput
                              type="radio"
                              id="Male"
                              name="customRadio"
                              label={t("male")}
                              className="mb-2 gender-flex"
                              value="Male"
                              checked={gender === "Male"}
                              onClick={() => setGender("Male")}
                              inline
                            />
                            <CustomInput
                              type="radio"
                              id="Female"
                              name="customRadio"
                              label={t("female")}
                              value="Female"
                              checked={gender === "Female"}
                              onClick={() => {
                                setGender("Female");
                              }}
                              className="mb-2 gender-flex"
                              inline
                            />
                            <CustomInput
                              type="radio"
                              id="Prefer not to answer"
                              name="customRadio"
                              label={t("pfrNotAns")}
                              value="other"
                              checked={gender === "Other"}
                              onClick={() => setGender("Other")}
                              className="mb-2 gender-flex"
                              inline
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <Label for="exampleDate2">{t("dobfield")}{" "}<span className="mandatory-marker">*</span></Label>
                        
                        <div class="form-group">
                        {dob ?
                          <div className="sign-up-dob-input">
                            
                            <DatePicker
                              dateFormat="dd MMM YYY"
                              onChange={(date) => { setDOB(date) }}
                              placeholderText="DD MM YYYY"
                              selected={moment(dob).toDate()}
                              showYearDropdown
                              maxDate={new Date()}
                              // maxDate={moment().subtract('18', 'years').toDate()}
                            />
                          </div> : 
                          <div className="sign-up-dob-input">
                            
                          <DatePicker
                            dateFormat="dd MMM YYY"
                            onChange={(date) => { setDOB(date) }}
                            placeholderText="DD MM YYYY"
                            selected={dob}
                            showYearDropdown
                            // maxDate={moment().subtract('18', 'years').toDate()}
                            maxDate={moment().toDate()}
                          />
                        </div>  }
                        </div>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label>Relation {" "} <span className="mandatory-marker">*</span></Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={TitleList}
                            onChange={(event) => {
                              setTitleValue(event.value);
                              setTitle({value:event.value, label:event.value});
                            }}
                            value={Title}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        <Label>{t('email')}{" "} <span className="mandatory-marker">*</span></Label>
                        <Input
                          bsSize="lg"
                          name="email"
                          placeholder={t('enterEmailPH')}
                          {...bindEmail}
                          type="email"
                          disabled={props.dependent_id}
                          validate={{ email: true ,maxLength: {value: 254}}}
                          errorMessage={t('emailErrorMessage')}
                          required
                        />
                      </Col>
        {/* <Col md="4">
          <label>
            Country <span className="mandatory-marker">*</span>
          </label>{" "}
          
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={CountryList}
              onChange={(event) => CountryHandler(event)}
              value={Country}
              isSearchable
              placeholder="Select "
            />
          </FormGroup>
        </Col> */}
        <Col md="4">
                        <FormGroup>
                          <Label>{t("country")}{" "} <span className="mandatory-marker">*</span></Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={countryList}
                            onChange={onChangeList}
                            value={country}
                            required
                          />
                        </FormGroup>
                      </Col>
        <Col md="4">
                        <FormGroup>
                          <Label>{t("countryCode")}{" "}</Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={countryCode}
                            isDisabled
                            options={countryCodeList}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
          <label>Mobile Number</label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.ContactNumber(e.target.value,setContactNumber,12)}
              type="text"
              name="contactnumber"
              placeholder="Mobile Number"
              value={ContactNumber}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
          <Tooltip
                  title={Address ? NewAddress : ""}
                  placement="right"
                  arrow
                >
            <label>
              Address <span className="mandatory-marker">*</span>
            </label>{" "}
            <GeoLocation
              required
              preSelectedValue={Address}
              showAllFields={false}
              isStateVisible={false}
              isCityVisible={false}
              isPinCodeVisible={false}
              addressLabelText={""}
              pincodeLabelText={"Post code"}
              onChange={onChangeGeolocation}
              onSelect={onSelectGeolocation}
            />
            </Tooltip>
          </FormGroup>
        </Col>
        {/* <Col md="6">
          <label>
            State/Province/County <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.State(e.target.value,setState,24)}
              type="text"
              name="state"
              placeholder="State"
              value={State}
            />
          </FormGroup>
        </Col> */}
        <Col lg="6" md="6">
                          <Label>{t("State")}{" "} <span className="mandatory-marker">*</span></Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={states}
                            placeholder="Select State"
                            onChange={(event)=> {
                              setUserState(event.value)
                            }}
                            value={states.filter(state => state.value === UserState)}
                            required
                          />
                      </Col>
        <Col md="6">
          <label>
            City <span className="mandatory-marker">*</span>
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.City(e.target.value,setCity,24)}
              type="text"
              name="city"
              placeholder="City"
              value={City}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <label>
            Post Code 
          </label>
          <FormGroup>
            <Input
              required
              onChange={(e) =>  Validation.ZipCode(e.target.value,setZipCode,10)}
              type="text"
              name="postcode"
              placeholder="Post Code"
              value={ZipCode}
            />
          </FormGroup>
        </Col>
        
      </Row>
      <Row className="row" style={{'justify-content': 'center'}}>
        {SubmitButton}
        <Button color="outline-primary ml-2" onClick={props.toggleModal}>
          Cancel
        </Button>
      </Row>
    </div>
  );
};

export default DependentsInput;
