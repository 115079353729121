import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Button, Col,Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";



const MedicationTable = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const[PharmacyDetails,setPharmacyDetails]=useState([])

  const FetchPharmacyDetails = () => {
    Axios.get(
      `${API_ENDPOINT}/common/pharmacy/view/${props.MedicationsList[0].pharmacyID}`
    )
      .then((res) => {
        if (res.status === 200) {
          setPharmacyDetails(res.data.data);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };


  const TableActions = (cell, row) => {
    return (
      <>
        <Button className="outline-primary lh-10 mr-2">
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-2"
            size="xs"
          />
          </Button>
        
      </>
    );
  };



  const defaultSorted = [
    {
      dataField: "drugname",
      order: "asc",
    },
  ];

  const tableColumns = [
    {
      dataField: "drugname",
      text: "Drug"
    },
    {
      dataField: "dosage",
      text: "Dosage",
    },
    { dataField: "id", text: "Actions", formatter: TableActions },
  ];


  useEffect(() => {
    if(props.MedicationsList[0].pharmacyID && props.PatientId)
    FetchPharmacyDetails();
  }, [props.MedicationsList[0].pharmacyID,props.PatientId]) // eslint-disable-line


  const expandRow = {
    renderer: (row) => (
      <div className="MedicationsTableExpands">
        <span>{`Frequency :  ${row.frequency}`}</span>
        <span>{`Route :  ${row.route}`}</span>
        <span className="prescription_notes">{ row.comment ? `Notes : ${row.comment}` :  `Notes : ` }</span>
      </div>
    ),
  };

  return (
    <>
    {PharmacyDetails.id ? (
        <Row className="pharmacy_details mb-3">
          <Col md="9">
          Pharmacy : {PharmacyDetails.status && PharmacyDetails.status === "1" ? PharmacyDetails.name : <strong> {PharmacyDetails.name} (Deactivated) </strong>}  
            <br></br> Address : {PharmacyDetails.address ? PharmacyDetails.address : "N/A"}
            <br></br> Contact Number : {PharmacyDetails.contactNumber ? PharmacyDetails.contactNumber : "N/A"}
          </Col>
        </Row>
      ) : null}
    <div className="PrescriptionMedicationTable ">
      <Card>
        <CardBody className="health-card">
            <BootstrapTable
              keyField="id"
              data={props.MedicationsList}
              columns={tableColumns}
              expandRow={expandRow}
              bordered={false}
              bootstrap4
              defaultSorted={defaultSorted}
            />
        </CardBody>
      </Card>
    </div>
    </>
  );
};

export default MedicationTable;
