import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import './VideoConsultation.css';
import {
    Card,
    CardHeader,
    Col,
    Container,
    Row,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    CardBody
} from "reactstrap";

import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import OpenTok from '../OpenTok/OpenTok';
import { GlobalState } from '../../../context/ContextApi';
import Timer from 'react-compound-timer';
import AttachmentTab from './AttachmentTab';
import ConsultTab from './ConsultTab';


const Dashboard = ({ sidebar, children }) => {

    return (
        <React.Fragment>
            <PageLayout>
                <Cards />
            </PageLayout>
        </React.Fragment>
    )
}

const Cards = () => {
    // GLOBAL CONTEXT
    const { physicianTitle, physicianName, appointmentEndTime, appointmentTime, timer, Details, appointmentInfo } = useContext(GlobalState)

    // LOCAL VARIABLES 
    const [activeTab, setActiveTab] = useState('1')

    // OBTAINING BASE URL BASED ON ENVIRONMENT
    let baseURL = null // eslint-disable-line
    if (process.env.NODE_ENV === "development") baseURL = process.env.REACT_APP_ALPHA_API
    if (process.env.NODE_ENV === "production") baseURL = process.env.REACT_APP_PRODUCTION_API


    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Container fluid>
            <Header>
                <Row>
                    <Col md="9">
                        <HeaderTitle className="video-call-heading">
                            <span className="video-physician-name">
                                {physicianTitle && physicianName ? `${physicianTitle}${physicianName}` : 'Video-Consultation'}
                            </span>
                            <span>{physicianName ? `${moment(appointmentTime).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} to ${appointmentEndTime}` : ''}</span>
                        </HeaderTitle>
                    </Col>
                    <Col md="3">
                        {timer && (<div className="call-timer">
                        <FontAwesomeIcon icon={faClock} className="video-timer-icon" />
                            <Timer>
                                <Timer.Hours /> <b className="timer-separator">:</b>
                                <Timer.Minutes /> <b className="timer-separator">:</b>
                                <Timer.Seconds />
                            </Timer>
                        </div>)}
                    </Col>
                </Row>
            </Header>

            <Row>
                <Col>
                    <Card className="video-card-height">
                        <CardBody>
                            {/* <Row>
                                <Col xs="12" class="pull-right">
                                {timer && (<div className="call-timer">
                                <FontAwesomeIcon icon={faClock} className="video-timer-icon" />
                                <Timer>
                                <Timer.Hours /> <b className="timer-separator">:</b>
                                <Timer.Minutes /> <b className="timer-separator">:</b>
                                <Timer.Seconds />
                            </Timer>
                        </div>)}
                                </Col>
                            </Row> */}
                            <OpenTok />

                        </CardBody>

                    </Card>
                </Col>
                <Col xl="4">
                    <Card className="video-card-height">
                        <CardHeader>
                            <div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            Consult
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            Attachments
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1" >
                                        <Row>
                                            <Col className="margin-top">
                                                <div className="file-attachment-card-container disable-scrollbars">

                                                    <ConsultTab
                                                        physicianDetails={appointmentInfo.physicianDetails}
                                                        reasonForConsultation={appointmentInfo.chiefComplaint}
                                                        specialityText={appointmentInfo.appointmentDetails}
                                                        appointmentDetails={appointmentInfo.appointmentDetails}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col className="margin-top">
                                                <div className="file-attachment-card-container disable-scrollbars">

                                                    <AttachmentTab
                                                    parent="videoConsult"
                                                    userId={Details.id} 
                                                    reloadDocumentList={false}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default connect(store => ({
    sidebar: store.sidebar
}))(Dashboard);