import React from "react";
import { Link } from "react-router-dom";
import "./Documents.css";
import Identification from "./Identification/Identification";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import SubNav from "../SubNav";
import { useTranslation } from "react-i18next";

const IdentificationDocuments = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageLayout>
        <Header>
          <HeaderTitle>{t("myAccount")}</HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/settings/identification">{t("myAccount")}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Identification</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Row>
          <Col md="3" xl="2">
            <SubNav />
          </Col>
          <Col md="9" xl="10">
              <Card>
              <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Identification
          </CardTitle>
        </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Identification />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          </Col>
        </Row>
      </PageLayout>
    </>
  );
};
export default IdentificationDocuments;
