/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Collapse,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import { useTranslation } from "react-i18next";
import PharmaciesTable from "./Pharmacies/PharmaciesTable";
import PharmaciesModal from "./Pharmacies/PharmaciesModal";
import PhysiciansTable from "./Physicians/PhysiciansTable";
import PhysiciansModal from "./Physicians/PhysiciansModal";
import Axios from "axios";
import { GlobalState } from '../../../src/context/ContextApi';
import "./CareTeam.css";
import { cookieService } from '../../services/cookieService';

const CareInfo = () => {
  const [isOpenPharmacies, setIsOpenPharmacies] = useState(true);
  const togglePharmacies = () => setIsOpenPharmacies(!isOpenPharmacies);

  const [isOpenPhysicians, setIsOpenPhysicians] = useState(true);
  const togglePhysicians = () => setIsOpenPhysicians(!isOpenPhysicians);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [PharmaciesList, setPharmaciesList] = useState([]);
  const [PharmaciesNoDataFound, setPharmaciesNoDataFound] = useState(false);
  const [PharmaciesLoading, setPharmaciesLoading] = useState(true);


  const [PhysiciansList, setPhysiciansList] = useState([]);
  const [PhysiciansNoDataFound, setPhysiciansNoDataFound] = useState(false);
  const [PhysiciansLoading, setPhysiciansLoading] = useState(true);

  const[PhysicianCareTypes,setPhysicianCareTypes]=useState([])
  const { completeProfileChanged, setCompleteProfileChanged } = useContext(GlobalState);

  // let dependentID = cookieService.get('dependentID');

  const fetchPharmacies = () => {
    window.showIncompleteProfileModal = true;
    const data = {
      // patientId: dependentID
    };
    Axios.post(API_ENDPOINT + "/patient/pharmacy/list", data)
      .then((res) => {
        setPharmaciesList(res.data.data);
        if (res.data.data.length === 0) {
          setPharmaciesNoDataFound(true);
        } else {
          setPharmaciesNoDataFound(false);
        }
        setPharmaciesLoading(false);
        if(res.data.title != "No data found"){ // eslint-disable-line
          setCompleteProfileChanged(!completeProfileChanged)
        }
      })
      .catch((error) => {
        setPharmaciesNoDataFound(true);
        setPharmaciesLoading(false);
      });
  };


  const fetchPhysicians = () => {
    // let url = "/patient/careteam/list";
    // if(dependentID)
    // {
    //   url = "/patient/careteam/list" + `/${dependentID}`
    // }
    Axios.post(API_ENDPOINT + "/patient/careteam/list")
      .then((res) => {
        setPhysiciansList(res.data.data);
        if (res.data.data.length === 0) {
          setPhysiciansNoDataFound(true);
        } else {
          setPhysiciansNoDataFound(false);
        }
        setPhysiciansLoading(false);
      })
      .catch((error) => {
        setPhysiciansNoDataFound(true);
        setPhysiciansLoading(false);
      });
  };

  

  const FetchPhysicianCareTypes = ()=>{


    Axios.get(`${API_ENDPOINT}/patient/careteam/caretype/list`)
      .then(function (response) {
        setPhysicianCareTypes(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }


  useEffect(() => {
    FetchPhysicianCareTypes();
  }, []); // eslint-disable-line


  const { t } = useTranslation();

  return (
    <div className="CareInfo">
      <Card>
        <CardBody className="card-body">
        <div className="care-info-collapse">
            <div
              color="primary"
              role="button"
              className="care-info-header-container"
            >
              <FontAwesomeIcon
                onClick={togglePharmacies}
                icon={isOpenPhysicians ? faAngleDown : faAngleRight}
                className="align-middle"
              />
              <h2 className="care-info-header" onClick={togglePhysicians}>
                {t("Physicians")}
              </h2>
              <PhysiciansModal PhysicianCareTypes={PhysicianCareTypes} TableRenderPhysicians={fetchPhysicians} />
            </div>
            <Collapse isOpen={isOpenPhysicians}>
              {
                <PhysiciansTable
                  data={PhysiciansList}
                  PhysiciansNoDataFound={PhysiciansNoDataFound}
                  PhysiciansLoading={PhysiciansLoading}
                  TableRenderPhysicians={fetchPhysicians}
                  PhysicianCareTypes={PhysicianCareTypes}
                />
              }
            </Collapse>
          </div>
          <div className="care-info-collapse">
            <div
              color="primary"
              role="button"
              className="care-info-header-container"
            >
              <FontAwesomeIcon
                onClick={togglePharmacies}
                icon={isOpenPharmacies ? faAngleDown : faAngleRight}
                className="align-middle"
              />
              <h2 className="care-info-header" onClick={togglePharmacies}>
                {t("Pharmacies")}
              </h2>
              <PharmaciesModal TableRenderPharmacies={fetchPharmacies} />
            </div>
            <Collapse isOpen={isOpenPharmacies}>
              {
                <PharmaciesTable
                  data={PharmaciesList}
                  PharmaciesNoDataFound={PharmaciesNoDataFound}
                  PharmaciesLoading={PharmaciesLoading}
                  TableRenderPharmacies={fetchPharmacies}
                />
              }
            </Collapse>
          </div>
          
        </CardBody>
      </Card>
    </div>
  );
};

const CareTeam = () => {

  return (
    <PageLayout>
      <Header>
        <HeaderTitle>Care Team</HeaderTitle>
      </Header>

      <Row>
        <Col md="12">
          <CareInfo />
        </Col>
      </Row>
    </PageLayout>
  );
};
export default CareTeam;
