import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import { Card, Row, Col, Spinner } from 'reactstrap'
import { useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage, faFilePdf, faFileAlt, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import moment from 'moment'
import MomentConfig from '../../../common/MomentConfig'
import DocumentViewer from './DocumentViewer/DocumentViewer'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const AttachmentTab = (props) => {
    const { userId, reloadDocumentList, parent } = props

    // LOCAL VARIABLES
    const [appointmentId, setAppointmentId] = useState("")
    const [modalState, setModalState] = useState(false)
    const [fileURL, setFileURL] = useState("")
    const [fileExtension, setFileExtension] = useState("")
    const [fileType, setFileType] = useState("")
    const [physicianName, setPhysicianName] = useState("")
    const [documentId, setDocumentId] = useState("")
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [deleteApproved, setDeleteApproved] = useState(false)
    const [fileName, setFileName] = useState("")
    const [spinner, setSpinner] = useState(true)
    const [documentList, setDocumentList] = useState("")

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const getAppointmentIDFromURL = () => {
        let aid = query.get("aid")
        if (aid) setAppointmentId(atob(aid))
    }

    const getUploadedDocuments = () => {
        setSpinner(true)
        axios.post('/patient/appointment/document/all', {
            appointmentId: appointmentId
        })
            .then(function (response) {
                // console.log(response)
                setSpinner(false)
                setDocumentList(response.data.data)
            })
            .catch(function (error) {
                // console.log(error)
                setSpinner(false)
                toastr.error(error.response.data.title, error.response.data.data.validation)
            });
    }

    const deleteDocument = () => {
        axios.get(`/patient/appointment/document/delete/${documentId}`)
            .then(function (response) {
                // console.log(response)
                setDocumentList(response.data.data)
                setDeleteModalState(false)
                setDeleteApproved(false)
                toastr.success("Document Deleted Successfully")
            })
            .catch(function (error) {
                // console.log(error)
                toastr.error(error.response.data.title)
            });
    }

    const getIcon = (extension) => {
        let icon = ""
        switch (extension) {
            case "gif":
                icon = faImage
                break;
            case "png":
                icon = faImage
                break;
            case "jpg":
                icon = faImage
                break;
            case "jpeg":
                icon = faImage
                break;
            case "pdf":
                icon = faFilePdf
                break;
            default:
                icon = faFileAlt
        }
        return icon
    }

    const passInfoToDocumentViewer = (item) => {
        setFileURL(item.fileURL)
        setFileExtension(item.actualFileName.toLowerCase().split('.').pop())
        setFileType("upload")
        setPhysicianName(item.physician_name)
        setModalState(true)
    }

    const noAttachmentCard = (
        <Card className="no-attachment-card">
            <h4>No Attachment Found</h4>
        </Card>
    )

    const attachmentCard = documentList
        ? documentList.map(
            (item) => {
                // CHECK FOR DELETED FILE
                if (item.isDeleted !== "0") return false

                // GET FILE EXTENSION
                const extension = item.actualFileName.toLowerCase().split('.').pop()
                const icon = getIcon(extension)

                return (
                    <Card className="file-attachment-card">
                        <Row>
                            <Col>
                                <p
                                    title={item.actualFileName}
                                >
                                    <span><FontAwesomeIcon icon={icon} /></span> {" "}
                                    {item.actualFileName}
                                </p>
                            </Col>
                            <Col xs="1">
                                <FontAwesomeIcon
                                    className="cursor-pointer"
                                    icon={faEye}
                                    onClick={() => {
                                        passInfoToDocumentViewer(item);
                                        setFileName(item.actualFileName);
                                    }}
                                    title="View File"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p
                                    title={`By: ${item.createdByUserName} On: ${moment.utc(item.createdDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}`}
                                >
                                {/* <p
                                    title={`By: ${item.createdByUserName} On1: ${moment(item.createdDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}`}
                                > */}
                                
                                    <strong>By: </strong>
                                    {item.createdByUserName} {" "}
                                    <strong>On: </strong>
                                    {moment.utc(item.createdDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}
                                    {/* {moment(item.createdDate).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} */}
                                </p>
                            </Col>
                            {item.createdBy === userId && parent !== "videoConsult"
                                ? <Col xs="1">
                                    <div className="attachment-tab-delete-icon">
                                    <FontAwesomeIcon
                                        className="cursor-pointer"
                                        icon={faTrashAlt}
                                        color="#fe5d69"
                                        onClick={() => {
                                            setDeleteModalState(true);
                                            setDocumentId(item.id);
                                            setFileName(item.actualFileName);
                                        }}
                                        title="Delete File"
                                        />
                                        </div>
                                </Col>
                                : ""
                            }
                        </Row>
                    </Card>
                )
            }
        )
        : ""


    useEffect(() => { if (appointmentId) getUploadedDocuments() }, [appointmentId, reloadDocumentList]) // eslint-disable-line
    useEffect(() => { getAppointmentIDFromURL() }, []) // eslint-disable-line
    useEffect(() => { if (deleteApproved) deleteDocument() }, [deleteApproved]) // eslint-disable-line

    return (
        <div>
            {!spinner && documentList.length > 0 ? attachmentCard : ""}
            {!spinner && documentList.length === 0 ? noAttachmentCard : ""}
            {spinner ?
                <div className="manage-file-modal-spinner">
                    <Spinner color="primary" />
                </div>
                : ""}
            
            <DocumentViewer
                modalState={modalState}
                setModalState={setModalState}
                fileURL={fileURL}
                fileExtension={fileExtension}
                fileType={fileType}
                physicianName={physicianName}
                setFileURL={setFileURL}
                fileName={fileName}
            />

            <DeleteConfirmationModal
                deleteModalState={deleteModalState}
                setDeleteModalState={setDeleteModalState}
                setDeleteApproved={setDeleteApproved}
                fileName={fileName}
            />
        </div>
    )
}

export default AttachmentTab
