import React from "react";
import { useLocation, Link } from 'react-router-dom';

import {
  Card,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import { cookieService } from '../../services/cookieService';

const SubNav = () => {
  let location = useLocation();
  let getPathName;
  switch (location.pathname) {
    case '/settings/account':
      getPathName = 'Acc';
      break;
    case '/settings/insurance':
      getPathName = 'Ins';
      break;
    case '/settings/language':
      getPathName = 'Lan';
      break;
    case '/settings/security':
      getPathName = 'Sec';
      break;
    case '/settings/notification':
      getPathName = 'Noti';
      break;
    case '/settings/identification':
    getPathName = 'Ide';
    break;
    case '/settings/main-account':
    getPathName = 'Macc';
    break;
    default:
      getPathName = 'Pp'
      break;
  }
  const { t } = useTranslation();
  let dependentID = cookieService.get('dependentID');

  return (
    <Card>
      {/* <CardHeader>
        <CardTitle tag="h5" className="mb-0">
          {t('profileSettings')}
        </CardTitle>
      </CardHeader> */}
      <ListGroup flush >
        <Link to="/settings/profile" className="sub-nav-item-padding">
          <ListGroupItem action active={getPathName === 'Pp' ? true : false} >
            {t('personalProfile')}
          </ListGroupItem>
        </Link>
        <Link to="/settings/identification" className="sub-nav-item-padding">
          <ListGroupItem action active={getPathName === 'Ide' ? true : false}>
            Identification
          </ListGroupItem>
        </Link>
        <Link to="/settings/account" className="sub-nav-item-padding">
          <ListGroupItem action active={getPathName === 'Acc' ? true : false}>
            {t('account')}
          </ListGroupItem>
        </Link>
        <Link to="/settings/insurance" className="sub-nav-item-padding">
          <ListGroupItem action active={!!(getPathName === 'Ins')}>
            Insurance Details
          </ListGroupItem>
        </Link>
        {dependentID > 0 ?
        <Link to="/settings/main-account" className="sub-nav-item-padding">
          <ListGroupItem action active={!!(getPathName === 'Macc')}>
            Main Account
          </ListGroupItem>
        </Link> : null }
        {/* <ListGroupItem tag="a" href="/settings/language" action active={getPathName === 'Lan' ? true : false}>
          Language
        </ListGroupItem>
        <ListGroupItem tag="a" href="/settings/security" action active={getPathName === 'Sec' ? true : false}>
          Security
        </ListGroupItem>
        <ListGroupItem tag="a" href="/settings/notification" action active={getPathName === 'Noti' ? true : false}>
          Notifications
        </ListGroupItem> */}
      </ListGroup>
    </Card>
  );
}


export default SubNav;