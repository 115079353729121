import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import axiosInstance from "../../../axios.js";
import "./Profile.css";
import * as Validation from "./../../../common/FieldValidation"
import DatePicker from "react-datepicker";
import GeoLocation from '../../../components/Geolocation/Geolocation';
import MobileNumberVerificationModal from '../../MobileVerification/MobileNumberVerificationModal';
import { cookieService } from '../../../services/cookieService';


import moment from "moment";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  CustomInput,
  Alert,
} from "reactstrap";

import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import { useInput } from "../../../hooks/input-hook";
import { useDropInput } from "../../../hooks/drop-down-hook";
import SubNav from "../SubNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faUpload } from "@fortawesome/free-solid-svg-icons";

import defaultAvatar from "../../../assets/avatar.png";
import { useTranslation } from "react-i18next";
import { GlobalState } from "./../../../context/ContextApi";

const PersonalInfo = (props) => {
  const { Details, setDetails, isProfileUpdated } = useContext(GlobalState);
  const avatar = Details.profileImageURL
    ? Details.profileImageURL
    : defaultAvatar;

  const {
    value: fname,
    bind: bindFirstName,
    setValue: setFirstName,
  } = useInput("");
  const { value: lname, bind: bindLastName, setValue: setLastName } = useInput(
    ""
  );
  const {
    value: mname,
    bind: bindMiddleName,
    setValue: setMiddleName,
  } = useInput("");
  const [gender, setGender] = useState("");
  const { value: dob, bind: bindDOB, setValue: setDOB } = useInput(""); // eslint-disable-line
  const { value: phoneNumber, bind: bindPhone, setValue: setPhone } = useInput("");
  const [data, setData] = useState([]); // eslint-disable-line
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [responseMessage, setResponseMessage] = useState([]);
  const [responseFields, setResponseFields] = useState({ message: "Error occured", status: false }); // eslint-disable-line
  const [errorStatus, setErrorStatus] = useState(false);
  const [countryMap, setCountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [userState, setUserState] = useState(""); 
  const [autoUserState,setAutoUserState] = useState("");  
  const [Photo, setPhoto] = useState("");
  const [PhotoPreview, setPhotoPreview] = useState("");
  const [CountryName, setCountryName] = useState("");
  const [HomeAutoAddress, setHomeAutoAddress] = useState("");
  const [HomeAddress, setHomeAddress] = useState("");
  const [NewHomeAddress, setNewHomeAddress] = useState("");
  const [HomeAddressTouched, setHomeAddressTouched] = useState(false);
  const [HomeState, setHomeState] = useState("");
  const [HomeAutoState, setHomeAutoState] = useState("");
  const [HomeCity, setHomeCity] = useState("");
  const [HomeAutoCity, setHomeAutoCity] = useState("");
  const [HomeZipCode, setHomeZipCode] = useState("");
  const [HomeAutoZipCode, setHomeAutoZipCode] = useState("");
  const [DisableSaveButton, setDisableSaveButton] = useState(false);
  const fileSize = Number(process.env.REACT_APP_PROFILE_PICTURE_FILE_SIZE);
  const [mobileVerificationModalStatus, setMobileVerificationModalStatus] = useState(false);
  const [CountryID, setCountryID] = useState("");
  // const [mobileVerifiedStatus, setMobileVerifiedStatus] = useState("");

  const [ZipText, setZipText] = useState("Post Code");

  let mobileNumber = Details ? `${Details.phone}`:"";
  let phoneNumberAfterMasking = mobileNumber.replace(/\d(?=\d\d\d\d)/g, "*");
  let mobileNumberAfterMasking = Details ? `${Details.countryCode}${phoneNumberAfterMasking}` : ""

  let dependentID = cookieService.get('dependentID');; //(deptCookie == 0 || deptCookie == undefined)  ?  0 : deptCookie;

  // const disableSaveButton = () => {
  //   console.log("Disable Button invoked",gender)

  //   if ((NewHomeAddress || HomeAutoAddress) && (HomeAutoCity || HomeCity) && (HomeState || HomeAutoState || userState || autoUserState) && (CountryName) && (gender)) {
  //     setDisableSaveButton(false)
  //     console.log("Disable is set to false")
  //   }
  //   else {
  //     setDisableSaveButton(true)
  //     console.log("Disable is set to True")
  //   }
  // }

  const disableSaveButton = () => {

    if ((NewHomeAddress || HomeAutoAddress) && (HomeAutoCity || HomeCity) && (HomeState || HomeAutoState || userState || autoUserState) || (CountryName) || (gender)) {
      setDisableSaveButton(false)
    }
    else {
      setDisableSaveButton(true)
    }
  }

  let countryList = countryMap.map(function (list) {
    return { value: list.country_name, label: list.country_name, id: list.id };
  });
  let countryCodeList = countryMap.map(function (list) {
    return { value: list.country_isd_code, label: list.country_isd_code };
  });
  let countryCurrenyList = countryMap.map(function (list) {
    return { value: list.country_currency, label: list.country_currency };
  });
  const {
    value: countryCode,
    bind: bindCountryCode, // eslint-disable-line
    setValue: setCountryCode,
  } = useDropInput({ value: "", label: "" });
  const {
    value: currency,
    bind: bindCurrency,
    setValue: setCurrency,
  } = useDropInput({ value: "", label: "" });
  const [country, setCountry] = useState({
    value: "",
    label: "Select Country",
  });

  useEffect(() => {
    axios
      .get("/user/details")
      .then(function (response) {
        if (response.status === 200) {
          // console.log(response);
          localStorage.setItem(
            "userDetail",
            JSON.stringify(response.data.data)
          );
          setFieldMapping(response.data.data);
        }
      })
      .catch(function (error) { });
  }, [Details]); // eslint-disable-line

  useEffect(() => {
    disableSaveButton()
  }, [NewHomeAddress, HomeAutoAddress, HomeAutoCity, HomeCity, HomeState, HomeAutoState, CountryName, gender, bindPhone, dob, userState]); // eslint-disable-line

  function setFieldMapping(user) {
    if (user !== null) {
      let userData = user && user[0];
      if(dependentID != 0){
        
        const dependentUser = Details.dependents.filter(usr => usr.id === dependentID);
        userData = dependentUser && dependentUser[0];
        // console.log('depUser', userData);
      }
      if (userData.is_patient) {
        let patient = userData.is_patient;
        setFirstName(patient.first);
        setLastName(patient.last);
        setMiddleName(patient.middle);

        if (userData.address) setHomeAddress(userData.address);
        setHomeAutoAddress(userData.address);
        // console.log(userData.address)
        if (patient.city) setHomeAutoCity(patient.city);
        setHomeCity(patient.city);
        if (patient.stateID) setHomeAutoState(patient.stateID);
        setHomeState(patient.stateID);
        if (patient.zipcode) setHomeAutoZipCode(patient.zipcode);
        setHomeZipCode(patient.zipcode);

        setPhone(patient.mobliePhone);
        setCountryDetail(patient.country);
        if (patient.dob.length > 0) {
          patient.dob = patient.dob.substr(0, patient.dob.length - 9);
        }

        setDOB(patient.dob);
        setUserState(patient.stateID)

        setGender(patient.gender);
        setPhotoPreview(userData.profileImageURL);
      }
    }
  }

  const handlePhoto = (photo) => {
    if (photo.size <= (fileSize * 1048576)) {
      setPhoto(photo);
      setPhotoPreview(URL.createObjectURL(photo));
    } else {
      toastr.error(
        "Please use a smaller picture",
        `The profile picture size cannot exceed ${fileSize} MB`
      );
    }
  };

  function setCountryDetail(country) {
    
    setCountryCode({ value: country, label: country });
    if (countryMap.length > 0) {
      countryMap
        .filter((key) => key.country_name === country) 
        .map((filterCountry) => setCountryMap(filterCountry));
    } else {
      axios
        .get("/common/country-list")
        .then(function (response) {
          if (response.status === 200) {
            setCountryList(response.data.data);
            let countryList = response.data.data;
            countryList
              .filter((key) => key.country_name === country) 
              .map((filterCountry) => setCountryMap(filterCountry));
          }
        })
        .catch(function (error) { });
    }
  }

  useEffect(()=> {
    setStates([])
    // setUserState("")
      console.log("Inside State List Fetch")
      axios.get(`common/state-list/${CountryID}`)
    .then(
      response => {
        const StatesList = response.data.data.map((item)=> {
          if(CountryID === '223')
            return { value: item.shortCode, label: item.stateName }
          else if(CountryID === '103')
            return { value: item.countyCode, label: item.countyName }
        });
        setStates(StatesList)
      })
      .catch(error => {
        console.log("Error Loading States List",error);
      });
  },[CountryID])

  function setCountryMap(country) {
    country.id === '223' ? setZipText('Zip Code') : setZipText('Post Code');
    setCountry({
      value: country.country_name,
      label: country.country_name,
    });
    setCurrency({
      value: country.country_currency,
      label: country.country_currency,
    });
    setCountryCode({
      value: country.country_isd_code,
      label: country.country_isd_code,
    });
    setCountryName(country.country_name);
    setCountryID(country.id);
  }

  const onChangeList = (event, errors, values) => {
    event.id==='223' ? setZipText('Zip Code') : setZipText('Post Code');
    if (countryMap.length > 0) {
      countryMap
        .filter((key) => key.country_name == event.value) // eslint-disable-line
        .map((filterCountry) => {setCountryMap(filterCountry);console.log(filterCountry)});
    }
  };


  const handleSubmit = (event, errors, values) => {console.log('dept', dependentID);
    // localStorage.setItem("verificationModalShown","0");
    setErrorStatus(false);
    setSpinnerEnable(!spinnerEnable);
    let patient = JSON.parse(localStorage.getItem("userDetail"));
    var patientId = "";
    if (patient.length > 0) {
      patientId =
        patient[0].is_patient && patient[0].is_patient.id
          ? parseInt(patient[0].is_patient.id)
          : null;
    }
    if(dependentID != 0){
      patientId = dependentID;
    }


    const bodyFormData = new FormData();

    bodyFormData.set("fname", fname);
    bodyFormData.set("mname", mname);
    bodyFormData.set("lname", lname);
    bodyFormData.set("gender", gender);
    bodyFormData.set("country", CountryName);
    bodyFormData.set("dob", moment(dob).format('YYYY-MM-DD'));
    bodyFormData.set("countryCode", countryCode.value);
    bodyFormData.set("mobile", phoneNumber);
    bodyFormData.set("countryID", CountryID);
    bodyFormData.set("stateID",userState)
    if (NewHomeAddress) {
      bodyFormData.set("address", NewHomeAddress);
    } else {
      bodyFormData.set("address", HomeAutoAddress);
    }
    bodyFormData.set("city", HomeCity);
    // bodyFormData.set("state", userState);
    bodyFormData.set("zipcode", HomeZipCode);

    bodyFormData.set("currency", currency.value);
    bodyFormData.append("userImge", Photo);

    axios
      .post("/patient/update/" + patientId, bodyFormData)
      .then((response) => {
        if (response.status === 202) {
          setData(response);
          setDetails("");
          setErrorStatus(!errorStatus);
          setResponseMessage([...responseMessage, response.data.title]);
          responseFields.message = t("profileUpdateSucessMessage");
          toastr.success('Success',responseFields.message);
          responseFields.status = true;
          setSpinnerEnable(false);
          window.scrollTo(0, 0);
          localStorage.setItem('modalOpened', 'close');

          // localStorage.setItem('mobileNoVerifiedStatus',response.data.data[0].isMobileNoVerified)
          // if(localStorage.getItem('mobileNoVerifiedStatus') === '0') {
          //   setMobileVerificationModalStatus(true);

          // }
          //  setTimeout(()=>{


          //  },1500);
          localStorage.setItem('mobileNumberBeforeUpdate', Details.phone);
          localStorage.setItem('countryCodeBeforeUpdate', Details.countryCode);
          localStorage.setItem('mobileNumberAfterUpdate', response.data.data[0].mobliePhone);
          localStorage.setItem('countryCodeAfterUpdate', response.data.data[0].countryCode);







        } else {
          responseFields.message = "Error Occured";
          toastr.error("Error Occured");
          responseFields.status = false;
        }
      })
      .catch(function (error) {
        const errorMsg = // eslint-disable-line
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.title) ||
          (error && error.response && error.response.statusText);
        setErrorStatus(true);
        // console.log(error.response.data.title);
        responseFields.message = "Error Occured";
        if(error.response.data.title == "Validation Failed"){
          toastr.error(error.response.data.title, error.response.data.data.validation);
        }else{
          toastr.error(error.response.data.title);
        }
        
        responseFields.status = false;
        setSpinnerEnable(false);
      });
  };
  const { t } = useTranslation();

  // useEffect(()=>{
  //   debugger;
  //   if (mobileVerifiedStatus === "0") {
  //     // if(localStorage.getItem('modalOpened')!== 'open') 
  //       setMobileVerificationModalStatus(true);

  //       // localStorage.setItem('modalOpened','open');
  //     }
  //     else {
  //     setMobileVerificationModalStatus(false);
  //     }
  //     // localStorage.setItem('verificationModalShown',0);


  // },[mobileVerifiedStatus])



  useEffect(() => {
    const mobileNumberBeforeUpdate = localStorage.getItem('mobileNumberBeforeUpdate');
    const mobileNumberAfterUpdate = localStorage.getItem('mobileNumberAfterUpdate');
    const countryCodeBeforeUpdate = localStorage.getItem('countryCodeBeforeUpdate');
    const countryCodeAfterUpdate = localStorage.getItem('countryCodeAfterUpdate');

    if (Details && Details.isMobileNoVerified === "0" && (mobileNumberBeforeUpdate !== mobileNumberAfterUpdate)) {
      if (localStorage.getItem('modalOpened') !== 'open') {
        setMobileVerificationModalStatus(true);
        localStorage.setItem('modalOpened', 'open');
      }
    }
    else if (Details && Details.isMobileNoVerified === "0" && (countryCodeBeforeUpdate !== countryCodeAfterUpdate)) {
      if (localStorage.getItem('modalOpened') !== 'open') {
        setMobileVerificationModalStatus(true);
        localStorage.setItem('modalOpened', 'open');
      }
    }
    else {
      setMobileVerificationModalStatus(false);
    }
    // localStorage.setItem('verificationModalShown',0);


  }, [Details])


  const onChangeGeolocationHome = (event) => {
    setHomeAddressTouched(true);
    if (event.length > 500) {
      toastr.warning(
        "Limit Exceeded",
        "Address should not be greater than 500 Characters"
      );
    } else {
      setNewHomeAddress(event);
      console.log('ljlkj',event)
      setHomeAutoAddress(event);
    }

    setHomeState("");
    setHomeCity("");
    setHomeZipCode("");
    setHomeAutoState("");
    setHomeAutoCity("");
    setHomeAutoZipCode("");
  };



  const onSelectGeolocationHome = (event) => {
    setTimeout(() => {
      if (HomeAutoState) {
        if (HomeAddressTouched) {
          if (event.administrative_area_level_1 === "long_name") {
            setHomeState("");
            setUserState("")
          } else {
            setHomeState(event.administrative_area_level_1);
            setUserState(event.stateFullDetail.short_name)
          }
        } else {
          setHomeState(HomeAutoState);
        }
      } else {
        if (HomeAddressTouched) {
          if (event.administrative_area_level_1 === "long_name") {
            setHomeState("");
            setUserState("")
          } else {
            setHomeState(event.administrative_area_level_1);
            setUserState(event.stateFullDetail.short_name)
          }
        } else {
          setHomeState("");
          setUserState("")
        }
      }

      if (HomeAutoCity) {
        if (HomeAddressTouched) {
          if (event.locality === "long_name" && event.administrative_area_level_2 === "long_name") {
            setHomeCity("");
          } else {
            if (event.locality !== "long_name") {
              setHomeCity(event.locality);
            } else if (event.administrative_area_level_2 !== "long_name") {
              setHomeCity(event.administrative_area_level_2);
            } else {
              setHomeCity("");
            }
          }
        } else {
          setHomeCity(HomeAutoCity);
        }
      } else {
        if (HomeAddressTouched) {
          if (event.locality === "long_name" && event.administrative_area_level_2 === "long_name") {
            setHomeCity("");
          } else {
            if (event.locality !== "long_name") {
              setHomeCity(event.locality);
            } else if (event.administrative_area_level_2 !== "long_name") {
              setHomeCity(event.administrative_area_level_2);
            } else {
              setHomeCity("");
            }
          }
        } else {
          setHomeCity("");
        }
      }

      if (HomeAutoZipCode) {
        if (HomeAddressTouched) {
          if (event.postal_code === "long_name") {
            setHomeZipCode("");
          } else {
            setHomeZipCode(event.postal_code);
          }
        } else {
          setHomeZipCode(HomeAutoZipCode);
        }
      } else {
        if (HomeAddressTouched) {
          if (event.postal_code === "long_name") {
            setHomeZipCode("");
          } else {
            setHomeZipCode(event.postal_code);
          }
        } else {
          setHomeZipCode("");
        }
      }


    }, 500);
  };

  const resetValues = () => {
    setHomeAddress("")
    setDetails("")
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            {t("personalProfile")}
          </CardTitle>
          {Details && !isProfileUpdated && (
            <Alert color="danger" className="profile-alert">{t("completeProfileAlert")}</Alert>
          )}
          {Details && Details.isMobileNoVerified === "0" && dependentID === "0" ?
          // <p style={{textAlign:"center"}}>Here</p>
            <Alert 
            color="warning" 
            className="profile-alert">
            <div className="text-center">Your mobile number  {mobileNumberAfterMasking} is not verified.&nbsp;</div>
            <Link className="link-color" onClick={() => { setMobileVerificationModalStatus(true) }}>{" "}Click here to verify
            </Link>
            </Alert>
            : ""}
        </CardHeader>
        <CardBody>
          <AvForm onValidSubmit={handleSubmit}>
            <Row className="profile-picture">
              <Col
                md="4"
              // className={
              //   Details && !PhotoPreview && !Details.profileImageURL
              //     ? "profile-photo-error"
              //     : "profile-photo"
              // }
              >
                <div className="text-center">
                  <img
                    alt="Profile pic"
                    src={PhotoPreview ? PhotoPreview : defaultAvatar}
                    className="rounded-circle img-responsive mt-2"
                    width="128"
                    height="128"
                  />
                  <div className="mt-2">
                    <Label className="custom-file-upload">
                      <FontAwesomeIcon icon={faUpload} />
                      {"\u00A0"}
                      {"\u00A0"}Upload
                      <Input
                        type="file"
                        onChange={(event) => {
                          handlePhoto(event.target.files[0]);
                        }}
                      ></Input>
                    </Label>
                  </div>
                  <small>{t("imgdesc")}</small>
                </div>
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <Label for="firstName">{t("firstName")} <span className="required">*</span></Label>
                <AvField
                  name="firstName"
                  type="text"
                  placeholder={t("firstName")}
                  validate={{
                    maxLength: { value: 40 }
                  }}
                  id="firstName"
                  {...bindFirstName}
                  errorMessage="This field is required"
                  required
                />
              </Col>
              <Col md={4}>
                <Label for="middleName">{t("middleName")}</Label>
                <AvField
                  name="middleName"
                  type="text"
                  placeholder={t("middleName")}
                  validate={{
                    maxLength: { value: 40 }
                  }}
                  id="middleName"
                  {...bindMiddleName}
                />
              </Col>
              <Col md={4}>
                <Label for="lastName">{t("lastName")} <span className="required">*</span></Label>
                <AvField
                  name="lastName"
                  type="text"
                  placeholder={t("lastName")}
                  validate={{
                    maxLength: { value: 40 }
                  }}
                  id="lastName"
                  {...bindLastName}
                  errorMessage="This field is required"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="exampleCheckbox">{t("gender")} <span className="required">*</span></Label>
                  <div>
                    <CustomInput
                      type="radio"
                      required
                      errorMessage="This field is required"
                      id="Male"
                      name="customRadio"
                      label={t("male")}
                      className="mb-2 gender-flex"
                      value="Male"
                      checked={gender === "Male"}
                      onClick={() => setGender("Male")}
                      inline
                    />
                    <CustomInput
                      type="radio"
                      required
                      errorMessage="This field is required"
                      id="Female"
                      name="customRadio"
                      label={t("female")}
                      value="Female"
                      checked={gender === "Female"}
                      onClick={() => {
                        setGender("Female");
                      }}
                      className="mb-2 gender-flex"
                      inline
                    />
                    <CustomInput
                      type="radio"
                      required
                      errorMessage="This field is required"
                      id="Other"
                      name="customRadio"
                      label={t("pfrNotAns")}
                      value="Male"
                      checked={gender === "Other"}
                      onClick={() => setGender("Other")}
                      className="mb-2 gender-flex"
                      inline
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <Label for="exampleDate2">{t("dobfield")} <span className="required">*</span></Label>
                {/* <AvField
                  name="dob"
                  type="date"
                  placeholder="date placeholder"
                  id="dob"
                  {...bindDOB}
                  max={moment().format("YYYY-MM-DD")}
                  errorMessage="This field is required"
                  required
                /> */}

                {dob ?
                  <div className="profile-date" >
                    <DatePicker
                      dateFormat="dd MMM YYY"
                      onChange={(date) => { setDOB(date); }}
                      placeholderText="DD MM YYYY"
                      selected={moment(dob).toDate()}
                      maxDate={dependentID != 0 ? moment().toDate() :moment().subtract('18', 'years').toDate()}
                      showYearDropdown
                    />
                  </div>
                  : ""}
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="3">
                <FormGroup>
                  <Label>{t("country")} <span className="required">*</span></Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={countryList}
                    onChange={onChangeList}
                    value={country}
                    required
                    errorMessage="This field is required"
                  />
                </FormGroup>
              </Col>

              <Col lg="3" md="3">
                <FormGroup>
                  <Label>{t("currency")}</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={countryCurrenyList}
                    {...bindCurrency}
                    isDisabled
                    required
                  />
                </FormGroup>
              </Col>

              <Col lg="3" md="3">
                <FormGroup>
                  <Label>{t("countryCode")}</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={countryCode}
                    isDisabled
                    options={countryCodeList}
                  />
                </FormGroup>
              </Col>
              <Col lg="3" md="3">
                <Label>{t("phoneNumber")} <span className="required">*</span></Label>{" "}
                {Details.isMobileNoVerified === '1' ?
                  <FontAwesomeIcon className="mobile-field-verified-icon" title="Mobile number is verified" icon={faCheckCircle} />
                  : <FontAwesomeIcon title="Mobile number is pending verification" icon={faExclamationCircle} />}
                <AvField
                  name="phone"
                  type="text"
                  placeholder={t("phoneNumber")}
                  validate={{
                    pattern: { value: '^[0-9]+$', errorMessage: 'only numbers allowed' },
                    maxLength: { value: 12 }
                  }}
                  id="phone"
                  {...bindPhone}
                  errorMessage="This field is required"
                  required
                />

              </Col>
            </Row>

            <Row>
              <Col md="6" lg="6">
                <FormGroup>
                  <Label for="HomeAddress">Address <span className="required">*</span></Label>
                  <GeoLocation
                    errorText={NewHomeAddress || HomeAutoAddress ? "" : <span className="Address-GeoLocation">This field is required</span>}
                    required
                    preSelectedValue={HomeAddress}
                    showAllFields={false}
                    isStateVisible={false}
                    isCityVisible={false}
                    isPinCodeVisible={false}
                    addressLabelText={""}
                    pincodeLabelText={"Zip code"}
                    onChange={onChangeGeolocationHome}
                    onSelect={onSelectGeolocationHome}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <Label for="state">State/Province/County <span className="required">*</span> </Label>
                {/* <AvField
                  value={HomeAutoState ? HomeAutoState : HomeState}
                  name="HomeState"
                  type="text"
                  onChange={(e) => Validation.ProfileState(e.target.value, setHomeState, setHomeAutoState, 24)}
                  required
                /> */}
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={states}
                    placeholder="Select State"
                    onChange={(event)=> {
                      setUserState(event.value)
                    }}
                    value={states.filter(state => state.value === userState)}
                    required
                  />
              </Col>

              <Col md="6">
                <Label for="city">City/Town <span className="required">*</span> </Label>
                <AvField
                  name="HomeCity"
                  type="text"
                  value={HomeAutoCity ? HomeAutoCity : HomeCity}
                  onChange={(e) => Validation.ProfileCity(e.target.value, setHomeCity, setHomeAutoCity, 24)}
                  required
                />
              </Col>
              <Col md="6">
                <Label for="zipcode"> {ZipText} </Label>
                <AvField
                  name="HomeZipcode"
                  type="text"
                  value={HomeAutoZipCode ? HomeAutoZipCode : HomeZipCode}
                  onChange={(e) => Validation.ProfileZipCode(e.target.value, setHomeZipCode, setHomeAutoZipCode, 10, ZipText)}
                />
              </Col>
            </Row>

            <div className="save-btn-wrapper">
              <Button
                disabled={DisableSaveButton}
                color="primary"
                type="submit"
                className="profile-save-btn mt-3"
              >
                {t("savechanges")}
              </Button> {" "}
              <Button
                color="secondary"
                type="reset"
                className="profile-save-btn mt-3"
                onClick={() => { resetValues() }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      {spinnerEnable && (
        <OverlayLoader />
      )}
      <MobileNumberVerificationModal
        mobileVerificationStatus={mobileVerificationModalStatus}
        setMobileVerificationModalStatus={setMobileVerificationModalStatus} />
    </>
  );
};

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageLayout>
        <Header>
          <HeaderTitle>{t("myAccount")}</HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/settings/profile">{t("myAccount")}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Personal Profile</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Row>
          <Col md="3" xl="2">
            <SubNav />
          </Col>
          <Col md="9" xl="10">
            <PersonalInfo />
          </Col>
        </Row>
      </PageLayout>
    </>
  );
};

const OverlayLoader = () => (
  <div class="overlay">
    <div class="overlay__inner">
      <div class="overlay__content"><span class="spinner"></span></div>
    </div>
  </div>
)
export default Profile;