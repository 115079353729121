/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteConfirmationModal = (props) => {
    const {
        deleteModalState,
        setDeleteModalState,
        setDeleteApproved,
        fileName,
    } = props;

    const toggle = () => setDeleteModalState(!deleteModalState);
    const confirm = () => setDeleteApproved(true);

    return (
        <div>
            <Modal isOpen={deleteModalState} toggle={toggle} >
                <ModalHeader toggle={toggle}>Delete File</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete <strong>"{fileName}"</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" className="button-width" onClick={confirm}>Yes</Button>{' '}
                    <Button color="secondary" className="button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DeleteConfirmationModal;