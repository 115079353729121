import React, { useState, useEffect, useContext } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Modal, ModalBody, Col, Row, DropdownItem } from "reactstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../../context/ContextApi';
import { cookieService } from '../../../services/cookieService';
import defaultAvatar from './../../../assets/avatar.png';
import { Link } from "react-router-dom";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const ProfileConfirmModal = (props) => {
  // const { completeProfileChanged, setCompleteProfileChanged } = useContext(GlobalState);
  const { Details } = useContext(GlobalState);
  const { LastName, FirstName, Relation } = useContext(GlobalState);
  let otherUsers = [];

  let dependentID = cookieService.get('dependentID');
  let objectLength = Details.dependents ?  Object.keys(Details.dependents).length : '';
  // const dependent = CryptoJS.AES.decrypt(dependentID, secretKey).toString(CryptoJS.enc.Utf8);
  // console.log('depenc', dependent);
  if(dependentID != 0){
    otherUsers = Details?.dependents?.filter(user => user.id !== dependentID);
    // console.log(otherUsers);
  }else{
    otherUsers = Details?.dependents;
  }
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  let token = localStorage.getItem("token");
  let ProfilePic = Details.profileImageURL ? Details.profileImageURL : defaultAvatar;
  if(cookieService.get('newProfilePic'))
  {
    ProfilePic = cookieService.get('newProfilePic');
  }
  const ProfileHandler = (dependentId, newProfilePic) => {
    var date = new Date();
    date.setTime(+date + 7 * 86400000);
    let profilePic = newProfilePic ? newProfilePic : defaultAvatar;

    if(dependentId>=0){
      cookieService.set("dependentID", dependentId, {path: "/", expires: date});
      cookieService.set("newProfilePic", profilePic, {path: "/", expires: date});

      const request = {
        dependentId: dependentId,
      };
      Axios
        .post(API_ENDPOINT + "/user/generateToken", request,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // setSpinnerEnable(false);
          if (response.status === 200) {
        //     var date = new Date();
        //     date.setTime(+date + 7 * 86400000);
           
  
            cookieService.set("token", response.data.token, {
              path: "/",
              expires: date,
            });
            window.location = "/"
        //     // cookieService.set("refresh_token", response.data.refresh_token, {
        //     //   path: "/",
        //     //   expires: date,
        //     // });
  
        //     // user_redirection(response);
            
        //     // window.location = "/";
        //     // window.location = "/otp-verification";
        //  } else {console.log("else");
        //     // setSpinnerEnable(false);
        //     toastr.error(
        //       "Wrong UserType",
        //       "The email or password you entered did not match our records."
        //     );
          }
        })
        .catch(function (error) {console.log("err", error.response.data.message);
          toastr.error(
            "Invalid Credentials",
            "The email or password you entered did not match our records."
          );
          // setSpinnerEnable(false);
        });
    }else{
      cookieService.remove('dependentID', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
      cookieService.remove('newProfilePic', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    }
    
   // window.location = "/"
    // window.location.reload();
  }

  useEffect(() => {
    
      setOpenModals(true);
    
  }, [props.dependentID])

  const initOpenModals = () => {
    let modals = {};

    // colors.forEach((color, index) => {
    //   modals = Object.assign({}, modals, { [index]: false });
    // });
    // setOpenModals(true);

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const [depID, setDepID] = useState();
  const [depProfileUrl, setDepProfileUrl] = useState('');

  const toggle = (index, dependentID, depProfileImage) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
    setDepID(dependentID);
    setDepProfileUrl(depProfileImage);
  };

  const signOut = () => {
    cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('refresh_token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('dependentID', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('newProfilePic', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    localStorage.removeItem("userDetail");
    window.location = "/"
  }

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          {/* <Button className="delete-button" outline onClick={() => toggle(index)} >
            <FontAwesomeIcon
              icon={faTrashAlt}
              fixedWidth
              
              className="align-middle mr-2"
            />
          </Button> */}
          <div>
    {/* <DropdownItem onClick={() => profileHandler(0, Details.profileImageURL)}> */}
      <div className="profile-card">
        <img
          alt="profile pic"
          // src={Details.profileImageURL ? Details.profileImageURL : defaultAvatar}
          src={ProfilePic}
          className="rounded-circle img-responsive mt-2"
          width="64"
          height="64"
        />
      </div>
      <div className="text-center">
        <h4 className="profile-main ml-2">{FirstName + " " + LastName}</h4>
        <div className="ml-2 relation-text">{dependentID ? Relation : 'Main Account'} </div>
      </div>
      <div className="parent">
        <div className="view-profile"><Link to="/settings/profile">View Profile</Link></div>
        <div className="signout"><a onClick={signOut}>Sign Out</a></div>
      </div>
            {/* </DropdownItem> */}
            {objectLength > 0 ?  <DropdownItem divider /> : null }
{dependentID > 0 ? 
            <DropdownItem onClick={() => toggle(index, 0, Details.profileImageURL)}>
                <div className="flex">
                  {/* <img src={settingsicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/> */}
                  <img
                    alt="Dependent profile pic"
                    src={Details.profileImageURL ? Details.profileImageURL : defaultAvatar}
                    className="rounded-circle img-responsive "
                    width="35"
                    height="35"
                  />
                  <div className="">
                    <h4 className="dependent-list ml-2">{Details.name} </h4>
                    <div className="ml-2 relation-text">Main Account </div>
                  </div>
                </div>
            </DropdownItem> : null }



    {otherUsers?.map((dep) => (<>
      
    <DropdownItem onClick={() => toggle(index, dep.id, dep.profileImageURL)}>
                <div className="flex">
                  {/* <img src={settingsicon} alt="icon-section" className="img-fluid align-middle mr-2" style={{width: '1.25em'}}/> */}
                  <img
                    alt="Dependent profile pic"
                    src={dep.profileImageURL ? dep.profileImageURL : defaultAvatar}
                    className="rounded-circle img-responsive "
                    width="35"
                    height="35"
                  />
                  <div className="">
                    <h4 className="dependent-list ml-2">{dep.name} </h4>
                    <div className="ml-2 relation-text">{dep.relation} </div>
                  </div>
                </div>
            </DropdownItem>
            </>
            ))}
    
      
    </div>
          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
            <ModalBody className="text-center m-3">
              <Row>
                <Col md="12">
                  <p className="text-center">Do you really want to switch this profile?</p>
                  <Button
                    color="primary"
                    onClick={() => ProfileHandler(depID, depProfileUrl)}
                  >
                    Yes
                  </Button> {" "}
                  <Button color="outline-primary mr-2" onClick={() => toggle(index)}>
                    No
                  </Button>
                  
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default ProfileConfirmModal;
