import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import avatar from "../../../assets/avatar.png";
import "./AppointmentDetails.css";
import Axios from "axios";
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import DetailTabs from "./DetailTabs";
import Skeleton from "react-loading-skeleton";
import { GlobalState } from "../../../context/ContextApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { cookieService } from '../../../services/cookieService';
import getSymbolFromCurrency from 'currency-symbol-map';

const AppointmentDetails = (props) => {
  // CONTEXT VARIABLES
  const { Details } = useContext(GlobalState);
  // LOCAL VARIABLES
  let appointment_id = props.id;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [Data, setData] = useState([]);
  const [AppointmentDetails, setAppointmentDetails] = useState([]);
  const [PhysicianDetails, setPhysicianDetails] = useState([]);
  const [currentTab, setCurrentTab] = useState("1")
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [PhysicianContactNumber, setPhysicianContactNumber] = useState(""); // eslint-disable-line
  const [AppointmentFee, setAppointmentFee] = useState("");
  const [Loading, setLoading] = useState(true);
  const [PdfReady, setPdfReady] = useState(false); // eslint-disable-line
  const [MedicationsList, setMedicationsList] = useState([]);
  const [appointmentCancelDetails, setAppointmentCancelDetails] = useState("");


  const ProfileImageSkeleton = (
    <Skeleton circle={true} height={80} width={80} />
  );
  const TextSkeleton = <Skeleton height={15} />;
  const TabSkeleton = (
    <>
      {" "}
      <Skeleton height={35} />
      <Skeleton height={250} />
    </>
  );

  const FetchAppointmentDetails = (props) => {
    Axios.get(API_ENDPOINT + "/patient/appointment/details/" + appointment_id)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setPdfReady(true);
        }, 500);
        if (res.status === 200) {
          // console.log(res.data.data);
          setData(res.data.data);
          setAppointmentDetails(res.data.data.appointmentDetails);
          setPhysicianDetails(res.data.data.physicianDetails);
          setAppointmentCancelDetails(res.data.data.appointmentCancelDetails.id > 0 ? res.data.data.appointmentCancelDetails : "");
          setAppointmentFee(res.data.data.amountPaid);
          setPhysicianContactNumber(res.data.data.physicianDetails.physicianData[0].contactnumber);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadPdf = (tabId) => {
    let noteType;
    // eslint-disable-next-line
    switch (tabId) {
      case '2': {
        noteType = '2';
        break;
      }
      case '4': {
        noteType = '3';
        break;
      }
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ url: `${API_ENDPOINT}/pdf/generate-appointment-pdf`, token: cookieService.get('token'), data: {
        appointmentId: appointment_id,
        noteType: noteType,
        appointmentDateTime: moment
          .utc(AppointmentDetails.schStartTime)
          .local()
          .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)
      }
      }));
      return null;
    } else {
      Axios.post(`${API_ENDPOINT}/pdf/generate-appointment-pdf`, {
        appointmentId: appointment_id,
        noteType: noteType,
        appointmentDateTime: moment
          .utc(AppointmentDetails.schStartTime)
          .local()
          .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)
      }, { responseType: 'blob' })
        .then(function (response) {
          var fileDownload = require('js-file-download');
          fileDownload(response.data, response["headers"]["x-suggested-filename"] || 'report.pdf');
          //handle the logic to download the content
        })
      }
  }



  const fetchMedicationsByAppointment = () => {
    // let dependentID = cookieService.get('dependentID');
    const patientID = Details.is_patient.id;
    Axios.get(API_ENDPOINT + "/patient/get-patient-medications-list/"+patientID +"/"+appointment_id+"/PS")
      .then((res) => {
        // console.log(res.data.data)
        setMedicationsList(res.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  };
  
  const downloadAppointmentPdf=()=>{
    Axios.post(`${API_ENDPOINT}/pdf/generate-patient-appointment-invoice-pdf`,{
      appointmentId: appointment_id,
    }, { responseType: 'blob' })
      .then(function (response) {
        var fileDownload = require('js-file-download');
        fileDownload(response.data, response["headers"]["x-suggested-filename"] || 'report.pdf');
        //handle the logic to download the content
      })
  }



  useEffect(() => {
    if (appointment_id) FetchAppointmentDetails(); }, [appointment_id]); // eslint-disable-line


  

  useEffect(() => {
    if (appointment_id && Details.is_patient && Details.is_patient.id) fetchMedicationsByAppointment();
  }, [appointment_id, Details]); // eslint-disable-line


// eslint-disable-next-line
  const Abc = (
    <div dangerouslySetInnerHTML={{ __html: Data.consultationSummary }} />
  );

  return (
    <div className="appointment_details ">

      <Card className="mb-0">
        <CardBody>
         {/*  <Row>
            <Col md="12">
              <div className="ModalHeader text-center">
                <h3>Appointment Details</h3>
                
              </div>
              <div className="modal-border mb-3"></div>
            </Col>
          </Row> */}
          <Row>
            <Col md={2}>
              <div className={`appointment_details_profile_image ${Loading ? "no-image" : ""}`}>
                {Loading ? (
                  ProfileImageSkeleton
                ) : (
                  <img
                    src={
                      PhysicianDetails.profileImageURL
                        ? PhysicianDetails.profileImageURL
                        : avatar
                    }
                    className="rounded-circle"
                    alt="Avatar"
                  />
                )}
              </div>
            </Col>
            <Col md={10}>
              <div className="appointment_details_content">
                {Loading ? (
                  TextSkeleton
                ) : (
                  <h5>
                    {moment
                      .utc(AppointmentDetails.schStartTime)
                      .local()
                      .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}
                  </h5>
                )}
                {Loading ? (
                  TextSkeleton
                ) : (
                  <p className="mb-1">Care Provider : {PhysicianDetails.title?PhysicianDetails.title+" ":null}{PhysicianDetails.name}</p>
                  
                )}
                 {Loading ? (
                  TextSkeleton
                ) : (
                  
                  <p className="mb-1">Speciality: {(props.consultationDetails.id === appointment_id)?props.consultationDetails.specialityText:'N/A'}</p>
                  
                )}
               {/*  {Loading ? (
                  TextSkeleton
                ) : (
                  <p className="mb-1">
                    Contact Number : {PhysicianContactNumber}
                  </p>
                )} */}

                {Loading ? (
                  TextSkeleton
                ) : (
                  <p className="mb-1">Amount Paid : {`${getSymbolFromCurrency(AppointmentFee.currency)}${AppointmentFee.amountPaid}`}
                  <Button style={{marginTop:'6px'}} color="link" title="Download Invoice"  onClick={()=>downloadAppointmentPdf()}><FontAwesomeIcon
                  icon={faDownload}
                  color="#6ec584"
                  /></Button></p>
                  
                )}

                {Loading ? (
                  TextSkeleton
                ) : (appointmentCancelDetails &&
                  <p className="mb-1">Refund Amount : {`${getSymbolFromCurrency(appointmentCancelDetails.currency)}${appointmentCancelDetails.refundAmount}`}</p>
                )}
              
                {Loading ? (
                  TextSkeleton
                ) : ( AppointmentDetails.appStartTime && 
                  <p className="mb-1">
                    Started On :{" "}
                    {moment
                      .utc(AppointmentDetails.appStartTime)
                      .local()
                      .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}
                  </p>
                  
                )}

                {Loading ? (
                  TextSkeleton
                ) : ( AppointmentDetails.appEndTime &&
                  <p className="mb-1">
                    Completed On :{" "}
                    {moment
                      .utc(AppointmentDetails.appEndTime)
                      .local()
                      .format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}
                  </p>
                )}

                {Loading ? (
                  TextSkeleton
                ) : (
                  <p className="mb-1">Chief Complaint : {Data.chiefComplaint}</p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12 mt-3" className="appointment_tabs">
              {Loading ? (
                TabSkeleton
              ) : (
                <>
                <DetailTabs
                  setCurrentTab={setCurrentTab}
                  setShowDownloadButton={setShowDownloadButton}
                  sicknote={Data.sickNote}
                  MedicationsList={MedicationsList}
                  referalnote={Data.referralNote}
                  consultationsummary={Data.consultationSummary}
                  PatientId={Details.is_patient.id}
                  details={PhysicianDetails}
                />
                {currentTab !== '1' && currentTab !== '3' && showDownloadButton ? (
                  <Button title="Download appointment invoice" color="primary" className="download-btn-center" onClick={() => downloadPdf(currentTab)}>
                    <FontAwesomeIcon
                    icon={faDownload}
                    color="white"
                    />
                    <span style={{paddingLeft: '5px'}}>Download</span>
                  </Button>
                ): null }
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default AppointmentDetails;
