import React, { useState } from "react";
import { Container } from "reactstrap";
import Sidebar from "../Sidebar";
import Wrapper from "../Wrapper";
import Main from "../Main/Main";
import Navbar from "../Navbar/Navbar";
import Content from "../Content/Content";

const PageLayout = (props) => {
  const [showMenu, setToggle] = useState(false);  
  const toggleMenu = () => {
    setToggle(!showMenu);
  };

  return (
    <div  >
      <Wrapper  >
      <Sidebar showMenu={showMenu}/>
      <Main>
        <Navbar toggleFunction={toggleMenu}/>
        <Content>
          <Container fluid >
            {props.children}
          </Container>
        </Content>
      </Main>
    </Wrapper>
    </div>
  );
}

export default PageLayout;
