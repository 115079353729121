import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';

import MedicationDeleteModal from "./MedicationDeleteModal";

const MedicationTable = (props) => {

  const MedicationRemover = (cell, row) => {
    if (row.source && row.source === "PS") {
      return (
        <span className="medication-exclamation" data-tip="Prescribed Medication"
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
          <ReactTooltip className="doc-upload-tooltip" />
        </span>
      )
    } else {
      return (
        <MedicationDeleteModal
          id={row.id}
          TableRenderMedications={props.TableRenderMedications}
        />
      );
    }
  };

  const defaultSorted = [
    {
      dataField: "drugname",
      order: "asc",
    },
  ];

  const tableColumns = [
    {
      dataField: "drugname",
      text: "Drug",
      sort: true
    },
    {
      dataField: "dosage",
      text: "Dosage",
    },
    {
      dataField: "frequency",
      text: "Frequency",
    },
    {
      dataField: "route",
      text: "Route",
    },
    {
      dataField: "status",
      text: "Actions",
      formatter: MedicationRemover,
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => { props.TableRenderMedications(); }, 3000);
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line

  return (
    <div className="MedicationTable">
      <Card>
        <CardBody className="health-card">
          {props.MedicationsNoDataFound ? (
            <p>No Data Found</p>
          ) : (
              <BootstrapTable
                keyField="id"
                data={props.data}
                columns={tableColumns}
                bordered={false}
                bootstrap4
                defaultSorted={defaultSorted}
              />
            )}
        </CardBody>
      </Card>
    </div>
  );
};

export default MedicationTable;
