import React from 'react'
import { Card, CardBody } from "reactstrap"

function BlankStateCard() {
    return (
        <Card className="doc-viewer-blank-state">
            <CardBody>
                <h4>Sorry, we could not generate a preview for this document.</h4>
                <h4 className="margin-top">Please download to view this file. </h4>
            </CardBody>
        </Card>
    )
}

export default BlankStateCard
