import React, { useState } from "react";
import MedicationInput from "./MedicationInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody } from "reactstrap";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const MedicationModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button color="primary ml-4" onClick={() => toggle(index)} className="health-profile-btn">
          <FontAwesomeIcon
                          icon={faPlus}
                          fixedWidth
                          className="align-middle mr-2"
                        />
            Add Medication
          </Button>
          <Modal
            isOpen={openModals[index]}
            // toggle={() => toggle(index)}
            centered
          >
            <h3 className="ModalHeader mt-3">Add Medication</h3>
            <div
              className="vital-modal-close"
              onClick={() => toggle(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>
            <ModalBody className="text-center m-3 MedicationModal">
              <MedicationInput
                toggleModal={() => toggle(index)}
                TableRenderMedications={props.TableRenderMedications}
              />
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default MedicationModal;
