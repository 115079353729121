import React, { useState } from "react";
import PharmacyInput from "./PharmacyInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit,faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody } from "reactstrap";
import ReactTooltip from 'react-tooltip';

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const PharmaciesModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <>
          {props.job === "edit" ? (
            <span>
              <span  data-tip="Change Pharmacy"
            >
            <Button className="outline-primary mr-2 mb-0 pharmacy_edit_button delete-table-row" onClick={() => toggle(index)}>
              <FontAwesomeIcon
                icon={faEdit}
                fixedWidth
                className="align-middle mr-2 "
              />
            </Button>
            </span>
            <ReactTooltip />
            </span>
          ) : (
            <Button color="primary ml-4" onClick={() => toggle(index)}>
              <FontAwesomeIcon
                icon={faPlus}
                fixedWidth
                className="align-middle mr-2"
              />
              Add Pharmacy
            </Button>
          )}

          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
             {props.job === "edit" ? <h3 className="ModalHeader mt-3">Edit Pharmacy</h3> : <h3 className="ModalHeader mt-3">Add Pharmacy</h3>}
             <div
              className="vital-modal-close"
              onClick={() => toggle(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>

            <ModalBody className=" m-3 PharmaciesModal">
              {props.job==="edit"?
              <PharmacyInput
              job={props.job}
              rowData={props.rowData}
              toggleModal={() => toggle(index)}
              TableRenderPharmacies={props.TableRenderPharmacies}
              pharmacy_id={props.pharmacy_id}
            />:<PharmacyInput
            job={props.job}
            toggleModal={() => toggle(index)}
            TableRenderPharmacies={props.TableRenderPharmacies}
          />
            }
              
            </ModalBody>
          </Modal>
        </>
      ))}
    </>
  );
};

export default PharmaciesModal;
