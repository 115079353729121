import React, { useContext } from "react";
import { GlobalState } from '../../../context/ContextApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Card,
    CardBody,
    Col,
    Row,
    Button
} from "reactstrap";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import '../OpenTok/Publisher/Publisher.css';
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map';

const AppointmentCancelled = (props) => {
    // CONTEXT VARIABLES 
    const { physicianName, refundAmount, refundCurrency, physicianTitle } = useContext(GlobalState);
    let history = useHistory();

    function handleClick() {
        window.name = "appointmentCompleted";
        history.push("/my-appointments");
    }

    return (
        <>
            <PageLayout>
                <Header>
                    <HeaderTitle>Consultation Cancelled</HeaderTitle>
                </Header>
                <Row>
                    <Col>
                        <Card className='bookingSummary_docDetails_wrap'>
                            <CardBody>
                                <div className="container">
                                    <FontAwesomeIcon className="check-icon" icon={faCheckCircle} />
                                    <div className="title">Appointment Cancelled</div>
                                    <p className="appointment-cancelled-text">
                                        Your consultation with <strong>{physicianTitle} {physicianName}</strong> has been cancelled. {" "}
                                        {refundAmount && Number(refundAmount) > 0
                                            ? <>
                                                Refund of <strong>{`${getSymbolFromCurrency(refundCurrency)}${refundAmount}`}</strong> has been initiated. {" "}
                                            </>
                                            : ""
                                        }
                                        You can view your appointment and download your invoice.<br />
                                        Thank you for using Equipo Health
                                    </p>
                                    <Button color="primary" size="lg" className="proceedPaymentBtn" onClick={() => handleClick()} >View Appointment History</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </PageLayout>
        </>

    );
}
export default AppointmentCancelled;