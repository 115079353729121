import React from "react";
import { Button, Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap"; // eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const CountryConfirmationModal = (props) => {
  return (
    <>
      <Modal isOpen={props.isOpen} centered>
        {/* <ModalHeader className="text-center">
            Consent
            </ModalHeader> */}
        <h3 className="ModalHeader mt-3">Consent</h3>
        <div
          onClick={() => props.onReject(false)}
          className="consent-modal-close"
        >
          <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
        </div>
        <div className="modal-border"></div>
        <ModalBody className="m-3">
          <Row>
            <Col md="12">
              <Row>
                <Col xs="2" className="icon-container">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="exclamation"
                    
                  />
                </Col>
                <Col xs="10">
                  <p>
                    You have chosen to seek advise, treatment or second opinion
                    (collectively "advise") from a health care professional who
                    is not registered to practise in your country/state of
                    residence/domicile, their professional insurance may not
                    cover the "advise" and you will do it at your own risk.
                    equipo and its associates will not be liable for any
                    liability associated with such "advise".
                  </p>
                  <p>
                    Please click "Agree" if you accept the above and want to
                    avail the service from this professional.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <Row>
            <Col md="12">
            {/* <div className="modal-border"></div> */}
              <div className="text-center mb-2">
                <Button
                  color="primary mr-2"
                  onClick={() =>
                    props.onAccept(props.id, props.slot, props.physician, true)
                  }
                >
                  Agree
                </Button>
                <Button color="error" onClick={() => props.onReject(false)}>
                  Disagree
                </Button>
              </div>
            </Col>
        </Row>
      </Modal>
    </>
  );
};
export default CountryConfirmationModal;
