import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { cookieService } from "../../services/cookieService";
import axios from "../../axios.js";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
  Col
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./SignIn.css";
import { useTranslation } from "react-i18next";
import { useInput } from "../../hooks/input-hook";
import logo from "../../assets/equipo-logo_low-res_with-tagline.png";
import TermsOfUseModal from '../TermsOfUseModal/TermsOfUseModal';
import PrivacyPolicyModal from '../PrivacyPolicyModal/PrivacyPolicyModal';

const SignIn = (props) => {

  // LOCAL STATE VARIABLES
  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const [termsOfUseModal, setTermsOfUseModal] = useState(false)
  const [privacyPolicyModal, setPrivacyPolicy] = useState(false)
  let history = useHistory();//eslint-disable-line

  useEffect(() => {
    let isEmailVerification = localStorage.getItem("IsEmailVerification");
    let isPasswordReset = localStorage.getItem("isPasswordReset");
    if (isEmailVerification) {
      localStorage.removeItem("IsEmailVerification");
      toastr.success("Updated  Successfully", "Email Updated Successfully");
    }
    if (isPasswordReset) {
      localStorage.removeItem("isPasswordReset");
      // toastr.success("Updated  Successfully", "Password Updated Successfully");
    }
    /*let token = cookieService.get('token');
   if(token && token.length > 0){
    history.push('/settings/profile')
   }*/
  }, []);

  const user_redirection = (response) => {
    if (response.data.user.mobile_verified === false && response.data.user.TwoFA_enabled === true) {
      window.location = '/mobile-verification';
    }
    if (response.data.user.mobile_verified === true && response.data.user.TwoFA_enabled === true) {
      window.location = '/otp-verification';
      // history.push('/otp-verification');
    }
    if (response.data.user.mobile_verified === true && response.data.user.TwoFA_enabled === false) {
      window.location = '/';
    }
    if (response.data.user.mobile_verified === false && response.data.user.TwoFA_enabled === false) {
      window.location = '/mobile-verification';
    }


  }

  const handleSubmit = (event, errors, values) => {
    setSpinnerEnable(!spinnerEnable);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const request = {
      username: email,
      password: password,
      timezone: timezone
    };
    axios
      .post("/login", request)
      .then((response) => {
        setSpinnerEnable(false);
        if (response.status === 200 && response.data.user.user_type === "Patient") {
          var date = new Date();
          date.setTime(+date + 7 * 86400000);
         

          cookieService.set("token", response.data.token, {
            path: "/",
            expires: date,
          });
          cookieService.set("refresh_token", response.data.refresh_token, {
            path: "/",
            expires: date,
          });
          cookieService.set("dependentID", 0, {path: "/", expires: date});

          user_redirection(response);
          
          // window.location = "/";
          // window.location = "/otp-verification";
       } else {
          setSpinnerEnable(false);
          toastr.error(
            "Wrong UserType",
            "The email or password you entered did not match our records."
          );
        }
      })
      .catch(function (error) {
        toastr.error(
          "Invalid Credentials",
          "The email or password you entered did not match our records."
        );
        setSpinnerEnable(false);
      });
  };
  const { t } = useTranslation();
  const [spinnerEnable, setSpinnerEnable] = useState(false);

  return (
    <>
      <div className="main h-100 w-100">
        <div className="container h-100">
          <div className="login-wrapper">
            <Card>
              <CardBody className="card-body">
                <div className="m-sm-4">
                  <div style={{height:100, width:230}}  className="text-center image-wrapper">
                    <img
                      src={logo}
                      alt={t("altTextAppLogo")}
                      className="img-fluid"
                    />
                  </div>
                  <br/>
                  <AvForm onValidSubmit={handleSubmit}>
                    <FormGroup>
                      <Label>{t("email")}</Label>
                      <AvField
                        bsSize="lg"
                        name="email"
                        type="email"
                        validate={{
                          maxLength: {value: 254}
                        }}
                        placeholder={t("enterEmailPH")}
                        errorMessage={t("requiredField")}
                        {...bindEmail}
                        required
                      />
                      <Label>{t("password")}</Label>
                      <AvField
                        bsSize="lg"
                        name="password"
                        validate={{
                          maxLength: {value: 24}
                        }}
                        type="password"
                        placeholder={t("enterPasswordPH")}
                        errorMessage={t("requiredField")}
                        {...bindPassword}
                        required
                      />
                      <Link to="forgot-password">{t("forgotPassword")}</Link>
                      {spinnerEnable && (
                        <div className="spinner-wrapper">
                          <Spinner
                            color="dark"
                            type="grow"
                            className="mr-2"
                            size="sm"
                          />
                          <Spinner
                            color="dark"
                            type="grow"
                            className="mr-2"
                            size="sm"
                          />
                          <Spinner
                            color="dark"
                            type="grow"
                            className="mr-2"
                            size="sm"
                          />
                        </div>
                      )}
                    </FormGroup>
                    <div className="text-center mt-3 button-wrapper">
                      <Button color="primary" size="lg" autoFocus>
                        {t("signIn")}
                      </Button>
                    </div>
                    <p className="newto">New to equipo? </p>
                    <Link className="button-link" to="/signup">
                      <Button color="primary" size="lg" outline>
                       Create an account
                      </Button>
                    </Link>
                  </AvForm>
                </div>

                <hr />
                <div className="contact-info-container">
                  <div className="email-and-phone text-center">
                    <Col xs={12}>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        fixedWidth
                      />
                      <a href="mailto:info@equipohealth.com" >info@equipohealth.com</a>
                    </Col>
                    <Col xs={12}>
                      <FontAwesomeIcon
                        icon={faPhoneAlt}
                        fixedWidth
                      />
                      <span >+1800 482 9082</span>
                    </Col>
                    <Col xs={12}>
                   <Label
            className="terms-of-use"
            ><span className="doc-type" onClick={() => { setTermsOfUseModal(true) }}>Terms of Use</span> | <span className="doc-type" onClick={() => { setPrivacyPolicy(true) }}>Privacy Policy</span></Label>
                   </Col>
                  </div>
                </div>
              </CardBody>
            </Card>
            <TermsOfUseModal
              termsOfUseModal={termsOfUseModal}
              setTermsOfUseModal={setTermsOfUseModal}
            />
            <PrivacyPolicyModal
              privacyPolicyModal={privacyPolicyModal}
              setPrivacyPolicy={setPrivacyPolicy}
            />
            
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
