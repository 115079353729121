/* global OneSignal */
import React, { createContext, useState, useEffect } from "react"
import axios from "axios"
import { cookieService } from "../services/cookieService"

export const GlobalState = createContext()
export const GlobalStateProvider = (props) => {
  const [Details, setDetails] = useState("")
  const [userId, setUserId] = useState("")
  const [hideMenu, setHideMenu] = useState(true)
  const [LastName, setLastName] = useState("")
  const [FirstName, setFirstName] = useState("")
  const [Relation, setRelation] = useState("")
  const [Authenticated, setAuthenticated] = useState(false)
  const [videoConsultInfo, setVideoConsultInfo] = useState("")
  const [timer, setTimer] = useState(0)
  const [timerDuration, setTimerDuration] = useState(0)
  const [appointmentId, setAppointmentId] = useState("")
  const [consultComplete, setConsultComplete] = useState(false)
  const [appointmentTime, setAppointmentTime] = useState("")
  const [appointmentEndTime, setAppointmentEndTime] = useState("")
  const [physicianTitle, setPhysicianTitle] = useState("")
  const [physicianName, setPhysicianName] = useState("")
  const [consultationStarted, setConsultationStarted] = useState(false)
  const [alterSidebar, setAlterSidebar] = useState(false)
  const [physicianSearchDetails, setPhysicianSearchDetails] = useState({})
  const [searchedSpeciality, setSearchedSpeciality] = useState("");
  const [specialityDetailsContext, setSpecialityDetailsContext] = useState({})
  const [isProfileUpdated, setIsProfileUpdated] = useState("")
  const [completedProfileData, setCompletedProfileData] = useState("")
  const [isIncompleteInfoPresent, setIsIncompleteInfoPresent] = useState(false)
  const [completeProfileChanged, setCompleteProfileChanged] = useState(true)
  const [appointmentInfo, setAppointmentInfo] = useState("")
  const [patientPharmacy, setPatientPharmacy]=useState("")
  const [paymentNote, setPaymentNote]=useState("")
  const [refundAmount, setRefundAmount]=useState("")
  const [refundCurrency, setRefundCurrency]=useState("")
  const [ruleCount, setRuleCount] = useState("")
  const [isMobileNoVerified, setIsMobileNoVerified] = useState(false);
  const [SuperUserSession,setSuperUserSession] = useState("");
  const [PatientName, setPatientName] = useState("");

  const getUserDetails = () => {
    let token = cookieService.get("token");
    let dependentID = cookieService.get('dependentID');
    let isAuthenticated = token ? true : false;

    if (isAuthenticated) {
      setAuthenticated(true);
      axios
        .get("/user/details")
        .then(function (response) {
          if (response.status === 200) {
            if(dependentID!=0){
              const filteredUsers = response.data.data[0].dependents.filter(user => user.id === dependentID);
              // console.log(filteredUsers);
              // setLastName(response.data.data[0].dependents.last);
              setLastName(filteredUsers[0]?.last);
              setFirstName(filteredUsers[0]?.first);
              setRelation(filteredUsers[0]?.relation);
            }else{
              setLastName(response.data.data[0].is_patient.last);
              setFirstName(response.data.data[0].is_patient.first);
            }
            setDetails(response.data.data[0]);
            setUserId(response.data.data[0].id);

            if(response.data.data[0].slug) {
              window.OneSignal = window.OneSignal || [];
              if(OneSignal) {
                OneSignal.push(function() {
                  OneSignal.init({
                    appId: process.env.REACT_APP_ONESIGNAL_APPID,
                    safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID,
                    notifyButton: {
                      enable: true,
                    },
                  });
                });
                OneSignal.push(function() {
                  OneSignal.setExternalUserId(response.data.data[0].slug);
                });
              }
            }
          }
        })
        .catch(function (error) { });

    } else {
      /*if(OneSignal) {
        OneSignal.push(function() {
          OneSignal.removeExternalUserId();
        });
      }*/
    }
  }

  // FUNCTION TO CHECK WHETHER THE PATIENT PROFILE IS UPDATED OR NOT
  const checkAllProfileDetails = () => {
    const profileInfo = Details.is_patient;
    if (
      profileInfo.first &&
      profileInfo.last &&
      profileInfo.gender &&
      profileInfo.email &&
      profileInfo.dob &&
      profileInfo.country &&
      profileInfo.mobliePhone &&
      profileInfo.address1 &&
      profileInfo.stateID &&
      profileInfo.city 
      // &&
      // profileInfo.zipcode &&
      // Details.profileImageURL
    ) {
      setIsProfileUpdated(true)
      window.showIncompleteProfileModal = true;
    } else {
      setIsProfileUpdated(false)
    }
  }

  const capitalizeTheFirstLetterOfEachWord = (words) => {
    var separateWord = words.toLowerCase().split(' ');
    for (var i = 0; i < separateWord.length; i++) {
       separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
       separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }

  const checkRelevantInfosProvided = () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    axios.get(API_ENDPOINT + "/patient/profile-check")
      .then((res) => {
        let resData = []
        if(res.data.data[0]){
            if(res.data.data[0].vital.length === 0){
              resData.push("vital")
            }
            if(res.data.data[0].allergy.length === 0){
              resData.push("allergy")
            }
            if(res.data.data[0].medication.length === 0){
              resData.push("medication")
            }
            if(!(res.data.data[0].pharmacy.some(pharmacyData => pharmacyData.m_status == 1))){  // eslint-disable-line
              resData.push("pharmacy")
            }
          }

         setCompletedProfileData(
          resData.map(data => capitalizeTheFirstLetterOfEachWord(data)).join(", ")
         )
         if (resData.length > 0){
            setIsIncompleteInfoPresent(true)
         } else {
            setIsIncompleteInfoPresent(false)
         }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  const getRefundRuleCount = () => {
    axios.post("/patient/appointment/cancellation-charge-list")
      .then((response) => {
        if (response.data.data.length === 0) {
          setRuleCount(0)
        } else {
          setRuleCount(response.data.data.length)
        }
      })
      .catch((error) => {
        setRuleCount(0)
      });
  }

  const checkIfMobileNumberVerified = () => {
    const isMobileVerified = Number(Details.isMobileNoVerified);
    if (isMobileVerified) {
      setIsMobileNoVerified(true);
    }
    else{
      setIsMobileNoVerified(false);
    }

  }

  useEffect(() => { if (!Details) getUserDetails() }, [Details]);
  useEffect(() => { if (Details) checkAllProfileDetails() }, [Details]);  // eslint-disable-line
  useEffect(() => { if (Details) checkIfMobileNumberVerified() }, [Details]);  // eslint-disable-line
  useEffect(() => { if (Details) checkRelevantInfosProvided() }, [Details, completeProfileChanged]); // eslint-disable-line
  useEffect(() => { if (ruleCount === "" && Details) getRefundRuleCount() }, [ruleCount, Details]);

  return (
    <GlobalState.Provider
      value={{
        alterSidebar, setAlterSidebar,
        appointmentEndTime, setAppointmentEndTime,
        appointmentId, setAppointmentId,
        appointmentInfo, setAppointmentInfo,
        appointmentTime, setAppointmentTime,
        Authenticated,
        completedProfileData, setCompletedProfileData,
        completeProfileChanged, setCompleteProfileChanged,
        consultationStarted, setConsultationStarted,
        consultComplete, setConsultComplete,
        Details, setDetails,
        FirstName, 
        Relation,
        hideMenu, setHideMenu,
        isIncompleteInfoPresent, setIsIncompleteInfoPresent,
        isProfileUpdated, setIsProfileUpdated,
        LastName,
        patientPharmacy, setPatientPharmacy,
        paymentNote, setPaymentNote,
        physicianName, setPhysicianName,
        physicianSearchDetails, setPhysicianSearchDetails,
        physicianTitle, setPhysicianTitle,
        refundAmount, setRefundAmount,
        refundCurrency, setRefundCurrency,
        ruleCount, setRuleCount,
        searchedSpeciality, setSearchedSpeciality,
        specialityDetailsContext, setSpecialityDetailsContext,
        timer, setTimer,
        timerDuration, setTimerDuration,
        userId, setUserId,
        videoConsultInfo, setVideoConsultInfo,
        isMobileNoVerified, setIsMobileNoVerified,
        SuperUserSession,setSuperUserSession,
        PatientName, setPatientName,
      }}
    >
      {props.children}
    </GlobalState.Provider>
  );
};
