import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";
import {
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

const TabsWithTextLabel = (props) => {
  // DEPENDENCY VARIABLES 
  const { className } = props; // eslint-disable-line
  // LOCAL VARIABLES
  let string = window.name;
  string = string.split(",", 1);
  string = string.length > 0 ? string[0] : ""

  let history = useHistory();
  let location = useLocation();
  const [activeTab, setActiveTab] = useState(string === "appointmentCompleted" ? "3" : "1");

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

    let UpcomingAppointmentsLoader = null;
    if (props.UpcomingConsultationLoading) {
      UpcomingAppointmentsLoader = <Spinner />;
    }

    let CompletedAppointmentsLoader = null;
    if (props.CompletedConsultationLoading) {
      CompletedAppointmentsLoader = <Spinner />;
    }

    let AllAppointmentsLoader = null;
    if (props.AllConsultationLoading) {
      AllAppointmentsLoader = <Spinner className="physician-spinner-today-appointments" />;
    }

  // BELOW EFFECT PUTS ACTIVE TAB NUMBER IN THE URL
  useEffect(() => {
    let path = location.pathname
    history.push(`${path}?tab=${btoa(activeTab)}`)
  }, [activeTab])// eslint-disable-line

    return (
      // eslint-disable-next-line
      <div className={"tab " + "my-appointments " + className}> 
        <Nav tabs className="mobile-nav">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Upcoming
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Completed
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              History
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              {UpcomingAppointmentsLoader}
              {props.UpcomingConsultationNodataFound ? (
                <div class="h3 bg-light empty-content">No Upcoming Consultation </div>
              ) : (
                  props.upcoming_appointments
                )}
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              {CompletedAppointmentsLoader}
              {props.CompletedConsultationNodataFound ? (
                <div class="h3 bg-light empty-content">Looks like you have no completed appointments</div>
              ) : (
                  props.completed_appointments
                )}

            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              {AllAppointmentsLoader}
              {props.AllConsultationNodataFound ? (
                <div class="h3 bg-light empty-content">Looks like you have no appointments</div>
              ) : (
                  props.all_appointments
                )}
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  
}

export default TabsWithTextLabel