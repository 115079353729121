import React, { useState } from "react";
import { Modal, ModalBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ProviderDetails from "../Providers/ProviderDetails/ProviderDetails";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const ProfileModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: ! openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <FontAwesomeIcon icon={faUserCircle} />
          <span className="link-sections__text" onClick={() => toggle(index)}>
            View Profile
          </span>
          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
            className="physician_details_modal"
          >
              <div className="modal-close">
                  <FontAwesomeIcon icon={faTimesCircle}  onClick={() => toggle(index)} />
              </div>
            <ModalBody>
              <Row>
                <Col md="12">
                  <ProviderDetails data={props.data} speciality={props.speciality} duration={props.duration} searchedSpeciality={props.searchedSpeciality} />
                    {/* <Test data={props.data} /> */}
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default ProfileModal;
