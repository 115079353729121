import React, { useState } from "react";
import PhysiciansInput from "./PhysiciansInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit,faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody } from "reactstrap";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const PhysiciansModal = (props) => {

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <>
          {props.job === "edit" ? (
            <Button className="outline-primary ml-2 mb-0 physician_edit_button delete-table-row" onClick={() => toggle(index)}>
              <FontAwesomeIcon
                icon={faEdit}
                fixedWidth
                className="align-middle mr-2 "
              />
            </Button>
          ) : (
            <Button color="primary ml-4" onClick={() => toggle(index)}>
              <FontAwesomeIcon
                icon={faPlus}
                fixedWidth
                className="align-middle mr-2"
              />
              Add Physician
            </Button>
          )}

          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
           
            {props.job === "edit" ? <h3 className="ModalHeader mt-3">Edit Physician</h3> : <h3 className="ModalHeader mt-3">Add Physician</h3>}

            <div
              className="vital-modal-close"
              onClick={() => toggle(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>
            <ModalBody className=" m-3 PhysiciansModal">
              {props.job==="edit"?
              <PhysiciansInput
              job={props.job}
              toggleModal={() => toggle(index)}
              TableRenderPhysicians={props.TableRenderPhysicians}
              physician_id={props.physician_id}
              PhysicianCareTypes={props.PhysicianCareTypes}
            />:<PhysiciansInput
            PhysicianCareTypes={props.PhysicianCareTypes}
            job={props.job}
            toggleModal={() => toggle(index)}
            TableRenderPhysicians={props.TableRenderPhysicians}
          />
            }
              
            </ModalBody>
          </Modal>
        </>
      ))}
    </>
  );
};

export default PhysiciansModal;
