import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import LazyLoad from 'react-lazy-load';
import Select from "react-select";
import axios from 'axios';
import { useDropInput } from '../../hooks/drop-down-hook';

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";


import styles from "./Providers.module.css";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";

import avatar from "../../assets/avatar.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Providers = () => {
  const { value: specialityList, bind: bindSpecialityList } = useDropInput(); // eslint-disable-line

  const { t } = useTranslation();
  const [data, setData] = useState([]);
  let specialityOptions = data.map(function (list) {
    return { value: list.id, label: list.specialityName };
  })
  useEffect(() => {

    axios.post('speciality/get-speciality-list')
    .then(function (response) {
      if(response.status === 202) {
        setData(response.data.data)
        } 
    })
    .catch(function (error) {

    })
  },[]);
  return (

    <PageLayout>
      <Header>
        <HeaderTitle>{t('doctorsList')}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/calls">{t('providers')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('directory')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col>
          <Card className={styles.providerCard}>
            <CardBody>
              <Row>
                <Col lg={6} md={6}>
                  <InputGroup >
                    <Input placeholder={t('searchForDoctors')} />
                    <InputGroupAddon addonType="append" color="primary">
                      <Button><FontAwesomeIcon icon={faSearch} />{" "}</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col lg={6} md={6}>
                  <FormGroup>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={specialityOptions}
                    {...bindSpecialityList}
                    required
                  />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                {t('book')}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad  height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad  height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad  height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad >
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
                <Col lg={6} md={6}>
                  <LazyLoad height={200} offsetVertical={200}>
                    <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
                      <CardBody className="p-3">
                        <div className={styles.profileWrapper}>
                          <div className={styles.imageWrapper}>
                            <img
                              src={avatar}
                              width="90"
                              height="90"
                              className="rounded-circle"
                              alt="Avatar"
                            />
                          </div>
                          <div className={styles.profileContent}>
                            <div className={styles.profileData}>
                              <h6> Manohar Thakur</h6>
                              <h5> MBBS,MRCS,MRCGP,MS,DNB,FAIS</h5>
                              <p>Family Medicine / General Practice Specialist</p>
                              <div>
                                <span>Available slot: </span>
                                <span className={styles.availablityDate}>08/12/2020</span>
                                <time>2:30 PM</time>
                              </div>
                            </div>
                            <div className={styles.btnWrapper}>
                              <Link className="button-link" to="/providers/details">
                                <Button color="primary" size="md">
                                  Book
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </LazyLoad>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageLayout>
  );
}


export default Providers;
