import React, { useContext } from "react";
import { GlobalState } from '../../../../context/ContextApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Card,
    CardBody,
    Col,
    Row,
    Button
} from "reactstrap";

import {
    faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import Header from "../../../Layout/Header/Header";
import HeaderTitle from "../../../Layout/Header/HeaderTitle";
import PageLayout from "../../../Layout/PageLayout/PageLayout"
import '../Publisher/Publisher.css';



const DependentAppointmentCompleted = (props) => {
    // DEPENDENCY VARIABLES 
    /* const { appointmentId } = props; */
    // CONTEXT VARIABLES 
    const { physicianName, appointmentId, PatientName } = useContext(GlobalState)


    const reDirectionPointer = () => {
        window.name = `appointmentCompleted,${appointmentId}`;
    }

    return (
        <>
            <PageLayout>
                <Header>
                    <HeaderTitle>Consultation Completed</HeaderTitle>
                </Header>
                <Row>
                    <Col>
                        <Card className='bookingSummary_docDetails_wrap'>
                            <CardBody>
                                <div className="container">
                                    <FontAwesomeIcon className="check-icon" icon={faCheckCircle} />
                                    <div className="title">Appointment Completed</div>
                                    <p>
                                        Your dependent {PatientName}'s consultation with Dr. {physicianName} has concluded. Thank you for using equipo Health.
                                    </p>
                                    <a href="/home" onClick={() => reDirectionPointer()}>
                                        <Button color="primary" size="lg" className="proceedPaymentBtn" >Go to Homepage </Button>
                                    </a>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </PageLayout>
        </>

    );
}
export default DependentAppointmentCompleted;