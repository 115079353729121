import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { cookieService } from '../../services/cookieService'
import axios from "../../axios.js";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
    Button,
    Card,
    CardBody,
    Spinner,
    Col,
    // Row,
    // Container
} from "reactstrap";

import moment from 'moment';
// import { AvForm, AvField } from "availity-reactstrap-validation";
import '../../components/MobileVerification/OTP.css';
import { useTranslation } from "react-i18next";
// import { useInput } from "../../hooks/input-hook";
import logo from "../../assets/logo.svg";
import OtpInput from "react-otp-input";
import { GlobalState } from '../../context/ContextApi';


const OTP = () => {
    // const { value: otpNumber, bind: bindOTPNumber} = useInput("");
    const { Details, setDetails } = useContext(GlobalState);

    const [sid, setSid] = useState("");//eslint-disable-line
    const [sidState, setSidState] = useState(true);
    const [otpNumber, setOtpNumber] = useState("");
    let authToken = cookieService.get("token");
    const [spinnerEnable, setSpinnerEnable] = useState(false);
    const [resendOtpState, setResendOtp] = useState(false);//eslint-disable-line
    const [verifyOtpButtonState, setVerifyOtpButtonState] = useState(true);
    const [attemptsDiv, setAttemptsDiv] = useState(false);
    const [attemptsCount, setAttemptsCount] = useState(5);
    const [otpField, setOtpField] = useState(true);
    const [resendOtpLink, setResendOtpLink] = useState(true);
    const [resendButtonState, setresendButtonState] = useState(false);
    const [verificationTimeoutWarning, setVerificationTimeoutWarning] = useState(false);
    const [wrongOtpWarning, setWrongOtpWarning] = useState(false);

    let otpState = "idle"
    let phoneNumber = `${Details.phone}`;
    let phoneNumberAfterMasking = phoneNumber.replace(/\d(?=\d\d\d\d)/g, "*");
    let mobileNumber = Details ? `${Details.countryCode}${phoneNumberAfterMasking}` : ""
    var date = new Date();
    const location = useLocation();
    const details_location = (location.state)?location.state.detail:"";

    let history = useHistory();

    let otpGeneratedTime = localStorage.getItem('otp-generated-time')

    const calculateTime = (otpGeneratedTime) => {
        let a = otpGeneratedTime ? moment(otpGeneratedTime) : moment()
        let b = moment()
        return b.diff(a, 'seconds')
    }

    const handleChange = (otp) => {
        setOtpNumber(otp);
        // console.log(otpLength);

    }

    const generateOtp = () => {
        setSpinnerEnable(true);
        const requestData = {
            message_text : (details_location)?details_location.message:"equipo login"
        }
        axios
            .post("/generate-otp", requestData,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )
            .then((response) => {
                localStorage.setItem('sidTimeOut', date.setSeconds(600))
                otpGeneratedTime ? otpState = "generated" : otpState = "not-generated"
                if (otpState === "not-generated") {localStorage.setItem('otp-generated-time', new Date())}
                setSid(response.data.data.sid);
                cookieService.set("twilio_otp_sid", response.data.data.sid, {
                    // path: "/",
                    expires: date,
                })
                setSpinnerEnable(false);
                console.log(cookieService.get("twilio_otp_sid"));
                toastr.success("Success", response.data.title);
                

            })
            .catch(function (error) {
                setSpinnerEnable(false);
                // console.log(error.response.data.title);
                toastr.error(
                    "Error",
                    error.response.data.title
                );

            });

    }

    const verifyOTP = (event, errors, values) => {
        setAttemptsDiv(false);
        setVerifyOtpButtonState(false);
        setResendOtpLink(false);
        setSpinnerEnable(true);
        const request = {
            sid: cookieService.get('twilio_otp_sid'),
            otp: otpNumber,

        };

        axios
            .post("/verify-otp", request,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )
            .then((response) => {
                // setSpinnerEnable(false);
                if (response.data.data.message === "approved") {
                    setSpinnerEnable(false);
                    cookieService.remove("twilio_otp_sid");
                    let mobileVerificationStatus = (response.data.data.isMobileNoVerified) ? response.data.data.isMobileNoVerified : "";
                    if (mobileVerificationStatus) {
                        setDetails("");
                        toastr.success("Success", response.data.title);
                        localStorage.removeItem('otp-generated-time')
                        setTimeout(() => {
                            history.push("/");
                        },1000);
                     return;

                    }

                    // setTimeout(() => {

                    //       },2000);
                    toastr.success("Success", response.data.title);
                    setTimeout(() => {
                        history.push("/");
                    },1000);
                }
                if (response.data.data.message === "pending") {
                    setAttemptsDiv(true);
                    if (attemptsCount > 1) {
                        setAttemptsCount(attemptsCount - 1);
                        setAttemptsDiv(true);
                        setVerifyOtpButtonState(true);
                        setResendOtpLink(true);
                    }
                    else {
                        setAttemptsDiv(false);
                        setVerifyOtpButtonState(false);
                        setOtpNumber("");
                        setOtpField(false);
                        setWrongOtpWarning(true);
                    }
                    setSpinnerEnable(false);
                    toastr.error('Error', response.data.title);
                    // console.log(response.data.title);
                }
                // if(response.data.status === 500) {
                //     // setVerifyOtpButtonState(false);
                //     console.log(response.data.status);
                // }


            })
            .catch(function (error) {
                setSpinnerEnable(true);
                setVerifyOtpButtonState(false);
                setSpinnerEnable(false);
                if (error.response.data.status === 404) {
                    setVerificationTimeoutWarning(true);
                    setOtpField(false);
                    setAttemptsDiv(false);
                    // toastr.error(
                    //     "Error",
                    //    '404'
                    //   );
                }
                if (error.response.data.status === 429) {
                    toastr.error(
                        "Error",
                        'There is an issue.Please try again after sometime'
                    );
                }
                // toastr.error(
                //   "Invalid Credentials",
                //   "The email or password you entered did not match our records."
                // );
                // setSpinnerEnable(false);
            });


    }

    const resendOtp = () => {
        setresendButtonState(true);
        setSpinnerEnable(true);
        setVerifyOtpButtonState(false);
        setAttemptsDiv(false);
        setAttemptsCount(5);
        const requestData = {
            message_text : (details_location)?details_location.message:"equipo login"
        
        }
        

        axios
            .post("/generate-otp", requestData,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )
            .then((response) => {
                setVerificationTimeoutWarning(false);
                setWrongOtpWarning(false);
                setResendOtpLink(true);
                setSpinnerEnable(false);
                setVerifyOtpButtonState(true);
                cookieService.remove("twilio_otp_sid");
                var date = new Date();
                date.setSeconds(600);
                setSid(response.data.data.sid);
                cookieService.set("twilio_otp_sid", response.data.data.sid, {
                    // path: "/",
                    expires: date,
                });
                setResendOtp(false);
                setOtpNumber("");
                toastr.success("Success", 'A new OTP has been sent to your mobile number');
                setOtpField(true);
                setresendButtonState(false);

            })
            .catch(function (error) {
                // console.log(error.response.data.title);
                toastr.error(
                    "Error",
                    error.response.data.title
                );
                setSpinnerEnable(false);
                setVerifyOtpButtonState(true);

            });

    }



    const otpInputStyle = {
        width: '7vh',
        height: '7vh',
        margin: '0 1vh',
        fontSize: '5vh',
        borderRadius: '4px',
        border: '1px solid rgba(0,0,0,0.3)'
    }


    const { t } = useTranslation();

    useEffect(() => {
        let timeDifference = calculateTime(otpGeneratedTime)
        if (timeDifference > 120) {
            otpGeneratedTime = "" // eslint-disable-line
            localStorage.removeItem('otp-generated-time')
            otpState = "otp-expired" // eslint-disable-line
        }
        if (timeDifference === 0) {
            if (!spinnerEnable && sidState) generateOtp();
        }
        if (otpState === "otp-expired") {
            if (!spinnerEnable && sidState) generateOtp();
        }
    }, [otpGeneratedTime, otpState, sidState]) // eslint-disable-line

    useEffect(() => { if (sidState) { setSidState(false); } }, [sidState]); // eslint-disable-line

    return (
        <>
            <div className="main h-100 w-100">
                <div className="container h-100">

                    <div className="otp-wrapper">
                        <Card>
                            <CardBody className="otp-card-body">
                                <div className="m-sm-4">
                                    <div className="text-center mb-3">
                                        <img
                                            src={logo}
                                            alt={t("altTextAppLogo")}
                                            className="otp-img-fluid"

                                        />
                                    </div>
                                    <br />
                                    {otpField ?
                                        <div className="otp-text-wrapper">
                                            <p>Enter the 6-digit OTP number we sent to {mobileNumber}</p>
                                            <OtpInput
                                                value={otpNumber}
                                                onChange={(e) => { handleChange(e) }}
                                                numInputs={6}
                                                isInputNum={true}
                                                separator={<span>-</span>}
                                                inputStyle={otpInputStyle}
                                            />
                                        </div> : ""}
                                    {wrongOtpWarning ?
                                        <div className="text-center">
                                            <p>You have entered incorrect OTP multiple times</p>
                                            <p>Please click the <b>Resend OTP</b> button below to generate a new OTP.</p>
                                            <Button
                                                color="primary"
                                                onClick={() => resendOtp()}
                                                className="text-center"
                                                disabled={resendButtonState}
                                            >
                                                Resend OTP
                                    </Button>
                                        </div>

                                        : ""}
                                    {verificationTimeoutWarning ?
                                        <div className="text-center">
                                             <p>The OTP has expired</p>
                                            <p>Please click the <b>Resend OTP</b> button below to generate a new OTP.</p>
                                            <Button
                                                color="primary"
                                                onClick={() => resendOtp()}
                                                className="text-center"
                                                disabled={resendButtonState}
                                            >
                                                Resend OTP
                                </Button>
                                        </div> : ""}
                                    <br />
                                    {spinnerEnable && (
                                        <div className="spinner-wrapper">
                                            <Spinner
                                                color="dark"
                                                type="grow"
                                                className="mr-2"
                                                size="sm"
                                            />
                                            <Spinner
                                                color="dark"
                                                type="grow"
                                                className="mr-2"
                                                size="sm"
                                            />
                                            <Spinner
                                                color="dark"
                                                type="grow"
                                                className="mr-2"
                                                size="sm"
                                            />

                                        </div>
                                    )}

                                    {verifyOtpButtonState ?
                                    <div className="otp-wrapper">
                                        <div className="text-center">
                                            <Button
                                                color="primary"
                                                onClick={() => verifyOTP()}
                                                className="text-center"
                                                disabled={(otpNumber.length === 6) ? false : true}
                                            >
                                                Verify OTP
                                     </Button>
                                        </div>

<div className="text-center">
<Link to="/home">
<button className="btn btn-outline-primary pull-right">
       
        Cancel
        </button>
    {" "}
    </Link>
</div></div>
                                        : ""}
                                    {resendOtpLink ?
                                        <div className="text-center">
                                            <Link onClick={() => resendOtp()}>Resend OTP</Link>
                                        </div> : ""}
                                    {attemptsDiv ?
                                        <div className="text-center mt-4">
                                            <small>You have {attemptsCount} attempt(s) left.</small>
                                        </div> : ""}
                                </div>
                                <hr />
                                <div className="contact-info-container">
                                    <div className="email-and-phone text-center">
                                        {/* <Col xs={12}>
                                        <small>Having trouble logging in ?</small> 
                                    </Col><br/> */}
                                        <Col xs={12}>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                fixedWidth
                                            />
                                            <a href="mailto:info@equipohealth.com" >info@equipohealth.com</a>
                                        </Col>
                                        <Col xs={12}>
                                            <FontAwesomeIcon
                                                icon={faPhoneAlt}
                                                fixedWidth
                                            />
                                            <span >+353 (0) 1524 0476</span>
                                        </Col>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OTP;