import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, Spinner, Button } from "reactstrap";
import PhysiciansDeleteModal from "./PhysiciansDeleteModal";
import PhysiciansModal from "./PhysiciansModal";


const PhysiciansTable = (props) => {
  const [PhysicianCareTypes, setPhysicianCareTypes] = useState([]);

  const ActionFormatter = (cell, row) => {
    return (
      <>
        <Button className="outline-primary mr-2 mb-0 physician_edit_button delete-table-row">
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-2 "
          />
        </Button>
        <PhysiciansModal
          job="edit"
          physician_id={row.id}
          TableRenderPhysicians={props.TableRenderPhysicians}
          PhysicianCareTypes={props.PhysicianCareTypes}
        />
        {
          row.careTypeId!=="1"?<PhysiciansDeleteModal
          id={row.id}
          TableRenderPhysicians={props.TableRenderPhysicians}
        />:null
        }
        
      </>
    );
  };

  const externalFormatter = (cell) => {
    if (cell === "1") {
      return "Yes";
    } else {
      return "No";
    }
  };

  const careTypeFormatter = (cell) => {
    return PhysicianCareTypes.filter((a) => a.id === cell).map((b) => b.name);
  };

  const expandRow = {
    renderer: (row) => (
      <div className="CareTeamTableExpands">
        <span>{`Address :  ${row.address}`}</span>
        <span>{`Country :  ${row.country}`}</span>
        <span>{`State :  ${row.state}`}</span>
        <span>{`City :  ${row.city}`}</span>
        <span>{`Post Code :  ${row.pincode}`}</span>
        <span>{`Mobile :  ${row.contactNumber}`}</span>
      </div>
    ),
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "careTypeId",
      text: "Type",
      formatter: careTypeFormatter,
    },
    {
      dataField: "external",
      text: "External",
      formatter: externalFormatter,
    },
    {
      dataField: "contactNumber",
      text: "Contact Number",
    },
    {
      dataField: "status",
      text: "Actions",
      formatter: ActionFormatter,
    },
  ];

  let loader = null;
  if (props.PhysiciansLoading) {
    loader = <Spinner />;
  }

  useEffect(() => {
if(PhysicianCareTypes && PhysicianCareTypes.length )
    props.TableRenderPhysicians();
  }, [PhysicianCareTypes]); // eslint-disable-line

  useEffect(() => {
    if (props.PhysicianCareTypes) {
      setPhysicianCareTypes(props.PhysicianCareTypes);
    }
  }, [props.PhysicianCareTypes]);

  return (
    <div className="PhysiciansTable">
      {loader}
      <Card>
        <CardBody className="health-card">
          {props.PhysiciansNoDataFound ? (
            <p>No Data Found</p>
          ) : (
            <BootstrapTable
              keyField="id"
              data={props.data}
              columns={tableColumns}
              bordered={false}
              expandRow={expandRow}
              bootstrap4
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PhysiciansTable;
