import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody,ModalHeader } from "reactstrap"; // eslint-disable-line
import AddDocument from "./AddDocument"
import {
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const AddDocumentModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };


  const ToggleHandler =(index)=>{
    toggle(index)
  }

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button
            color="primary"
            size="sm"
            className="pull-right mr-2"
            onClick={() => toggle(index)}
          >
            <FontAwesomeIcon
              icon={faUpload}
              fixedWidth
              className="align-middle mr-2"
            />
            Upload
          </Button>

          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >
            <h3 className="ModalHeader mt-3">Add Document</h3>
            <div
              className="modal-close"
              onClick={() => toggle(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>

            
            <ModalBody className=" m-3"><AddDocument ToggleModal={()=>ToggleHandler(index)} RenderDocuments={props.RenderDocuments} setNoDataFound={props.setNoDataFound} /></ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default AddDocumentModal;
