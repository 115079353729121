import React from "react";
import { useHistory } from "react-router-dom";
import { cookieService } from '../../services/cookieService';
import logo from "../../assets/equipo-logo_low-res_with-tagline.png";


const ConsulatationInterstitial = (props) => {
    let history = useHistory();
    
  const id = history.location.state && history.location.state.detail && history.location.state.detail.id ? history.location.state.detail.id : null;
  return (
      <div className="email-verification-wrapper">
      <div className="container verification-container" style={{paddingLeft: '5%', paddingRight: '5%'}}>
        <div className="card">

          <header className="verification-header">
            <div className="text-center verify-img-wrapper">
              <img
                src={logo}
                alt="Doc APP logo"
                className="img-fluid"
              />
            </div>
          </header>
          <main style={{ padding: '1rem' }}>
              <>
                <p>
                    Your video consultation is launched on a browser window.
                </p>
                <p style={{fontWeight: "bold"}}>Application didn't open? <a href={process.env.REACT_APP_URL + "/iosapp/" + id + "/"+ encodeURIComponent(cookieService.get('token'))} target="_blank" rel="noopener noreferrer">Click here</a></p>
            </>
          </main>
          <a style={{margin: '15px', textAlign: 'center'}} href="/home"><button class="btn btn-primary">Home</button></a>
        </div>
      </div>
    </div>
  );
};
export default ConsulatationInterstitial;
